import { Component, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { Subscription, Observable, throwError, of, fromEvent, combineLatest } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { JwtPayload, ItemAttributeTypes, ItemAttributeObjectTypes, ItemChild, ItemTypes, ReferenceTypes, LikeTypes, Like, CommonConstants, SenecaResponse, MATERIAL_VISIBILITY_DELTA_HOURS, ItemAttributeMaterialTypes, ItemFrontEndWrapper, genericResponseSubscriber, ItemTakerEnrollStatusTypes, Survey, SurveyStatuses, Item, CourseDate, parseBoolean, CourseManagerItem, EnrichedItemTaker, sortByRules, MapById, Upload, User, Lang, SurveyAttributeTypes } from 'src/cm2-commonclasses';
import { ItemsService } from 'src/app/core/services/items.service';
import { Store } from '@ngrx/store';
import * as fromApp from '../../ngrx/app.reducers';
import { map, take, switchMap, catchError } from 'rxjs/operators';
import * as moment from 'moment';
import { GlobalApplicationData } from 'src/app/shared/models/global-application-data.model';
import { CourseEditionService } from 'src/app/initiatives/services/course-edition.service';
import { ItemUtil, IDataItem } from 'src/app/shared/models/item.model';
import { CourseEditionUtil, locationNameForCard, CourseEdition, CourseModuleUtil } from 'src/app/shared/models/course.model';
import { TakerService } from 'src/app/takers/services/taker.service';
import { ModalService } from 'library-alloy/dist/library-alloy';
import { InitiativeUtils, CKEditorConfigLibraryObjects } from 'src/app/shared/models/initiatives.model';
import { LangsService } from '../services/langs.service';
import { Syllabus, newSyllabusForFE, SyllabusUtil } from 'src/app/shared/models/syllabus.model';
import { AuthService } from 'src/app/auth/services/auth.service';
import * as langModel from '../../shared/models/lang.model';
import { UrlService } from 'src/app/shared/services/url.service';
import { SessionStorageService } from 'src/app/libraryHome/home/SessionStorageService';
import { SessionStorageCatalogService } from 'src/app/catalogHome/home/SessionStorageCatalogService';
import { ItemTakerAttributeTypes, ItemLang, ItemAttribute, ItemTakerEnroll } from 'atfcore-commonclasses/bin/classes/item';
import { SupplierPersonAttributeTypes, SupplierPersonAttributeRoles } from 'atfcore-commonclasses/bin/classes/supplier';
import { SupplierService } from 'src/app/suppliers/services/supplier.service';
import { copyToClipboard } from '../../shared/models/lang.model';
import { getDecodedUrlParam, sanitazeUrl } from 'src/app/shared/services/util';
import { environment } from 'src/environments/environment';
import { InitiativeService } from 'src/app/initiatives/services/initiative.service';

export const objectTypesToOpen = [ItemAttributeObjectTypes.PODCAST,
ItemAttributeObjectTypes.BOOK,
ItemAttributeObjectTypes.IMAGE,
ItemAttributeObjectTypes.VIDEO];
export const
  objectTypesToDownload = [ItemAttributeObjectTypes.DOCUMENT, ItemAttributeObjectTypes.GRAPH, ItemAttributeObjectTypes.EBOOK];

const attributeTypesToRetrieve = [
  ItemAttributeTypes.COMPETENCE_LEVEL_REQUIRED,
  ItemAttributeTypes.COMPETENCE_AWARD,
  ItemAttributeTypes.AIMS,
  ItemAttributeTypes.AGENDA,
  ItemAttributeTypes.PREREQS,
  ItemAttributeTypes.APPROACH,
  ItemAttributeTypes.PARTNER,
  ItemAttributeTypes.TECH_COMPETENCES,
  ItemAttributeTypes.SOFT_COMPETENCES,
  ItemAttributeTypes.OBJECT_TYPE,
  ItemAttributeTypes.OBJECT_TYPE_SPECIALIZATION,
  ItemAttributeTypes.DURATION,
  ItemAttributeTypes.VALUE,
  ItemAttributeTypes.WISHLIST_ENABLED,
  ItemAttributeTypes.EXTERNAL_OBJECT_ID,
  ItemAttributeTypes.RENTABLE,
  ItemAttributeTypes.TUTOR_CONTACT,
  ItemAttributeTypes.TEACHER_CONTACT,
  ItemAttributeTypes.SUPPORT_CONTACT,
  ItemAttributeTypes.ENABLE_COURSE_CERTIFICATION,
  ItemAttributeTypes.LINK,
  ItemAttributeTypes.DAM_SUBTITLE,
  ItemAttributeTypes.PLAYER_PREVENT_USER_CONTROL
];

@Component({
  selector: 'app-item-details',
  templateUrl: './item-details.component.html',
  styleUrls: ['./item-details.component.scss']
})
export class ItemDetailsComponent implements OnInit, OnDestroy {
  private scrollEvent$;
  itemId: string;
  itemDetails: IDataItem;
  loggedUser: JwtPayload;
  isGettingBreadcrumbs: boolean;
  isGettingItemDetails: boolean;
  isDownloadingExternalResource: boolean;
  projectId: string;
  sectionId: string;
  editingProjectId: string;
  editingSectionId: string;
  breadcrumbs: any[] = [];
  itemToAddId: string;
  lpId: string;
  editingLearningPlanId: string;
  adminMode: boolean;
  isDraft: boolean;
  editingLpId: string;
  subtype: string;
  subtypeLabel: string;
  itemType: string;
  hourValue;
  hourValueText: string;
  externalLinks: any[];
  attachmentList: ItemChild[];
  isItemCertifiable: boolean;
  certificationDate;
  isGettingSurveys: boolean;
  isSurveyStarted: boolean;
  isSurveyStartedLibrary: boolean;
  isSurveyCertificable: boolean;
  isSurveyCertificableLibrary: boolean;
  damPlayerVisible: boolean;
  damPlayerSeekTo: number | string;
  scormPlayerVisibile: boolean;
  propedeuticReferenceIds: string[];
  isGettingLikes: boolean;
  likeCounter: {
    likes: number,
    dislikes: number
  };
  currentLike: Like;
  iLikeThis: boolean;
  attachmentUrl: string;
  applicationData: GlobalApplicationData;
  enableCertification: boolean;
  itemsSubscription$: Subscription;
  getItemDetails$: Subscription;
  showPropedeuticalItemToThisModal: {
    propedeuticalObject: any,
    selectedItem: any
  };
  createLike$: Subscription;
  deleteLike$: Subscription;
  markNotificationAsReadByReference$: Subscription;
  itemsInContainerCertified: number;
  childsNotCertifiedButCertifiables: number;
  totalChildsWithSurvey: number;
  isGettingChilds: boolean;
  childObjects: any;
  childsOfModules: number = 0;
  childObjectTmp: IDataItem[];
  propedeuticalObject;
  descriptionExpanded: boolean;
  getCertificationFile$: Subscription;
  downloadEditionCertification$: Subscription;
  certificationImageUrl: string;
  linkedinUrl: string;
  isItemDisabled: boolean;
  isSyllabusCourse: boolean;
  isVideo: boolean;
  isSurveyItem: boolean;
  isPlayerBoxOpened: boolean;
  tabSelectedIndex: number;
  tabSelectedIndexResult: string;
  currentTab: string;
  tabs: { id: string, name: string }[];
  linkedItems;
  editions: CourseDate[];
  competences: string[];
  materials: any[];
  preworkMaterialsCounter: number = 0;
  classroomMaterialsCounter: number = 0;
  postworkMaterialsCounter: number = 0;
  totMaterialsCounter: number = 0;
  materialListNumRecords = 20;
  currentMaterialListPage = 1;
  currentMaterialListCounter = 0;
  isFetchingMaterialsFiles = false;
  selectedMaterialTab: string;
  isAtomicItem: boolean;
  isAssessment: boolean;
  isGettingLinkedItems: boolean;
  courseEndDate;
  applicationLang: string;
  getApplicationLang$: Subscription;
  addChilds$: Subscription;
  suggestItemToUser$: Subscription;
  tryLibraryItemTakerEnroll$: Subscription;
  editionsSurvey: any[];
  certifiedEditions: any[];
  editionsListWithPastEditions: any[];
  isOnlineCourse: boolean;
  isBlendedStage: boolean = false;
  isWebinar: boolean;
  isExternalCourse: boolean;
  isExternalCourseOnline: boolean;
  CanSetPresenceAbsence: boolean;
  CanViewPresenceAbsence: boolean;
  isExternalCourseClass: boolean;
  isExternalEventClass: boolean;
  isExternalEventOnline: boolean;
  isExternalEvent: boolean;
  areTabsSticky: boolean;
  toDoCoursesCardsClicked;
  clickY;
  scrollLeft;
  startX;
  frontEndSyllabus: Syllabus;
  syllabus;
  syllabusFormatted: boolean;
  itemIdLinkedToCourse: string;
  isSettingBookmarks: boolean;
  itemLinkedToCourse: any; // Passato ad any per aggiunta variabili di gestione preiscrizione su itemChild (prima era IDataItem);
  editionsByPlace;
  isLoadingTabs: boolean;
  isLoadingEditions: boolean;
  libraryItemsData: any;
  countUsersToSuggestItem$: Subscription;
  getUsersToSuggestItem$: Subscription;
  externalCredentials: { externalLogin: string, externalPsw: string };
  usersToSuggestData: {
    usersToSuggestFromRecord: number,
    usersToSuggestNumRecords: number,
    usersToSuggestCurrentPage: number,
    usersToSuggestCounter: number,
    usersToSuggestLoaded: number,
    usersToSuggest: any[],
    resetUsersToSuggest: boolean,
    selectedUserId: string,
    searchedText: string
  };
  isFetchingUsersToSuggest: boolean;
  syllabusesData: {
    isFetchingSyllabuses: boolean,
    syllabusListFromRecord: number,
    syllabusListNumRecords: number,
    syllabusListCurrentPage: number,
    syllabusListCounter: number,
    syllabusListLoaded: number,
    syllabusList: any[],
    selectedSyllabus: any,
    searchedText: string
  };
  isFetchingLibraryItems: boolean;
  countItems$: Subscription;
  selectedMaterialForModal;
  stopDateCourseSubscriptionFormat = langModel.dateFormats[2];
  timeFormat = langModel.timeFormats[0];
  selectedEditionForConfirmSubscription;
  selectedEditionForCancelSubscription;
  isUsingInternetExplorer: boolean;
  isItemsAdminPreview: boolean;
  isEditingBlendedSection: boolean;
  isInitiativesAdminPreview: boolean;
  isInitiativesAdminFromHomePreview: boolean;
  isManagerPreview: boolean;
  isOpenDamBtnDisabled: boolean;
  editionsOfWrappedContainer: any[];
  isLoadingEditionsOfWrappedContainer: boolean;
  showItemCertificationLoader: boolean;
  objectNotFound: boolean;
  isInLibrary: boolean;
  isInCatalog: boolean;
  // isInTrainingBooklet: boolean;
  syllabusTitle: string;
  libraryObject: IDataItem;
  surveyMapForPlaylist: {
    [itemId: string]: {
      isSurveyStarted: boolean,
      isSurveyCertificable: boolean,
      isItemCertifiable: boolean
    }
  };
  wholeIdMap: any;
  isForOpenSurvey: boolean;
  isDownloadingFile: boolean;
  // lista per la gestione dei docenti e relatori (tutor)
  teachersTutorsList: any = [];
  getItemPrompterList$;
  prompterList: User[];
  hasPrompter: boolean;
  linkUrls: string[];
  selectedPerson: any;
  idsAdded: string[] = [];
  hasTheUserTheCertificateAssigned: boolean;
  externalObjId: string;
  isCreatingNewPlaylist: boolean = false;
  availableLangs: Lang[];
  defaultLang: string;
  langList: Lang[];
  langMap: { [key: string]: Lang };
  currentLang: Lang;
  defaultItemLang: ItemLang;
  currentItemLang: ItemLang;
  langInitForPlaylistDone: boolean = false;
  newPlaylistObj: Item;
  ckeConfig: any;
  isCreatingPlaylist: boolean;
  canRequestFromCatalog: boolean;
  canAccessUserCatalog: boolean;
  alreadyAskedForCatalog: boolean;
  addStandaloneCatalogItemRequest$: Subscription;
  itemToRequstCatalogPartecipation;

  canShareCertificationOnLinkedin: boolean = false;

  assessmentIdRelated: string;
  selectedEditionForConfirmPresence;

  canAskCatalogAsSimpleMail: boolean;
  isActivePreiscrition: boolean;
  startDatePreiscrition: any;
  endDatePreiscrition: any;
  onWhatPreiscrition: string;
  canPreiscritionOnInitiative: boolean = false;
  canCancelPreiscritionOnInitiative: boolean = false;
  isOnRangePreiscrition: boolean = false;
  hasStatusNegatedOnInitiative: boolean = false;

  hasStatusInvitedOnInitiative: boolean = false;
  hasStatusApprovedOnInitiative: boolean = false;
  hasStatusCancelledOnInitiative: boolean = false;
  isAvailableForSelectedEdition: boolean = true;
  selectedEdition;
  editionToCheck;

  searchableTags;
  searchableTagTypes = [];
  getUsableTags$: Subscription;
  isLoadingUsableTags = false;

  isInPreview: boolean;
  languageList;
  hasLanguage: boolean;
  selectedLanguage: string;

  refreshCachePlaylist$: any;

  preregisterOnCourse$: Subscription;
  courseEditionToPreRegistration;



  availabilityError;
  availabilityResponse;
  editionsInConflict;
  errorMessageVPL;
  errorCodesVPL;
  isSurveyTimeRangeCompatible: boolean;
  /** True se l'Item ha OBJECT_TYPE_SPECIALIZATION Webinar */
  isWebinarSpecialization: boolean;
  isCurrentlyInvitedOrApprovedInInitiativeOrEdition: boolean;
  takers;
  isPublicTaker: boolean;
  isUserCanceled: boolean;
  // Disabilita il tasto guarda video per oggetti all'interno di iniziative pubbliche per cui non ho visibilità nell'oggetto
  singleItemWithoutStageDamOpenBtnDisabled: boolean = false;
  // valorizzata a true solo bnel caso stia consumando un oggetto, disabilita il button seguendo le condizioni sopra
  // aggiunto altrimenti veniva disabilitato anche in scegli edizione e altri tipi di iniziativa senza oggetto
  isItemPlayerButton: boolean = false;
  isCourseAlreadyTaken: boolean = false;
  forceDisableMainButton: boolean;
  disableLpItemsForInvalidStatus: boolean = false;
  isCurrentlyInvitedOrApprovedInitiative: boolean;
  isCurrentlyInvitedOrApprovedEdition: any;

  teacherAvailabilityResponse;
  supplierPerson;
  editionActionDisabledForOnlineCourse: any;

  isInTrainingBooklet: boolean;
  isTraditionalPlayer: boolean = false;
  canPreiscritionOnEditions: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private itemService: ItemsService,
    private initiativeService: InitiativeService,
    private urlService: UrlService,
    private sessionStorageService: SessionStorageService,
    private sessionStorageCatalogService: SessionStorageCatalogService,
    private toastr: ToastrService,
    private store: Store<fromApp.AppState>,
    private surveyService: CourseEditionService,
    private ref: ChangeDetectorRef,
    private takerService: TakerService,
    private langsService: LangsService,
    private authService: AuthService,
    private modalService: ModalService,
    private translate: TranslateService,
    private supplierService: SupplierService,
    private cdr: ChangeDetectorRef
  ) {

    this.ckeConfig = CKEditorConfigLibraryObjects;

    if (environment && environment.canAccessUserCatalog) {
      this.canAccessUserCatalog = true;
    } else {
      this.canAccessUserCatalog = false;
    }

    if (environment && environment.canAskCatalogAsSimpleMail) {
      this.canAskCatalogAsSimpleMail = true;
    } else {
      this.canAskCatalogAsSimpleMail = false;
    }

    if (environment && environment.canShareCertificationOnLinkedin) {
      this.canShareCertificationOnLinkedin = true;
    } else {
      this.canShareCertificationOnLinkedin = false;
    }

    // Collegamento alla lingua di default
    const defaultLang$: Observable<string> = this.store.select(fromApp.getDefaultLang);
    // Recupero la lingua settata dallo store applicativo, per settare la lingua in cui eseguire le traduzioni
    let applicationLang$: Observable<string> = this.store.select(fromApp.getApplicationLang);
    const availableLangs$: Observable<Lang[]> = this.store.select(fromApp.getAvailableLangs);

    const combinedSelectes$ = combineLatest<any>(defaultLang$, availableLangs$, applicationLang$);
    this.getApplicationLang$ = combinedSelectes$.subscribe(([defaultLang, availableLangs, applicationLang]) => {
      this.defaultLang = defaultLang;
      this.availableLangs = availableLangs;
      this.applicationLang = applicationLang;
      // Setto la lingua
      if (applicationLang) {
        this.applicationLang = applicationLang;
        moment.locale(this.applicationLang);
      }
    });

    let appSidebarContent = document.getElementsByClassName("ng-sidebar__content") && document.getElementsByClassName("ng-sidebar__content")[0];
    this.scrollEvent$ = fromEvent(appSidebarContent,
      'scroll').subscribe((e: any) => {
        // Determino la posizione delle tab
        let tabElement = document.getElementById('tabs');
        let tabsMarker = document.getElementById('alloy-tabs-maker');

        if (tabElement) {
          // Elementi delle sezioni
          let descriptionElement = document.getElementById('description');
          let competencesElement = document.getElementById('competences');
          let linkUrlsElement = document.getElementById('linkUrls');
          let syllabusElement = document.getElementById('syllabus');
          let externalCredentialsElement = document.getElementById('externalCredentials');
          let initiativeMaterialsElement = document.getElementById('initiativeMaterials');
          let editionsSurveyElement = document.getElementById('editionsSurvey');
          let attachmentsElement = document.getElementById('attachments');
          let sectionsOfItemElement = document.getElementById('sectionsOfItem');
          let itemsOfLPElement = document.getElementById('itemsOfLP');
          let editionsListElement = document.getElementById('editionsList');
          let linkedItemsElement = document.getElementById('linked');
          let certificationElement = document.getElementById('certification');
          let certificationsElement = document.getElementById('certifications');
          let teachersElement = document.getElementById('teacherTutor');
          let prompterListElement = document.getElementById('prompterList');
          let acquisibleCompetencesElement = document.getElementById('acquisibleCompetences');
          let requiredCompetencesElement = document.getElementById('requiredCompetences');

          // Posizione delle tab
          let tabBounding = tabElement.getBoundingClientRect();

          // Determino le posizioni di tali sezioni, se esitenti
          let descriptionBounding = descriptionElement && descriptionElement.getBoundingClientRect();
          let descriptionHeight = descriptionBounding && descriptionBounding.height;

          let competencesBounding = competencesElement && competencesElement.getBoundingClientRect();
          let competencesHeight = competencesBounding && competencesBounding.height;

          let linkUrlsBounding = linkUrlsElement && linkUrlsElement.getBoundingClientRect();
          let linkUrlsHeight = linkUrlsBounding && linkUrlsBounding.height;

          let syllabusBounding = syllabusElement && syllabusElement.getBoundingClientRect();
          let syllabusHeight = syllabusBounding && syllabusBounding.height;

          let externalCredentialsBounding = externalCredentialsElement && externalCredentialsElement.getBoundingClientRect();
          let externalCredentialsHeight = externalCredentialsBounding && externalCredentialsBounding.height;

          let initiativeMaterialsBounding = initiativeMaterialsElement && initiativeMaterialsElement.getBoundingClientRect();
          let initiativeMaterialsHeight = initiativeMaterialsBounding && initiativeMaterialsBounding.height;

          let editionsSurveyBounding = editionsSurveyElement && editionsSurveyElement.getBoundingClientRect();
          let editionsSurveyHeight = editionsSurveyBounding && editionsSurveyBounding.height;

          let attachmentsBounding = attachmentsElement && attachmentsElement.getBoundingClientRect();
          let attachmentsHeight = attachmentsBounding && attachmentsBounding.height;

          let sectionsOfItemBounding = sectionsOfItemElement && sectionsOfItemElement.getBoundingClientRect();
          let sectionsOfItemHeight = sectionsOfItemBounding && sectionsOfItemBounding.height;

          let prompterListBounding = prompterListElement && prompterListElement.getBoundingClientRect();
          let prompterListHeight = prompterListBounding && prompterListBounding.height;

          let itemsOfLPBounding = itemsOfLPElement && itemsOfLPElement.getBoundingClientRect();
          let itemsOfLPpHeight = itemsOfLPBounding && itemsOfLPBounding.height;

          let editionsListBounding = editionsListElement && editionsListElement.getBoundingClientRect();
          let editionsListHeight = editionsListBounding && editionsListBounding.height;

          let linkedItemsBounding = linkedItemsElement && linkedItemsElement.getBoundingClientRect();
          let linkedItemsHeight = linkedItemsBounding && linkedItemsBounding.height;

          let certificationBounding = certificationElement && certificationElement.getBoundingClientRect();
          let certificationHeight = certificationBounding && certificationBounding.height;

          let certificationsBounding = certificationsElement && certificationsElement.getBoundingClientRect();
          let certificationsHeight = certificationsBounding && certificationsBounding.height;

          let teachersBounding = teachersElement && teachersElement.getBoundingClientRect();
          let teachersHeight = teachersBounding && teachersBounding.height;

          let acquisibleCompetencesBounding = acquisibleCompetencesElement && acquisibleCompetencesElement.getBoundingClientRect();
          let acquisibleCompetencesHeight = acquisibleCompetencesBounding && acquisibleCompetencesBounding.height;

          let requiredCompetencesBounding = requiredCompetencesElement && requiredCompetencesElement.getBoundingClientRect();
          let requiredCompetencesHeight = requiredCompetencesBounding && requiredCompetencesBounding.height;

          // Determino la distanza fra le tab e ogni sezione
          let descriptionDistanceToTabs = descriptionBounding && (descriptionBounding.top - tabBounding.top - 65);
          let competencesDistanceToTabs = competencesBounding && (competencesBounding.top - tabBounding.top - 65);
          let syllabusDistanceToTabs = syllabusBounding && (syllabusBounding.top - tabBounding.top - 65);
          let externalCredentialsDistanceToTabs = externalCredentialsBounding && (externalCredentialsBounding.top - tabBounding.top - 65);
          let initiativeMaterialsToTabs = initiativeMaterialsBounding && (initiativeMaterialsBounding.top - tabBounding.top - 65);
          let editionsSurveyDistanceToTabs = editionsSurveyBounding && (editionsSurveyBounding.top - tabBounding.top - 65);
          let attachmentsDistanceToTabs = attachmentsBounding && (attachmentsBounding.top - tabBounding.top - 65);
          let sectionsOfItemDistanceToTabs = sectionsOfItemBounding && (sectionsOfItemBounding.top - tabBounding.top - 65);
          let prompterListDistanceToTabs = prompterListBounding && (prompterListBounding.top - tabBounding.top - 65);
          let itemsOfLPDistanceToTabs = itemsOfLPBounding && (itemsOfLPBounding.top - tabBounding.top - 65);
          let editionsListDistanceToTabs = editionsListBounding && (editionsListBounding.top - tabBounding.top - 65);
          let linkedItemsDistanceToTabs = linkedItemsBounding && (linkedItemsBounding.top - tabBounding.top - 65);
          let certificationDistanceToTabs = certificationBounding && (certificationBounding.top - tabBounding.top - 65);
          let certificationsDistanceToTabs = certificationsBounding && (certificationsBounding.top - tabBounding.top - 65);
          let teachersDistanceToTabs = teachersBounding && (teachersBounding.top - tabBounding.top - 64);
          let acquisibleCompetencesDistanceToTabs = acquisibleCompetencesBounding && (acquisibleCompetencesBounding.top - tabBounding.top - 65);
          let requiredCompetencesDistanceToTabs = requiredCompetencesBounding && (requiredCompetencesBounding.top - tabBounding.top - 65);

          if (tabsMarker && tabsMarker.getBoundingClientRect() && tabsMarker.getBoundingClientRect().top <= 78) {
            this.areTabsSticky = true;
          } else {
            this.areTabsSticky = false;
          }

          if (descriptionDistanceToTabs + descriptionHeight > 0) {
            this.selectTab('description', true);
          } else if (syllabusDistanceToTabs + syllabusHeight > 0) {
            this.selectTab('syllabus', true);
          } else if (externalCredentialsDistanceToTabs + externalCredentialsHeight > 0) {
            this.selectTab('externalCredentials', true);
          } else if (initiativeMaterialsToTabs + initiativeMaterialsHeight > 0) {
            this.selectTab('initiativeMaterials', true);
          } else if (competencesDistanceToTabs + competencesHeight > 0) {
            this.selectTab('competences', true);
          } else if (competencesDistanceToTabs + linkUrlsHeight > 0) {
            this.selectTab('linkUrls', true);
          } else if (attachmentsDistanceToTabs + attachmentsHeight > 0) {
            this.selectTab('attachments', true);
          } else if (sectionsOfItemDistanceToTabs + sectionsOfItemHeight > 0) {
            this.selectTab('sectionsOfItem', true);
          } else if (itemsOfLPDistanceToTabs + itemsOfLPpHeight > 0) {
            this.selectTab('itemsOfLP', true);
          } else if (editionsListDistanceToTabs + editionsListHeight > 0) {
            this.selectTab('editionsList', true);
          } else if (teachersDistanceToTabs + teachersHeight > 0) {
            this.selectTab('teachersTutor', true);
          } else if (editionsSurveyDistanceToTabs + editionsSurveyHeight > 0) {
            this.selectTab('editionsSurvey', true);
          } else if (certificationDistanceToTabs + certificationHeight > 0) {
            this.selectTab('certification', true);
          } else if (certificationsDistanceToTabs + certificationsHeight > 0) {
            this.selectTab('certifications', true);
          } else if (linkedItemsDistanceToTabs + linkedItemsHeight > 0) {
            this.selectTab('linked', true);
          } else if (prompterListDistanceToTabs + prompterListHeight > 0) {
            this.selectTab('prompterList', true);
          } else if (acquisibleCompetencesDistanceToTabs + acquisibleCompetencesHeight > 0) {
            this.selectTab('acquisibleCompetences', true);
          } else if (requiredCompetencesDistanceToTabs + requiredCompetencesHeight > 0) {
            this.selectTab('requiredCompetences', true);
          }
        }
      });
    const globalApplicationData$: Observable<GlobalApplicationData> = this.store.select(fromApp.getGlobalApplicationData);
    globalApplicationData$.pipe(take(1)).subscribe(
      (globalApplicationData) => {
        this.applicationData = globalApplicationData;
      });
  }

  itemDetailsForPlanTracker$: Observable<IDataItem>;
  async ngOnInit() {
    this.isDownloadingFile = false;
    this.hasTheUserTheCertificateAssigned = false;
    this.getOnlyUsableTags()
    this.route.params
      .subscribe(
        (params: Params) => {
          let appInitiatives = document.getElementsByClassName("ng-sidebar__content") && document.getElementsByClassName("ng-sidebar__content")[0];
          if (appInitiatives) {
            appInitiatives.scrollTop = 0;
          }
          this.resetData(params);
          let checkUserTheCertificateAssignedPromise = this.checkUserTheCertificateAssigned(this.itemId || this.itemToAddId)
          checkUserTheCertificateAssignedPromise.then(() => {
            this.store.select(fromApp.getLoggedUser)
              .subscribe((loggedUser: JwtPayload) => {
                this.loggedUser = loggedUser;
                if (this.loggedUser) {
                  this.getItemDetails();
                }
              })
          })
          this.wholeIdMap = params;
        }
      );

    if (this.router.url.includes("itemDetails") || this.router.url.includes("itemDetailSec")) {
      // ID del genitore (progetto o LP) recuperato dai parametri dell'URL.
      const parentId = this.route.snapshot.paramMap.get("projectId") || this.route.snapshot.paramMap.get("lpId") || this.route.snapshot.paramMap.get("itemId");

      this.isLoadingEditions = true;
      this.itemDetailsForPlanTracker$ = this.getItemDetailsForPlanTracker(parentId);
      this.isLoadingEditions = false;
    }
  }

  getOnlyUsableTags() {
    this.isLoadingUsableTags = true;
    this.getUsableTags$ = this.itemService.getOnlyUsableTags()
      .subscribe(data => {
        if (data.error) {
          this.toastr.error(this.translate.instant('errors.' + data.error));
        } else {
          this.searchableTagTypes = data.response || [];
        }
        this.isLoadingUsableTags = false;
      },
        (err) => {
          this.isLoadingUsableTags = false;
          this.toastr.error(this.translate.instant('errors.' + err.message));
        })
  }

  // Resetta la subscription
  resetSubscriptions() {
    if (this.getItemPrompterList$) {
      this.getItemPrompterList$.unsubscribe();
    }
    if (this.itemsSubscription$) {
      this.itemsSubscription$.unsubscribe();
    }
    if (this.getItemDetails$) {
      this.getItemDetails$.unsubscribe();
    }
    if (this.createLike$) {
      this.createLike$.unsubscribe();
    }
    if (this.markNotificationAsReadByReference$) {
      this.markNotificationAsReadByReference$.unsubscribe();
    }
    if (this.deleteLike$) {
      this.deleteLike$.unsubscribe();
    }
    if (this.getCertificationFile$) {
      this.getCertificationFile$.unsubscribe();
    }
    if (this.downloadEditionCertification$) {
      this.downloadEditionCertification$.unsubscribe();
    }
    if (this.preregisterOnCourse$) {
      this.preregisterOnCourse$.unsubscribe();
    }
  }

  // Resetta i dati del componente
  resetData(params: Params) {
    this.itemId = getDecodedUrlParam(params['itemId']);
    this.projectId = getDecodedUrlParam(params['projectId']);
    this.sectionId = getDecodedUrlParam(params['sectionId']);
    this.editingProjectId = getDecodedUrlParam(params['editingProjectId']);
    this.editingSectionId = getDecodedUrlParam(params['editingSectionId']);
    this.itemToAddId = getDecodedUrlParam(params['itemToAddId']);
    this.lpId = getDecodedUrlParam(params['lpId']);
    this.editingLearningPlanId = getDecodedUrlParam(params['editingLearningPlanId']);
    this.editingLpId = getDecodedUrlParam(params['editingLpId']);
    const isDraftParam = getDecodedUrlParam(params['isDraft'])
    this.isDraft = (isDraftParam == "true");
    // Inizializzo una istanza nuova del Syllabus
    this.frontEndSyllabus = newSyllabusForFE();
    this.isUsingInternetExplorer = this.isInternetExplorerBrowser();
    this.isGettingBreadcrumbs = true;
    this.currentItemLang = null;
    this.langInitForPlaylistDone = false;
    this.isCreatingPlaylist = false;
    this.objectNotFound = false;
    this.isInLibrary = false;
    this.isInTrainingBooklet = false;
    this.isInCatalog = false;
    this.isOpenDamBtnDisabled = true;
    this.showItemCertificationLoader = false;
    this.itemLinkedToCourse = null;
    this.selectedMaterialForModal = null;
    this.editionsOfWrappedContainer = null;
    this.itemIdLinkedToCourse = null;
    this.syllabusFormatted = false;
    this.isGettingLinkedItems = true;
    this.selectedEditionForConfirmSubscription = null;
    this.selectedEditionForConfirmPresence = null;
    this.selectedEditionForCancelSubscription = null;
    this.courseEditionToPreRegistration = null;
    this.isLoadingTabs = true;
    this.isLoadingEditions = true;
    this.isSettingBookmarks = false;
    this.isSyllabusCourse = false;
    this.isAtomicItem = false;
    this.isAssessment = false;
    this.isVideo = false;
    this.isSurveyItem = false;
    this.isGettingChilds = false;
    this.isOnlineCourse = false;
    this.isBlendedStage = false;
    this.isWebinar = false;
    this.isExternalCourse = false;
    this.isExternalCourseOnline = false;
    this.CanSetPresenceAbsence = false;
    this.isExternalCourseClass = false;
    this.isExternalEventClass = false;
    this.isExternalEventOnline = false;
    this.isInTrainingBooklet = false;
    // Controlla se sto recuperando gli item della Library
    this.isFetchingLibraryItems = false;
    // Dati contenenti le informazioni dei materiali library disponibili
    this.libraryItemsData = {
      libraryItemsFromRecord: 0,
      libraryItemsNumRecords: 20,
      libraryItemsCurrentPage: 1,
      libraryItemsCounter: 0,
      libraryItemsLoaded: 0,
      libraryItems: [],
      resetLibraryItems: false,
      selectedItemId: null
    };
    // Lista di suggeritori
    this.prompterList = null;
    this.hasPrompter = false;
    // Controlla se sto recuperando gli utenti a cui suggerire l'oggetto
    this.isFetchingUsersToSuggest = false;
    // Dati contenenti le informazioni degli utenti a cui suggerire l'oggetto
    this.usersToSuggestData = {
      usersToSuggestFromRecord: 0,
      usersToSuggestNumRecords: 20,
      usersToSuggestCurrentPage: 1,
      usersToSuggestCounter: 0,
      usersToSuggestLoaded: 0,
      usersToSuggest: [],
      resetUsersToSuggest: false,
      searchedText: '',
      selectedUserId: null
    };
    if (this.breadcrumbs && this.breadcrumbs.length) {
      this.breadcrumbs.length = 0;
    }
    if (this.externalLinks) {
      if (this.externalLinks.length) {
        this.externalLinks.length = 0;
      }
    } else {
      this.externalLinks = [];
    }
    if (this.editionsSurvey) {
      if (this.editionsSurvey.length) {
        this.editionsSurvey.length = 0;
      }
    } else {
      this.editionsSurvey = [];
    }
    if (this.certifiedEditions) {
      if (this.certifiedEditions.length) {
        this.certifiedEditions.length = 0;
      }
    } else {
      this.certifiedEditions = [];
    }
    if (this.editionsListWithPastEditions) {
      if (this.editionsListWithPastEditions.length) {
        this.editionsListWithPastEditions.length = 0;
      }
    } else {
      this.editionsListWithPastEditions = [];
    }
    if (this.linkedItems) {
      if (this.linkedItems.length) {
        this.linkedItems.length = 0;
      }
    } else {
      this.linkedItems = [];
    }
    if (this.competences) {
      if (this.competences.length) {
        this.competences.length = 0;
      }
    } else {
      this.competences = [];
    }
    if (this.linkUrls) {
      if (this.linkUrls.length) {
        this.linkUrls.length = 0;
      }
    } else {
      this.linkUrls = [];
    }
    if (this.attachmentList) {
      if (this.attachmentList.length) {
        this.attachmentList.length = 0;
      }
    } else {
      this.attachmentList = [];
    }
    if (this.propedeuticReferenceIds) {
      if (this.propedeuticReferenceIds.length) {
        this.propedeuticReferenceIds.length = 0;
      }
    } else {
      this.propedeuticReferenceIds = [];
    }
    this.childsOfModules = 0;
    if (this.childObjects) {
      if (this.childObjects.length) {
        this.childObjects.length = 0;
      }
    } else {
      this.childObjects = [];
    }
    if (this.childObjectTmp) {
      if (this.childObjectTmp.length) {
        this.childObjectTmp.length = 0;
      }
    } else {
      this.childObjectTmp = [];
    }
    this.itemDetails = null;

    this.isItemsAdminPreview = false;
    this.isEditingBlendedSection = false;
    this.isInitiativesAdminPreview = false;
    this.isInitiativesAdminFromHomePreview = false;
    this.isManagerPreview = false;

    if (this.router.url.indexOf('itemsAdmin') !== -1) {
      this.isItemsAdminPreview = true;

      if (this.router.url.indexOf('BlendedSection') !== -1) {
        this.isEditingBlendedSection = true;
      }
    }

    if (this.router.url.indexOf('library') !== -1) {
      this.isInLibrary = true;
    }

    if (this.router.url.indexOf('trainingBooklet') !== -1) {
      this.isInTrainingBooklet = true;
    }

    if (this.router.url.indexOf('catalog') !== -1) {
      this.isInCatalog = true;
    }

    if (this.router.url.indexOf('trainingBooklet') !== -1) {
      this.isInTrainingBooklet = true;
    }

    if (this.router.url.indexOf('itemPreview/') !== -1) {
      this.isInitiativesAdminPreview = true;
    }

    if (this.router.url.indexOf('itemPreviewFromHome') !== -1) {
      this.isInitiativesAdminFromHomePreview = true;
    }

    if (this.router.url.indexOf('manager/itemDetails/') !== -1) {
      this.isManagerPreview = true;
    }

    // Verifica se sono nella parte amministrativa
    if (this.isItemsAdminPreview || this.isInitiativesAdminPreview || this.isInitiativesAdminFromHomePreview) {
      this.adminMode = true;
    } else {
      this.adminMode = false;
    }

    if (this.isInitiativesAdminPreview || this.isInitiativesAdminFromHomePreview || this.isManagerPreview) {
      this.isInPreview = true;
    } else {
      this.isInPreview = false;
    }

    this.isDraft = false;
    this.courseEndDate = null;
    this.subtype = null;
    this.subtypeLabel = null;
    this.itemType = null;
    this.hourValue = null;
    this.hourValueText = null;
    this.isItemCertifiable = false;
    this.certificationDate = null;
    this.isSurveyStarted = false;
    this.isSurveyStartedLibrary = false;
    this.isSurveyCertificable = false;
    this.isSurveyCertificableLibrary = false;
    this.damPlayerVisible = false;
    this.damPlayerSeekTo = null;
    this.scormPlayerVisibile = false;
    this.likeCounter = {
      likes: null,
      dislikes: null
    };
    this.currentLike = null;
    this.iLikeThis = false;
    this.attachmentUrl = null;
    this.enableCertification = false;
    this.showPropedeuticalItemToThisModal = {
      propedeuticalObject: null,
      selectedItem: null
    }
    this.itemsInContainerCertified = 0;
    this.itemsInContainerCertified = 0;
    this.childsNotCertifiedButCertifiables = 0;
    this.totalChildsWithSurvey = 0;
    this.descriptionExpanded = false;
    this.propedeuticalObject = null;
    this.certificationImageUrl = null;
    this.linkedinUrl = null;
    this.alreadyAskedForCatalog = false;
    this.canRequestFromCatalog = false;
  }

  isSubscribeEnabled(edition) {
    if (edition.isOnlineCourse || this.isWebinar) {
      return edition && ((edition.isInvited
        || edition.isCancelled || edition.isApproved) ||
        ((this.hasStatusInvitedOnInitiative || this.hasStatusApprovedOnInitiative || this.hasStatusCancelledOnInitiative) && (edition.noStatusForPreiscrition || edition.isCancelled)))
        && moment(edition.lastDayDate).isSameOrAfter(new Date().toUTCString());
    } else {
      return edition && ((edition.isInvited
        || edition.isCancelled || edition.isApproved) ||
        ((this.hasStatusInvitedOnInitiative || this.hasStatusApprovedOnInitiative || this.hasStatusCancelledOnInitiative) && (edition.noStatusForPreiscrition || edition.isCancelled)))
        && moment(edition.endDate).isAfter(new Date().toUTCString());
    }
  }

  isSyllabusFilled(fieldName: string, dataField: string) {
    if (fieldName == ItemAttributeTypes.SAP_CODICE_TIPO_EVENTO) {
      dataField = 'title';
    }
    if (this.frontEndSyllabus
      && this.frontEndSyllabus[fieldName]
      && this.frontEndSyllabus[fieldName].data) {

      if (this.frontEndSyllabus[fieldName].data.length) {
        return this.frontEndSyllabus[fieldName].data[0]?.[dataField];
      } else {
        return this.frontEndSyllabus[fieldName].data[dataField]
      }
    }
  }

  // Verifica se ho gli obiettivi nel syllabus
  aimsSyllabusFilled() {
    return this.isSyllabusFilled('AIMS', 'description');
  }

  // Verifica se ho i contatti nel Syllabus
  tutorContactSyllabusFilled() {
    return this.isSyllabusFilled('TUTOR_CONTACT', 'description');
  }
  teacherContactSyllabusFilled() {
    return this.isSyllabusFilled('TEACHER_CONTACT', 'description');
  }
  supportContactSyllabusFilled() {
    return this.isSyllabusFilled('SUPPORT_CONTACT', 'description');
  }

  // Verifica se ho i prerequisiti nel syllabus
  prereqsSyllabusFilled() {
    return this.isSyllabusFilled('PREREQS', 'description');
  }

  // Verifica se ho la durata nel syllabus
  durationSyllabusFilled() {
    return this.isSyllabusFilled('DURATION', 'description');
  }

  // Verifica se ho tech skills nel syllabus
  techCompetencesSyllabusFilled() {
    return this.frontEndSyllabus && this.frontEndSyllabus['TECH_COMPETENCES'] && this.frontEndSyllabus['TECH_COMPETENCES'].data && this.frontEndSyllabus['TECH_COMPETENCES'].data.length;
  }

  // Verifica se ho soft skills nel syllabus
  softCompetencesSyllabusFilled() {
    return this.frontEndSyllabus && this.frontEndSyllabus['SOFT_COMPETENCES'] && this.frontEndSyllabus['SOFT_COMPETENCES'].data && this.frontEndSyllabus['SOFT_COMPETENCES'].data.length;
  }

  // Verifica se ho wave nel syllabus
  waveSyllabusFilled() {
    return this.frontEndSyllabus && this.frontEndSyllabus['WAVE'] && this.frontEndSyllabus['WAVE'].data && this.frontEndSyllabus['WAVE'].data.length;
  }

  // Verifica se ho l'"a chi si rivolge" nel syllabus
  approachSyllabusFilled() {
    return this.isSyllabusFilled('APPROACH', 'description');
  }

  // Verifica se c'è il '"con chi" nel syllabus
  partnerSyllabusFilled() {
    return this.isSyllabusFilled('PARTNER', 'description');
  }
  // Verifica se ho il programma nel syllabus
  agendaSyllabusFilled() {
    return this.isSyllabusFilled('AGENDA', 'description');
  }
  // Verifica se ho selezionato una lingua


  // Verifica se ho almeno un dato nel syllabus
  showSyllabusBodyContent() {
    return (this.isAssessment && this.editions && this.editions.length) ||
      (this.frontEndSyllabus
        && (
          this.agendaSyllabusFilled() || this.aimsSyllabusFilled() || this.prereqsSyllabusFilled() || this.durationSyllabusFilled() || this.partnerSyllabusFilled()
          || this.teacherContactSyllabusFilled() || this.tutorContactSyllabusFilled() || this.supportContactSyllabusFilled()
        ));
  }

  // Se nell'edizione ti puoi preregistrare
  isPreregisterEnabled(edition) {
    if (this.isActivePreiscrition && this.onWhatPreiscrition && (this.onWhatPreiscrition == 'EDITIONS_ONLY' || this.onWhatPreiscrition == 'ALL') &&
      this.isOnRangePreiscrition && (edition.noStatusForPreiscrition || edition.userStatus == 'USER_STATUS_CANCELLED' || edition.userStatus == 'USER_STATUS_ABSENT')) {
      return true;
    } else {
      return false;
    }
  }

  // Se nell'edizione ti puoi cancellare dalla preiscrizione
  isCanCancelPreregisterEnabled(edition) {
    if (this.isActivePreiscrition && this.onWhatPreiscrition && (this.onWhatPreiscrition == 'EDITIONS_ONLY' || this.onWhatPreiscrition == 'ALL') &&
      this.isOnRangePreiscrition && edition.userStatus == 'USER_STATUS_PREREGISTERED') {
      return true;
    } else {
      return false;
    }
  }

  // Gestione del click su una edizione, che apre una modale in base allo stato dell'utente e al tipo di edizione (online o in presenza)
  onEditionClicked(edition) {
    const now = new Date().toUTCString();
    const isExternalCourseEditionPassed = edition && (!edition.stopDate || (edition.stopDate && moment((edition.stopDate)).isBefore(now)));
    // se funziona aggiungi il controllo per user_status_confirmed per il primo if

    if (this.isPreregisterEnabled(edition) || this.isCanCancelPreregisterEnabled(edition)) {
      this.openConfirmSubscriptionModal(edition, this.isPreregisterEnabled(edition), this.isCanCancelPreregisterEnabled(edition));
    } else if (this.isExternalCourse && !edition.isPresent && !edition.isAbsent && isExternalCourseEditionPassed && edition.isConfirmed) {
      /*
        se si tratta di un corso esterno (online o in presenza), se la data di fine dell’edizione è passata
        e se lo stato dell’utente è diverso da USER_STATUS_PRESENT e da USER_STATUS_ABSENT,
        l’utente ha la possibilità di segnalare la sua presenza o la sua assenza.
        Pertanto è necessario mostrare la modale per permettere all'utente di impostare il stato (presente o assente)
      */
      this.openConfirmPresenceModal(edition);
    } else if (!this.isSubscribeEnabled(edition) && edition.isConfirmed) {
      this.openConfirmSubscriptionModal(edition, false, true, true);
    } else if ((edition.isPresent && this.isExternalCourse) || (edition.isAbsent && this.isExternalCourse)) {
      this.openConfirmPresenceModal(edition); // l'utente può cambiare stato
    } else { //  if (this.isSubscribeEnabled(edition) && !edition.isOnlineEvent && !edition.isOnlineCourse)
      this.openConfirmSubscriptionModal(edition);
    }
  }

  // Deve essere valorizzato il numero se è presente il check sull'abilitazione della prenotazione
  isMaxUsableTakesValidInEditionForConfirmPresence(edition) {
    if (edition &&
      edition.maxUsableTakes &&
      !edition.usedTakes) {
      return false;
    }

    return true;
  }

  isMapsEnabled(courseEdition, idx) {
    if (courseEdition) {
      return courseEdition.days && courseEdition.days.find((schedule, index) => idx == index && !!schedule && !!schedule.location && !!schedule.location.locationLat);
    }
    return false;
  }

  openMaps(courseEdition, index: number) {
    courseEdition.showMaps = true;
    if (courseEdition && courseEdition.days[index]) {
      const location = courseEdition.days[index].location;
      if (location && location.locationLat && location.locationLong) {
        courseEdition.mapData = {
          lat: location.locationLat,
          lng: location.locationLong
        }
      }
    }
  }

  // Torna il titolo custom dell'edizione
  calculateEditionCustomTitle(edition): string {
    if (edition) {
      let currentLangCode = this.applicationLang;
      let isCustomTitleEnabled = parseBoolean(ItemUtil.getAttributeValue(edition, ItemAttributeTypes.ENABLE_CUSTOM_TITLE_AND_SUBTITLE));
      if (isCustomTitleEnabled && currentLangCode && this.langsService && edition.itemLangs && edition.itemLangs.length) {
        let initiativeIndex: number = this.langsService.findItemLangIndex(currentLangCode, edition);
        return edition.itemLangs[initiativeIndex].title;
      }
    }

    return null;
  }

  // Deve essere valorizzato il numero se è presente il check sull'abilitazione della prenotazione
  isMaxUsableTakesValid(edition) {
    if (this.isSubscribeEnabled(edition) &&
      edition.maxUsableTakes &&
      !edition.usedTakes) {
      return false;
    }

    return true;
  }

  // Torna il sottotitolo custom dell'edizione
  calculateEditionCustomSubTitle(edition): string {
    if (edition) {
      let currentLangCode = this.applicationLang;
      let isCustomTitleEnabled = parseBoolean(ItemUtil.getAttributeValue(edition, ItemAttributeTypes.ENABLE_CUSTOM_TITLE_AND_SUBTITLE));
      if (isCustomTitleEnabled && currentLangCode && this.langsService && edition.itemLangs && edition.itemLangs.length) {
        let initiativeIndex: number = this.langsService.findItemLangIndex(currentLangCode, edition);
        return edition.itemLangs[initiativeIndex].subTitle;
      }
    }

    return null;
  }

  // Cambia il valore dello usedTakes
  changeTakesValue(action: string, edition) {
    if (edition) {
      if (action === 'increment') {
        // Devo assicurarmi che il numero non sia maggiore del più piccolo numero fra i posti disponibili e quelli massimi settati dal back office per i posti prenotazione
        let maxVal = Math.min(edition.maxUsableTakes, edition.availableSeats);
        if (edition.usedTakes < maxVal) {
          edition.usedTakes++;
        } else {
          return;
        }
      }
      if (action === 'decrement' && edition.usedTakes !== 0) {
        edition.usedTakes--;
      }
      this.changeUsedTakes(edition, edition.usedTakes);
    }
  }

  // Cambia il valore al check sulla privacy
  coursePrivacyAcceptedChanged(edition) {
    edition.coursePrivacyAccepted = !edition.coursePrivacyAccepted;
  }

  // Apre la pagina della privacy del course
  openCoursePrivacy(edition) {
    CourseModuleUtil.openPrivacy('takers/coursePrivacy/' + edition.initiativeId, this.urlService);
  }

  dismissConfirmCourseSubscription() {
    this.selectedEditionForConfirmSubscription = null;
    this.modalService.close('confirmCourseSubscription');
  }

  openConfirmPreRegistrationModal(courseEdition?) {
    this.courseEditionToPreRegistration = courseEdition;
    this.modalService.open('confirmPreRegistrationModal');
    document.getElementById("confirmPreRegistrationModal").focus();
  }

  closeConfirmPreRegistrationModal(confirm?) {
    this.modalService.close('confirmPreRegistrationModal');

    if (confirm) {
      this.preRegisterOnCourseEdition();
    } else {
      this.courseEditionToPreRegistration = null;
    }
  }

  // Preregistrati su un'edizione
  preRegisterOnCourseEdition() {
    this.isGettingItemDetails = true;
    this.isLoadingEditions = true;
    this.preregisterOnCourse$ = this.takerService.preregisterOnCourse(this.itemDetails.itemId, (this.courseEditionToPreRegistration && this.courseEditionToPreRegistration.courseDateId), true, (this.courseEditionToPreRegistration && this.courseEditionToPreRegistration.coursePrivacyAccepted))
      .subscribe(genericResponseSubscriber(this, (senecaResponse) => {
        if (senecaResponse.error) {
          // Mostro il toaster di errore
          this.toastr.error(this.translate.instant('errors.' + senecaResponse.error));
          this.isGettingItemDetails = false;
          this.isLoadingEditions = false;
        } else {
          this.toastr.success(this.translate.instant('generic.DATA_SAVED'));

          // Se sono nel catalogo e mi sono preiscritto, vado nella pagina di dettaglio (altrimenti, se resto nel catalogo, trovo il messaggio "non hai più le autorizzazioni")
          if (false) {
            // if (this.isInCatalog) {
            // Orrendo ma necessario: devo attendere che il servizio crei la takerEnroll
            setTimeout(() => {
              this.isGettingItemDetails = false;
              this.isLoadingEditions = false;

              this.router.navigate(['takers/itemDetails', this.itemDetails.itemId]);
            }, 2000);
          } else {
            // Forzo il caricamento della pagina per recuperare riassegnare i controlli per la card principale per testo e CTA
            location.reload();
            // if (!this.courseEditionToPreRegistration) {
            //   location.reload();
            // } else {
            //   setTimeout(() => {
            //     this.isGettingItemDetails = false;
            //     this.isLoadingEditions = false;
            //     this.reloadEditions();
            //   }, 500)
            // }
          }
        }
      }, (senecaResponse) => {
        this.isGettingItemDetails = false;
        this.isLoadingEditions = false;
        if (senecaResponse.error) {
          this.toastr.error(this.translate.instant('errors.' + senecaResponse.error));
        }
      }));
  }

  confirmCourseSubscriptionModal() {
    this.modalService.close('confirmCourseSubscription');
    if (this.isPreregisterEnabled(this.selectedEditionForConfirmSubscription)) {
      this.checkUserMatchTarget(false, this.selectedEditionForConfirmSubscription); // check match target
      // this.openConfirmPreRegistrationModal(this.selectedEditionForConfirmSubscription);
    } else if ((this.selectedEditionForConfirmSubscription.userStatus == 'USER_STATUS_CONFIRMED' && moment().isSameOrBefore(this.selectedEditionForConfirmSubscription.endDate)) || this.isCanCancelPreregisterEnabled(this.selectedEditionForConfirmSubscription) || this.selectedEditionForConfirmSubscription.userStatus == 'USER_STATUS_OVERBOOKING_CONFIRMED') {
      // Se sono iscritto ed entro la data di fine delle iscrizioni oppure se posso cancellare la preiscrizione apro la modale di cancellazione
      this.openCancelSubscriptionModal(this.selectedEditionForConfirmSubscription);
    } else {
      this.checkUserMatchTarget(true, this.selectedEditionForConfirmSubscription); // check match target
    }

    this.selectedEditionForConfirmSubscription = null;
  }

  async checkUserMatchTarget(isSubscription, edition) {
    try {
      this.editionToCheck = edition;
      let userId = this.loggedUser.user.userId.toString();
      let response = await this.takerService.userHasRequiredCompetences(userId, this.itemId).toPromise();
      if (response.error) {
        this.toastr.error(this.translate.instant('errors.' + response.error));
      } else {

        if (response.response.canUserEnroll == true) { // if response true can subscribe
          if (isSubscription == true) {
            // this.checkUserAvailability();
            this.subscribeToCourseEdition(this.editionToCheck);
          } else {
            this.openConfirmPreRegistrationModal(edition);
          }
        } else { // if response false can't subscribe
          this.openUserNotMatchTarget();
        }
      }
    } catch (error) {
    }
  }

  openUserNotMatchTarget() {
    this.modalService.open('userNotMatchTarget');
    document.getElementById('userNotMatchTarget').focus();
  }

  closeUserNotMatchTarget() {
    this.modalService.close('userNotMatchTarget');
    this.modalService.close('confirmCourseSubscription');
  }

  async checkUserAvailability() {
    try {
      this.isGettingItemDetails = true;
      this.isLoadingEditions = true;
      let editionIdToIgnore = this.editionToCheck.courseDateId;
      let days = [];
      this.editionToCheck.days.forEach(element => {
        element.timeSchedules.forEach(el => {
          days.push(el.startTime);
          days.push(el.endTime);
        });
      });

      let res = await this.initiativeService.listCoursesInSameConflictByTaker(editionIdToIgnore, days).toPromise();

      this.isGettingItemDetails = false;
      this.isLoadingEditions = false;
      if (res.error) {
        this.toastr.error(this.translate.instant('errors.' + res.error));
      } else {
        if (res.response.length == 0) { // if user available subscribe
          this.subscribeToCourseEdition(this.editionToCheck);
          this.editionToCheck = null;
          this.selectedEdition = null;
        } else { // if user not available alert modal
          this.selectedEdition = this.editionToCheck;
          this.editionToCheck = null;
          this.openNotAvailableUserModal();
        }
      }
    } catch (error) {
      this.isLoadingEditions = false;
      this.isGettingItemDetails = false;
      this.toastr.error(this.translate.instant('errors.' + error));
    }
  }

  subscribeNotAvailableUser() {
    this.subscribeToCourseEdition(this.selectedEdition);
    this.selectedEdition = null;
    this.modalService.close('notAvailableUserModal');
    this.modalService.close('confirmCourseSubscription');
  }

  openNotAvailableUserModal() {
    this.modalService.open('notAvailableUserModal');
    document.getElementById('notAvailableUserModal').focus();
  }

  closeNotAvailableUserModal() {
    this.modalService.close('confirmCourseSubscription');
    this.modalService.close('notAvailableUserModal');
    this.selectedEdition = null;
  }

  // Apre una modale per chiedere conferma sulla rimozione della iniziativa selezionate
  openConfirmSubscriptionModal(edition, isPreregister?: boolean, canCancelFromPreregister?: boolean, isCancelSubscription?: boolean) {
    // Archivio l'iniziativa che mi è stata passata
    this.selectedEditionForConfirmSubscription = { ...edition };
    this.selectedEditionForConfirmSubscription.customTitle = this.getEditionCustomTitle(this.selectedEditionForConfirmSubscription) || this.calculateEditionCustomTitle(this.selectedEditionForConfirmSubscription);
    this.selectedEditionForConfirmSubscription.customSubTitle = this.getEditionCustomSubTitle(this.selectedEditionForConfirmSubscription) || this.calculateEditionCustomSubTitle(this.selectedEditionForConfirmSubscription);
    this.selectedEditionForConfirmSubscription.usedTakes = 0;

    if (this.selectedEditionForConfirmSubscription.userStatus == 'USER_STATUS_NEGATED') {
      this.selectedEditionForConfirmSubscription.confirmBtnLabel = null;
    } else if (isPreregister || canCancelFromPreregister || isCancelSubscription) {
      if (isCancelSubscription) {
        this.selectedEditionForConfirmSubscription.confirmBtnLabel = this.translate.instant('takers.courseSubscription.modal.cancelSubscription.TITLE');
      } else if (canCancelFromPreregister) {
        this.selectedEditionForConfirmSubscription.confirmBtnLabel = this.translate.instant('generic.DELETE_PREREGISTER');
      } else if (isPreregister) {
        this.selectedEditionForConfirmSubscription.confirmBtnLabel = this.translate.instant('generic.PREREGISTER');
      }
    } else if (this.showAskPartecipationMessage()) {
      if (edition.alreadyAskedForCatalog) {
        this.selectedEditionForConfirmSubscription.confirmBtnLabel = this.translate.instant('generic.PARTECIPATION_REQUESTED');
      } else {
        this.selectedEditionForConfirmSubscription.confirmBtnLabel = this.translate.instant('generic.REQUEST_PARTECIPATION');
      }
    } else if (!this.showNoAuthToOperateMessage() && !this.isCourseAlreadyTaken &&
      (this.isCurrentlyInvitedOrApprovedInitiative || this.isCurrentlyInvitedOrApprovedEdition[this.selectedEditionForConfirmSubscription.itemId] || this.isPublicTaker || this.selectedEditionForConfirmSubscription.canRegister)) {
      this.selectedEditionForConfirmSubscription.confirmBtnLabel = this.translate.instant('awWizard.datesAndPlaces.SUBSCRIBE');
    } else if (this.selectedEditionForConfirmSubscription && this.selectedEditionForConfirmSubscription.seats && this.selectedEditionForConfirmSubscription.seats[0].obTotalSeats > this.selectedEditionForConfirmSubscription.seats[0].obUsedSeats && this.selectedEditionForConfirmSubscription.userStatus !== 'USER_STATUS_OVERBOOKING_CONFIRMED') {
      this.selectedEditionForConfirmSubscription.confirmBtnLabel = this.translate.instant('awWizard.datesAndPlaces.SUBSCRIBE');
    } else if (this.selectedEditionForConfirmSubscription && this.selectedEditionForConfirmSubscription.userStatus === 'USER_STATUS_OVERBOOKING_CONFIRMED') {
      this.selectedEditionForConfirmSubscription.confirmBtnLabel = this.translate.instant('takers.courseSubscription.modal.cancelSubscription.TITLE');
    }

    let stopDate;
    let today;

    if (this.selectedEditionForConfirmSubscription.stopDate) {
      stopDate = this.selectedEditionForConfirmSubscription.stopDate;
    } else {
      stopDate = moment(this.selectedEditionForConfirmSubscription.formattedStopDate).toISOString();
    }


    this.selectedEditionForConfirmSubscription.cancelBtnLabel = this.translate.instant('generic.CANCEL');
    this.selectedEditionForConfirmSubscription.stopDate = this.selectedEditionForConfirmSubscription.stopDate ? moment(this.selectedEditionForConfirmSubscription.stopDate).format('lll') : this.selectedEditionForConfirmSubscription.formattedStopDate;

    today = moment().toISOString();
    if (stopDate < today) {
      this.selectedEditionForConfirmSubscription.confirmBtnLabel = null
    }

    this.modalService.open('confirmCourseSubscription');
    document.getElementById('confirmCourseSubscription').focus();
  }

  secondConfirmationCourseSubscriptionModal() {
    this.modalService.close('confirmCourseSubscription');
  }

  // Apre una modale per chiedere lo status sulla presenza al corso
  openConfirmPresenceModal(edition) {
    // Archivio l'iniziativa che mi è stata passata
    this.selectedEditionForConfirmPresence = { ...edition };
    this.selectedEditionForConfirmPresence.customTitle = this.getEditionCustomTitle(this.selectedEditionForConfirmPresence) || this.calculateEditionCustomTitle(this.selectedEditionForConfirmPresence);
    this.selectedEditionForConfirmPresence.customSubTitle = this.getEditionCustomSubTitle(this.selectedEditionForConfirmPresence) || this.calculateEditionCustomSubTitle(this.selectedEditionForConfirmPresence);
    this.selectedEditionForConfirmPresence.usedTakes = 0;
    if (this.canRequestFromCatalog && this.canAccessUserCatalog) {
      if (edition.alreadyAskedForCatalog) {
        this.selectedEditionForConfirmPresence.confirmBtnLabel = this.translate.instant('generic.PARTECIPATION_REQUESTED');
      } else {
        this.selectedEditionForConfirmPresence.confirmBtnLabel = this.translate.instant('generic.REQUEST_PARTECIPATION');
      }
    } else {
      this.selectedEditionForConfirmPresence.confirmBtnLabel = this.translate.instant('awWizard.datesAndPlaces.SUBSCRIBE');
    }
    this.selectedEditionForConfirmPresence.cancelBtnLabel = this.translate.instant('generic.CANCEL');
    this.selectedEditionForConfirmPresence.stopDate = moment(this.selectedEditionForConfirmPresence.stopDate).format('lll');
    this.modalService.open('confirmCoursePresenceModal');
    document.getElementById('confirmCoursePresenceModal').focus();
  }

  secondConfirmConfirmPresenceModal() {
    this.modalService.close('confirmCoursePresenceModal');
    // Apro la modale per la conferma
    this.modalService.open('absenceConfirmation');
    document.getElementById('absenceConfirmation').focus();
  }

  // Chiude la modale per conferma la presenza al corso
  closePresenceConfirmationModal(confirm?: boolean) {
    this.modalService.close('presenceConfirmation');

    if (confirm) {
      this.setCoursePresence(true);
    } else {
      this.selectedEditionForConfirmPresence = null;
    }
  }

  // Chiude la modale per conferma l'assenza al corso
  closeAbsenceConfirmationModal(confirm?: boolean) {
    this.modalService.close('absenceConfirmation');

    if (confirm) {
      this.setCoursePresence();
    } else {
      this.selectedEditionForConfirmPresence = null;
    }
  }

  // Setta la presenza al corso
  async setCoursePresence(isPresent?: boolean) {
    this.isGettingItemDetails = true;
    let userIds = [this.loggedUser.user.userId];
    let dayIdToIgnore = [];
    let days = [];
    let stageItemId = this.selectedEditionForConfirmPresence.stageItemId;
    let editionItemIds = [this.selectedEditionForConfirmPresence.courseDateId];

    this.getUserTakers();
    let takerId = this.takers[0].takerId;

    // recupero i giorni da considerare e il giorno da ignorare
    this.selectedEditionForConfirmPresence.days.forEach(element => {
      if (!dayIdToIgnore.includes(element.itemId)) {
        dayIdToIgnore.push(element.itemId);
      }

      element.timeSchedules.forEach(el => {
        days.push(el.startTime);
        days.push(el.endTime);
      });
    });

    this.getCourseMins(days);

    if (isPresent) {
      // Controllo se l'utente è docente
      await this.listSupplierPersonByUserId();
      if (this.supplierPerson && this.supplierPerson !== "OBJECT_NOT_FOUND") {
        // controllo docenze concomitanti
        await this.teacherAvailabilityCheck(null, days, stageItemId, dayIdToIgnore);
      }

      if (this.teacherAvailabilityResponse) {
        // se ho docenze concomitanti mostro una modale bloccante
        this.openTeacherAvailabilityConflictModal();
      } else {
        await this.availabilityCheckVPL(stageItemId, days, userIds, dayIdToIgnore);
        if (this.availabilityResponse) {
          if (this.availabilityResponse.length > 0) {
            this.editionsInConflict = this.availabilityResponse;
            this.openAvailabilityConflictModal();
          } else {
            // Confermo la presenza
            let senecaResponse = await this.takerService.userSelfConfirmExternal(this.selectedEditionForConfirmPresence.courseDateId, ItemTakerEnrollStatusTypes.USER_STATUS_PRESENT, this.selectedEditionForConfirmPresence.usedTakes).toPromise();
            if (senecaResponse.errorMessage || senecaResponse.errorCodes) {
              this.errorMessageVPL = senecaResponse.errorMessage;
              this.errorCodesVPL = [];
              senecaResponse.errorCodes.forEach(element => {
                let array = element.split(", ");

                let status;
                let message;

                array.forEach(el => {
                  if (el.toLowerCase().startsWith("messaggio")) {
                    message = el;
                  }
                  if (el.toLowerCase().startsWith("status")) {
                    status = el;
                  }
                });

                if (status && message) {
                  this.errorCodesVPL.push({
                    message: message,
                    status: status
                  })
                }
              });

              this.openVplModal();
            }
            if (senecaResponse.error) {
              this.toastr.error(this.translate.instant('errors.' + senecaResponse.error));
            }
            if (!senecaResponse.error && !senecaResponse.errorMessage && !senecaResponse.errorCodes) {
              let takerIds = [takerId];
              let minToSet = this.getCourseMins(days);
              let ItemTakerEnrollAttribute = {
                "attributeType": "COURSE_PRESENCE_MINUTES",
                "attributeValue": minToSet
              };

              this.setTakerEnrollAttribute(takerIds, editionItemIds, ItemTakerEnrollAttribute);
            }

            this.selectedEditionForConfirmPresence = null;
            this.isGettingItemDetails = false;
            this.reloadEditions();
          }
        }
      }
    } else {
      // Confermo l'assenza
      let senecaResponse = await this.takerService.userSelfConfirmExternal(this.selectedEditionForConfirmPresence.courseDateId, ItemTakerEnrollStatusTypes.USER_STATUS_ABSENT, this.selectedEditionForConfirmPresence.usedTakes).toPromise()
      if (senecaResponse.errorMessage || senecaResponse.errorCodes) {
        this.errorMessageVPL = senecaResponse.errorMessage;
        this.errorCodesVPL = [];
        senecaResponse.errorCodes.forEach(element => {
          let array = element.split(", ");

          let status;
          let message;

          array.forEach(el => {
            if (el.toLowerCase().startsWith("messaggio")) {
              message = el;
            }
            if (el.toLowerCase().startsWith("status")) {
              status = el;
            }
          });

          if (status && message) {
            this.errorCodesVPL.push({
              message: message,
              status: status
            })
          }
        });

        this.openVplModal();
      }
      if (senecaResponse.error) {
        this.toastr.error(this.translate.instant('errors.' + senecaResponse.error));
      }
      this.selectedEditionForConfirmPresence = null;
      this.isGettingItemDetails = false;
      this.reloadEditions();
    }
  }

  async setTakerEnrollAttribute(takerIds, editionItemIds, ItemTakerEnrollAttribute) {
    try {
      let res = await this.initiativeService.setTakerEnrollAttribute(takerIds, editionItemIds, ItemTakerEnrollAttribute).toPromise();
      if (res.error) {
        this.toastr.error(this.translate.instant('errors.' + res.error));
      }
    } catch (er) {
    }
  }

  getCourseMins(days) {
    let d = [];
    let morning = [];
    let afternoon = [];

    days.forEach(element => {
      if (element !== null && element !== undefined) {
        element = moment(element).format("HH:mm")
        d.push(element)
      }
    });

    let orderedDays = d.sort();

    if (orderedDays.length === 2 || orderedDays.length === 3) {
      morning.push(orderedDays[0]);
      morning.push(orderedDays[1]);
    } else {
      morning.push(orderedDays[0]);
      morning.push(orderedDays[1]);
      afternoon.push(orderedDays[2]);
      afternoon.push(orderedDays[3]);
    }

    morning = morning.sort();
    afternoon = afternoon.sort();

    let min1;
    let min2;

    min1 = this.getDiffTime(morning[0], morning[1])
    if (afternoon.length > 1) {
      min2 = this.getDiffTime(afternoon[0], afternoon[1])
    } else {
      min2 = 0;
    }

    return min1 + min2
  }

  getDiffTime(oraI, oraF) {
    oraI = oraI.replace(':', '.');
    oraF = oraF.replace(':', '.');

    let minI = (oraI - parseInt(oraI)) * 100;
    let minF = (oraF - parseInt(oraF)) * 100;

    let i = parseInt(oraI) * 60 + minI;
    let f = parseInt(oraF) * 60 + minF;

    let diff = f - i;
    diff = Math.round(diff);
    let ore = diff / 60;
    ore = parseInt(ore.toString())
    let min = Math.round((diff / 60 - ore) * 60);

    return min = min + (ore * 60);
  }


  dismissConfirmPresenceModal() {
    this.selectedEditionForConfirmPresence = null;
    this.modalService.close('confirmCoursePresenceModal');
  }

  confirmConfirmPresenceModal() {
    this.modalService.close('confirmCoursePresenceModal');
    // Apro la modale per la conferma
    this.modalService.open('presenceConfirmation');
    document.getElementById('presenceConfirmation').focus()
  }

  // Apre una modale per chiedere conferma sulla rimozione della iniziativa selezionate
  openSubscriptionDetailsModal(edition) {
    // Archivio l'iniziativa che mi è stata passata
    this.selectedEditionForConfirmSubscription = { ...edition };
    this.selectedEditionForConfirmSubscription.customTitle = this.getEditionCustomTitle(this.selectedEditionForConfirmSubscription) || this.calculateEditionCustomTitle(this.selectedEditionForConfirmSubscription);
    this.selectedEditionForConfirmSubscription.customSubTitle = this.getEditionCustomSubTitle(this.selectedEditionForConfirmSubscription) || this.calculateEditionCustomSubTitle(this.selectedEditionForConfirmSubscription);
    //Poichè i corsi Online e gli eventi esterni non hanno una stopDate(fine iscrizione) inserisco la endDate nello stopDate per non ricevere un invalid date nel dettaglio dell'item
    //e sottrago un'ora a front-end poichè senza restituisce la data con un'ora in più (es. db 12/01/2019 ore 23.00 -> fe 13/01/2019 ore 00.00)
    //  if (this.isOnlineCourse || this.isExternalEvent) {
    if (!this.selectedEditionForConfirmSubscription.stopDate) {
      this.selectedEditionForConfirmSubscription.stopDate = moment(this.selectedEditionForConfirmSubscription.endDate).format('lll');
    } else {
      this.selectedEditionForConfirmSubscription.stopDate = moment(this.selectedEditionForConfirmSubscription.stopDate).format('lll');
    }

    if (!this.selectedEditionForConfirmSubscription.isConfirmed && !this.selectedEditionForConfirmSubscription.isObConfirmed) {
      this.selectedEditionForConfirmSubscription.confirmSecondBtnLabel = this.translate.instant('generic.CLOSE');
    } else {
      this.selectedEditionForConfirmSubscription.confirmBtnLabel = this.translate.instant('takers.courseSubscription.modal.cancelSubscription.TITLE');
    }
    this.selectedEditionForConfirmSubscription.cancelBtnLabel = this.selectedEditionForConfirmSubscription.confirmSecondBtnLabel ? null : this.translate.instant('generic.CANCEL')
    this.modalService.open('confirmCourseSubscription');
    document.getElementById('confirmCourseSubscription').focus();
  }

  dismissCancelSubscriptionModal() {
    this.modalService.close('cancelCourseSubscription');
    this.selectedEditionForCancelSubscription = null;
  }

  openCancelSubscriptionModal(edition) {
    // Archivio l'iniziativa che mi è stata passata
    this.selectedEditionForCancelSubscription = edition;
    this.selectedEditionForCancelSubscription.cancelMessage = '';
    this.modalService.open('cancelCourseSubscription');
    document.getElementById('cancelCourseSubscription').focus();
  }

  confirmCancelSubscriptionModal() {
    this.cancelSubscription(this.selectedEditionForCancelSubscription, this.selectedEditionForCancelSubscription.cancelMessage);
    this.selectedEditionForCancelSubscription = null;
  }

  cancelSubscription(edition, cancelMessage: string) {
    edition.cancelSubscriptionNote = cancelMessage;
    this.cancelParticipation(edition, cancelMessage);
  }

  onCancelMessageChanged(newVal?) {
    if (this.selectedEditionForCancelSubscription) {
      this.selectedEditionForCancelSubscription.cancelMessage = newVal;
    }
  }

  // Cancella la partecipazione ad una edizione
  cancelParticipation(courseEdition, eventData) {
    const statusNote = !!eventData && typeof eventData == 'string' ? eventData : '';
    let editionId;
    let stageId;
    if (courseEdition.courseDateId) {
      editionId = courseEdition.courseDateId;
    } else if (courseEdition.itemId) {
      stageId = courseEdition.itemId;
    }
    this.takerService.userSelfCancel(this.loggedUser.user.userId, (editionId || null), statusNote, true, (stageId || null))
      .subscribe(genericResponseSubscriber(this, (senecaResponse) => {
        this.modalService.close('cancelCourseSubscription');
        // Forzo il caricamento della pagina per recuperare riassegnare i controlli per la card principale per testo e CTA
        location.reload();
        // if (stageId) {
        //   location.reload();
        // } else if (editionId) {
        //   this.reloadEditions();
        // }
      }, (senecaResponse) => {
        if (senecaResponse.error) {
          this.toastr.error(this.translate.instant('errors.' + senecaResponse.error));
        }
        if (senecaResponse.errorMessage || senecaResponse.errorCodes) {
          this.errorMessageVPL = senecaResponse.errorMessage;
          this.errorCodesVPL = [];
          senecaResponse.errorCodes.forEach(element => {
            let array = element.split(", ");

            let status;
            let message;

            array.forEach(el => {
              if (el.toLowerCase().startsWith("messaggio")) {
                message = el;
              }
              if (el.toLowerCase().startsWith("status")) {
                status = el;
              }
            });

            if (status && message) {
              this.errorCodesVPL.push({
                message: message,
                status: status
              })
            }
          });
          this.openVplModal();
          this.modalService.close('cancelCourseSubscription');
        }
      }));
  }

  changeUsedTakes(courseEdition, newValue) {
    if (courseEdition && courseEdition.courseDateId) {
      courseEdition.usedTakes = newValue;
    }
  }

  // Torna il titolo custom dell'edizione
  getEditionCustomTitle(edition?): string {
    let uniqueEdition = edition || (this.editions && this.editions[0]);
    let isCustomTitleEnabled = edition && edition.hasCustomTitle;
    if (uniqueEdition && isCustomTitleEnabled && uniqueEdition.itemLangs && uniqueEdition.itemLangs.length) {
      let initiativeIndex: number = this.langsService.findItemLangIndex(this.applicationLang, uniqueEdition);
      return uniqueEdition.itemLangs[initiativeIndex].title;
    } else {
      return null;
    }
  }

  // Torna il sottotitolo custom dell'edizione
  getEditionCustomSubTitle(edition?): string {
    let uniqueEdition = edition || (this.editions && this.editions[0]);
    let isCustomTitleEnabled = edition && edition.hasCustomTitle;
    if (uniqueEdition && isCustomTitleEnabled && uniqueEdition.itemLangs && uniqueEdition.itemLangs.length) {
      let initiativeIndex: number = this.langsService.findItemLangIndex(this.applicationLang, uniqueEdition);
      return uniqueEdition.itemLangs[initiativeIndex].subTitle;
    } else {
      return null;
    }
  }

  requestItemInCatalog() {
    this.isGettingItemDetails = true;
    let itemId = this.itemToRequstCatalogPartecipation.itemId || this.itemToRequstCatalogPartecipation.courseDateId;
    this.addStandaloneCatalogItemRequest$ = this.itemService.addStandaloneCatalogItemRequest(itemId)
      .subscribe(data => {
        if (data.error) {
          this.toastr.error(this.translate.instant('errors.' + data.error));
        } else {
          if (this.itemToRequstCatalogPartecipation.courseDateId) {
            for (let i = 0, editionsLength = this.editions.length; i < editionsLength; i++) {
              let currentEdition: any = this.editions[i];
              if (currentEdition.courseDateId === this.itemToRequstCatalogPartecipation.courseDateId) {
                currentEdition.alreadyAskedForCatalog = true;
                break;
              }
            }
          } else {
            this.alreadyAskedForCatalog = true;
          }

          this.toastr.success(this.translate.instant(this.translate.instant('generic.PARTECIPATION_REQUESTED')));
        }
        this.isGettingItemDetails = false;
      },
        (err) => {
          this.isGettingItemDetails = false;
        });
  }

  // Apre una modela per chiedere la conferma della richiesta a catalogo
  openConfirmCatalogRequestModal() {
    this.modalService.open('confirmCatalogRequestModal');
    document.getElementById('confirmCatalogRequestModal').focus();
  }
  closeConfirmCatalogRequestModal(confirm?) {
    this.modalService.close('confirmCatalogRequestModal');
    if (confirm) {
      this.requestItemInCatalog();
    }
  }

  async subscribeToCourseEdition(courseEdition) {
    let stageItemId = courseEdition.stageItemId;
    let userIds = [this.loggedUser.user.userId]
    let days = [];
    let dayIdToIgnore = [];

    // recupero i giorni
    this.editionToCheck.days.forEach(element => {
      if (!dayIdToIgnore.includes(element.itemId)) {
        dayIdToIgnore.push(element.itemId);
      }

      element.timeSchedules.forEach(el => {
        days.push(el.startTime);
        days.push(el.endTime);
      });
    });

    // Controllo se l'utente è docente
    await this.listSupplierPersonByUserId();
    if (this.supplierPerson && this.supplierPerson !== "OBJECT_NOT_FOUND") {
      // controllo docenze concomitanti
      await this.teacherAvailabilityCheck(null, days, stageItemId, dayIdToIgnore);
    }
    if (this.teacherAvailabilityResponse) {
      // se ho docenze concomitanti mostro una modale bloccante
      this.openTeacherAvailabilityConflictModal();
    } else {
      // controllo corsi concomitanti (discente)
      await this.availabilityCheckVPL(stageItemId, days, userIds, dayIdToIgnore);

      if (this.availabilityResponse) {
        if (this.availabilityResponse.length > 0) {
          // se ho corsi concomitanti mostro la modale bloccante
          this.editionsInConflict = this.availabilityResponse;
          this.openAvailabilityConflictModal();
        } else {

          // Se è attivata la possibilità di richiesta a catalogo, mando una mail per richiedere la partecipazione
          if (this.canRequestFromCatalog && this.canAccessUserCatalog && this.canAskCatalogAsSimpleMail) {
            if (!courseEdition.alreadyAskedForCatalog) {
              this.itemToRequstCatalogPartecipation = courseEdition;
              this.openConfirmCatalogRequestModal();
            }
          } else {
            this.takerService.userSelfConfirm(this.loggedUser.user.userId, courseEdition.courseDateId, true, courseEdition.usedTakes, courseEdition.coursePrivacyAccepted)
              .subscribe(genericResponseSubscriber(this, (senecaResponse) => {
                this.reloadEditions();
              }, (senecaResponse) => {
                if (senecaResponse.errorMessage || senecaResponse.errorCodes) {
                  this.errorMessageVPL = senecaResponse.errorMessage;
                  this.errorCodesVPL = [];
                  senecaResponse.errorCodes.forEach(element => {
                    let array = element.split(", ");

                    let status;
                    let message;

                    array.forEach(el => {
                      if (el.toLowerCase().startsWith("messaggio")) {
                        message = el;
                      }
                      if (el.toLowerCase().startsWith("status")) {
                        status = el;
                      }
                    });

                    if (status && message) {
                      this.errorCodesVPL.push({
                        message: message,
                        status: status
                      })
                    }
                  });
                  this.openVplModal();
                }
                if (senecaResponse.error) {
                  this.toastr.error(this.translate.instant('errors.' + senecaResponse.error));
                }
              }));
          }
        }

      }
    }
  }

  async listSupplierPersonByUserId(userId?) {
    try {
      let res = await this.initiativeService.listSupplierPersonByUserId(userId).toPromise();
      if (res.error && res.error !== "OBJECT_NOT_FOUND") {
        this.toastr.error(this.translate.instant('errors.' + res.error));
      }

      if (res.error === "OBJECT_NOT_FOUND") {
        this.supplierPerson = res.error;
      }

      if (res.response) {
        this.supplierPerson = res.response;
      }

    } catch (error) {
    }
  }

  showSocialActionBar() {
    return (!this.canRequestFromCatalog || (this.canRequestFromCatalog && this.itemDetails && this.itemDetails.existsItemTakerVisibility && this.canAskCatalogAsSimpleMail))
      && !this.objectNotFound && !this.isGettingItemDetails;
  }

  // Conferma l'iscrizione e porta alla library
  confirmUserAndGoToLibrary(courseEdition) {
    this.takerService.userSelfConfirm(this.loggedUser.user.userId, courseEdition.courseDateId, false, courseEdition.usedTakes, courseEdition.coursePrivacyAccepted)
      .subscribe(genericResponseSubscriber(this, (senecaResponse) => {
        this.openLinkedItem(this.itemDetails.itemId, this.itemIdLinkedToCourse);
      }, (senecaResponse) => {
        if (senecaResponse.errorMessage || senecaResponse.errorCodes) {
          this.errorMessageVPL = senecaResponse.errorMessage;
          this.errorCodesVPL = [];
          senecaResponse.errorCodes.forEach(element => {
            let array = element.split(", ");

            let status;
            let message;

            array.forEach(el => {
              if (el.toLowerCase().startsWith("messaggio")) {
                message = el;
              }
              if (el.toLowerCase().startsWith("status")) {
                status = el;
              }
            });

            if (status && message) {
              this.errorCodesVPL.push({
                message: message,
                status: status
              })
            }
          });
          this.openVplModal();
        }
        if (senecaResponse.error) {
          this.toastr.error(this.translate.instant('errors.' + senecaResponse.error));
        }
      }));
  }

  // Ricarica le edizioni
  reloadEditions() {
    this.editions.length = 0;
    this.courseEndDate = null;
    if (this.editionsListWithPastEditions && this.editionsListWithPastEditions.length) {
      this.editionsListWithPastEditions.length = 0;
    }
    if (this.editionsSurvey && this.editionsSurvey.length) {
      this.editionsSurvey.length = 0;
    }
    if (this.certifiedEditions && this.certifiedEditions.length) {
      this.certifiedEditions.length = 0;
    }
    this.loadEditions();
  }

  // Inizializza l'item
  initData(skipItemType?: boolean) {
    if (!skipItemType) {
      this.setItemType();
    }

    this.store.select(fromApp.getLoggedUser).pipe(
      map((loggedUser: JwtPayload) => {
        this.loggedUser = loggedUser;
        // Se c'è il parametro con l'informazione del Learning Plan significa che sto aprendo un oggetto ad esso aggiunto. Dunque preparo i corrispettivi breadcrumb
        if (!this.breadcrumbs || !this.breadcrumbs.length) {
          this.manageBreadcrumbs();
        }

        // Se è una iniziativa carico le edizioni, altrimenti i contenuti correlati
        if (this.isSyllabusCourse) {
          this.loadEditions(true);
        } else {
          this.isLoadingEditions = false;
        }

        // this.loadLinkedItems();

        this.isVideo = this.itemDetails.isVideo;
        this.isOnlineCourse = InitiativeUtils.isOnlineInitiative(this.itemDetails.itemType);
        this.isBlendedStage = InitiativeUtils.isBlendedStage(this.itemDetails.itemType);

        // Formatto i testi
        ItemUtil.replaceEnterInTitleSubtitleDescription(this.itemDetails);

        // Verifico gli attributi dell'oggetto
        if (this.itemDetails.itemAttributes) {
          let itemAttributes = this.itemDetails.itemAttributes;
          this.linkUrls = [];
          for (let k = 0; k < itemAttributes.length; k++) {
            if ((itemAttributes[k].attributeType === ItemAttributeTypes.TECH_COMPETENCES
              || itemAttributes[k].attributeType === ItemAttributeTypes.SOFT_COMPETENCES)
              && itemAttributes[k].crossReferenceObject && itemAttributes[k].crossReferenceObject.title) {
              this.competences.push(itemAttributes[k].crossReferenceObject.title);
            } else if (itemAttributes[k].attributeType === ItemAttributeTypes.EXTERNAL_OBJECT_ID) {
              this.itemIdLinkedToCourse = itemAttributes[k].attributeValue;
            } else if (itemAttributes[k].attributeType === ItemAttributeTypes.LINK) {
              this.linkUrls.push(itemAttributes[k].attributeValue);
            }
          }

          if (!this.adminMode && !this.itemDetails.isBlendedProject) {
            this.getLikes();
          }

          // Se è un oggetto atomico e non sono in lato admin continuo caricando i dati dello scorm
          if (!this.itemDetails.isLearningPlan && !this.itemDetails.isProject && !this.itemDetails.isBlendedProject) {

            if (this.itemDetails.itemType && (this.itemDetails.itemType === ItemTypes.DAM_ITEM ||
              this.itemDetails.itemType === ItemTypes.DAM_ITEM_ENCLOSED)) {
              this.damPlayerVisible = true;
            };

            if (this.itemDetails.itemType && (this.itemDetails.itemType === ItemTypes.SCORM_FREE ||
              this.itemDetails.itemType === ItemTypes.SCORM_INVITE)) {
              this.scormPlayerVisibile = true;
            };

            this.damPlayerSeekTo = this.itemDetails.itemRegistration && this.itemDetails.itemRegistration.currentSeconds;
          }
        };

        if (!this.adminMode && (!this.isSyllabusCourse || this.isAssessment)) {
          this.getCertificationData(this.itemDetails);
        }

        this.subtype = this.itemDetails.subtype;
        this.certificationDate = this.itemDetails.certificationDate;
        this.hourValue = this.itemDetails.hourValue;

        // Setto la traduzione della tipologia
        if (this.subtype) {
          this.subtypeLabel = this.translate.instant('generic.itemTypes.' + this.itemDetails.subtype);
        };

        // Se non si tratta di un corso (CM) recupero le info sui child
        if (!this.isSyllabusCourse) {
          // this.tryLibraryItemTakerEnroll$ = this.takerService.tryLibraryItemTakerEnroll(this.itemDetails.itemId)
          //   .subscribe(data => {
          //     if (data.error || !data.response) {
          //       this.toastr.error(this.translate.instant('errors.' + data.error));
          //     }
          //   });

          if (this.itemDetails.itemChilds && (this.itemDetails.isLearningPlan || this.itemDetails.isProject || this.itemDetails.isBlendedProject)) {
            this.propedeuticReferenceIds = ItemUtil.getPropedeuticReferenceIds(this.itemDetails.itemChilds, true);
          }

          this.getItemChildsInfo(this.itemDetails);
          // Carico le tab per l'oggetto library
          this.loadTabs(true);
        }

        this.hourValueText = this.getHourValueText(this.itemDetails.hourValue);

        // Spengo il loader
        this.isGettingItemDetails = false;
      }),
      take(1)
    ).subscribe();
  }

  // Seleziona la nuova tab
  selectTab(tabName, preventScroll?: boolean) {
    this.currentTab = tabName;
    if (!preventScroll) {
      this.scrollIntoPage(tabName);
    }
  };

  isCourseMaterialsSectionVisible() {
    if (this.isSyllabusCourse && (this.preworkMaterialsCounter || this.classroomMaterialsCounter || this.postworkMaterialsCounter)) {
      return true;
    }

    return false;
  }

  // Setta la stringa relativa al monte ore
  getHourValueText(hourValueObj): string {
    let hourValueText: string = '';
    if (hourValueObj) {
      if (typeof hourValueObj === 'string') {
        hourValueText = hourValueObj || '';
      } else {
        if (hourValueObj.hours) {
          this.hourValueText = this.hourValueText + hourValueObj.hours + 'h' + ' ';
        }
        if (hourValueObj.minutes) {
          this.hourValueText = this.hourValueText + hourValueObj.minutes + 'm';
        };
      }
    }

    return hourValueText;
  }

  loadEditions(loadMaterials?) {
    if (this.syllabus) {
      this.isLoadingEditions = true;
      this.itemService.getCourseEditions(this.syllabus.itemId, this.itemId, true, true)
        .subscribe((editions) => {
          if (editions.error) {
            this.isLoadingEditions = false;
            // Vedo se c'è la traduzione dell'errore
            this.toastr.error(this.translate.instant('errors.' + editions.error));
          } else {
            if (editions.response.length) {
              // Ordino le edizioni, e loro date, per ordine temporale
              CourseEditionUtil.sortCourseEditionListForUser(editions.response);
              let editionsConfirmed = []
              editions.response.forEach((ed, i) => {
                if (ed.courseDateStatus != 'CONFIRMED') {
                  editions.response.splice(i, 1)
                }
              });
              this.editions = editions.response;

              for (let k = 0, editionsLength = editions.response.length; k < editionsLength; k++) {
                let currentEdition = editions.response[k];
                currentEdition.alreadyAskedForCatalog = currentEdition.catalogItemRequestDate;

                currentEdition.customPlace = this.getEditionLocation(currentEdition);
                if (currentEdition.days && currentEdition.days.length) {
                  let tmpTeachersTutors = [];
                  for (let m = 0, daysLength = currentEdition.days.length; m < daysLength; m++) {
                    let currentDay = currentEdition.days[m];
                    currentDay.formattedFullDate = currentDay.dayDate;
                    // Recupero la lista completa di docenti e relatori (tutor)
                    if (!currentEdition.tutors) {
                      currentEdition.tutors = [];
                    }
                    if (!currentEdition.teachers) {
                      currentEdition.teachers = [];
                    }
                    if (currentDay.teachers && currentDay.teachers.length) {
                      currentEdition.teachers.push(...currentDay.teachers);
                      tmpTeachersTutors.push(...currentDay.teachers);
                    }
                    if (currentDay.tutors && currentDay.tutors.length) {
                      currentEdition.tutors.push(...currentDay.tutors);
                      tmpTeachersTutors.push(...currentDay.tutors)
                    }
                  }
                  this.getTeachersAndTutors(tmpTeachersTutors);
                }
                // Ultima e prima giornata dell'edizione
                const daysLength = currentEdition.days && currentEdition.days.length;
                currentEdition.lastDayDate = daysLength && currentEdition.days[daysLength - 1] && currentEdition.days[daysLength - 1].timeSchedules && currentEdition.days[daysLength - 1].timeSchedules.length && currentEdition.days[daysLength - 1].timeSchedules[currentEdition.days[daysLength - 1].timeSchedules.length - 1] && currentEdition.days[daysLength - 1].timeSchedules[currentEdition.days[daysLength - 1].timeSchedules.length - 1].endTime;
                currentEdition.firstDayDate = currentEdition.days && currentEdition.days[0] && currentEdition.days[0].timeSchedules && currentEdition.days[0].timeSchedules[0] && currentEdition.days[0].timeSchedules[0].startTime;
                // Se non c'è lo userStatus e l'utente può iscriversi o cancellarsi, do per scontato che tale utente sia invitato a livello di iniziativa e non di edizione (e quindi lo userStatus sulla currentEdition è mancante)
                if (!currentEdition.userStatus) {
                  currentEdition.noStatusForPreiscrition = true;
                  if (currentEdition.canCancel || currentEdition.canRegister) {
                    /* currentEdition.userStatus = ItemTakerEnrollStatusTypes.USER_STATUS_INVITED; */
                  } else {
                    // Se è nel passato e non ho azioni, lo setto come Non iscritto
                    let now = new Date().toUTCString();
                    let isInThePast = moment((currentEdition.endDate || now)).isAfter(now) ? true : false;
                    if (isInThePast) {
                      currentEdition.userStatus = null;
                    }
                  }
                }
                currentEdition.formattedStatus = currentEdition.userStatus && this.translate.instant('awWizard.invited.takerStatusesForUser.' + currentEdition.userStatus) || '';
                currentEdition.formattedStopDate = currentEdition.stopDate && moment(currentEdition.stopDate).format('DD/MM/YYYY') || '';
                currentEdition.isOnlineEvent = currentEdition.endDate && currentEdition.onlineEventLinkTitle && currentEdition.onlineEventLink;
                if (!currentEdition.formattedStopDate && (currentEdition.isOnlineEvent || this.isOnlineCourse)) {
                  // E' un evento online, quindi come data di scadenza faccio vedere la data di fine
                  if (!currentEdition.stopDate) {
                    currentEdition.formattedStopDate = currentEdition.endDate && moment(currentEdition.endDate).utc().format('DD/MM/YYYY') || '';
                  } else {
                    currentEdition.formattedStopDate = currentEdition.stopDate && moment(currentEdition.stopDate).utc().format('DD/MM/YYYY') || '';
                  }
                }
                currentEdition.isConfirmed = currentEdition.userStatus && currentEdition.userStatus === ItemTakerEnrollStatusTypes.USER_STATUS_CONFIRMED;
                currentEdition.isObConfirmed = currentEdition.userStatus && currentEdition.userStatus === ItemTakerEnrollStatusTypes.USER_STATUS_OVERBOOKING_CONFIRMED;
                currentEdition.isPresent = currentEdition.userStatus && currentEdition.userStatus === ItemTakerEnrollStatusTypes.USER_STATUS_PRESENT;
                currentEdition.isAbsent = currentEdition.userStatus && currentEdition.userStatus === ItemTakerEnrollStatusTypes.USER_STATUS_ABSENT;
                currentEdition.isCancelled = currentEdition.userStatus && currentEdition.userStatus === ItemTakerEnrollStatusTypes.USER_STATUS_CANCELLED;
                currentEdition.isInvited = currentEdition.userStatus && currentEdition.userStatus === ItemTakerEnrollStatusTypes.USER_STATUS_INVITED;
                currentEdition.isApproved = currentEdition.userStatus && currentEdition.userStatus === ItemTakerEnrollStatusTypes.USER_STATUS_APPROVED;
                // Salvo il fatto che si tratta di un online anche nell'edizione
                currentEdition.isOnlineCourse = this.isOnlineCourse || currentEdition.isOnlineEvent;
                currentEdition.isExternalEvent = this.isExternalEvent;
                // Calcolo i posti disponibili
                if (currentEdition.seats && currentEdition.seats.length) {
                  currentEdition.usedSeats = currentEdition.seats[0].usedSeats;
                  currentEdition.seatsLimit = currentEdition.seats[0].totalSeats;
                  currentEdition.availableSeats = (!currentEdition.usedSeats && currentEdition.seatsLimit)
                    || (currentEdition.seatsLimit && currentEdition.usedSeats >= 0 && (currentEdition.seatsLimit - currentEdition.usedSeats)) || 0;
                };
              }
              if (this.isOnlineCourse && editions.response && editions.response.length
                && editions.response[0].userStatus && (editions.response[0].userStatus === ItemTakerEnrollStatusTypes.USER_STATUS_INVITED || editions.response[0].userStatus === ItemTakerEnrollStatusTypes.USER_STATUS_CONFIRMED)) {

                if (!editions.response[0].stopDate && editions.response[0].endDate) {
                  this.courseEndDate = new Date(editions.response[0].endDate).toLocaleString([], { year: 'numeric', month: 'short', day: 'numeric', hour: '2-digit', minute: '2-digit' }).replace(',', '');
                } else if (editions.response[0].stopDate) {
                  this.courseEndDate = new Date(editions.response[0].stopDate).toLocaleString([], { year: 'numeric', month: 'short', day: 'numeric', hour: '2-digit', minute: '2-digit' }).replace(',', '');
                }
              }

              this.editionsListWithPastEditions = editions.response;
              this.editions = editions.response;
              /* Rimuovo le edizioni a cui non posso più iscrivermi (questo solo se non è un corso online, poichè esso non ha data di fine iscrizioni).
              Altra eccezione:
              se si tratta di un corso esterno (online o in presenza), se la data di fine dell’edizione è passata
              e se lo stato dell’utente è diverso da USER_STATUS_PRESENT e da USER_STATUS_ABSENT,
              l’utente ha la possibilità di segnalare la sua presenza o la sua assenza.
              Pertanto è necessario mostrare l'edizione.

              if (!this.isOnlineCourse) {
                this.editions = this.editions.filter((edition: any) => {
                  let now = new Date().toUTCString();
                  //  return (moment((edition.endDate || now)).isAfter(now)) || edition.isPresent;
                  return this.isExternalCourse || (moment((edition.stopDate || now)).isAfter(now)) || edition.isPresent;
                });
              }
                update: vengono mostrate tutte le edizioni passate.
                se la categoria di formazione è esterna e la metodologia è diversa da questionario o corso online asincrono
                si può segnalare la  presenza o l'assenza.
              */
              // Se è un corso online devo recuperare l'eventuale oggetto Library collegato
              if (this.isOnlineCourse && this.itemIdLinkedToCourse) {
                this.getLibraryItemLinkedToCourse();
              } else {
                this.isOpenDamBtnDisabled = false;
              }
              // Per ogni edizione, cerco se c'è una rilevazione agganciata
              let getAssessmentsOfEditionsPromise = this.getAssessmentsOfEditions(this.editionsListWithPastEditions);
              getAssessmentsOfEditionsPromise.then(() => {
                const assessmentIds = this.editions.map((edition: any) => edition.assessmentId);
                if (assessmentIds && assessmentIds.length) {
                  // E' stato deciso che se sono in una iniziativa, in una tab isolata faccio vedere le sessioni (i questionari) disponibili
                  /*  if (!this.isAssessment) {
                      for (let r = 0, assessmentIdslength = assessmentIds.length; r < assessmentIdslength; r++) {
                        let currentAssessmentId = assessmentIds[r];
                        this.editions = this.editions.filter((currentConfirmedEdition: any) => {
                          return currentConfirmedEdition.stageItemId !== currentAssessmentId;
                        });
                      } */
                  //   } else {
                  // Se sono nel dettaglio di un assessment, devo rimuovere le altre edizioni e lasciare solo la sessione del questionario
                  // Inoltre devo rimuovere le sessioni del questionario che iniziano nel futuro (nel caso delle sessioni non c'è data di fine iscrizione)
                  if (this.isAssessment) {
                    this.editions = this.editions.filter((currentEdition: any) => {
                      const now = new Date().toUTCString();
                      const isInFuture = currentEdition.startDate && (moment((currentEdition.startDate)).isAfter(now));
                      return !isInFuture && currentEdition.stageItemId === this.itemId;
                    });
                  }
                  //  }
                }
                this.editionsByPlace = this.formatEditionsByPlace(this.editions);
                let getCertificationsOfEditionsPromise = this.searchCertificationsDone(this.editionsListWithPastEditions);
                getCertificationsOfEditionsPromise.then(() => {
                  // Recupero le edizioni per le quali ho completato correttamente un questionario (oppure non è previsto) e lo statè presente, così da dare la possibilità all'utente di scaricare il certificato
                  if (!this.isAssessment && this.editions && this.editions.length) {
                    for (let e = 0, editionsToCheckLength = this.editions.length; e < editionsToCheckLength; e++) {
                      let currentEditionToCheck: any = this.editions[e];
                      if (currentEditionToCheck.isPresent) {
                        if (currentEditionToCheck.assessmentId && this.editionsSurvey && this.editionsSurvey.length) {
                          for (let editionsSurveyCounter = 0, editionsSurveyLength = this.editionsSurvey.length; editionsSurveyCounter < editionsSurveyLength; editionsSurveyCounter++) {
                            let currentEditionSurvey = this.editionsSurvey[editionsSurveyCounter];
                            if (currentEditionSurvey.stageItemId === currentEditionToCheck.assessmentId) {
                              let editionOfAssessment = this.getEditionOfAssessment(currentEditionSurvey);
                              if (editionOfAssessment && editionOfAssessment.isSurveyPassed) {
                                this.certifiedEditions.push(currentEditionToCheck);
                              }
                            }
                          }
                        } else {
                          // Non è prevista una survey e lo stato è presente, quindi l'utente può scaricare il certificato
                          this.certifiedEditions.push(currentEditionToCheck);
                        }
                      }
                    }
                  }

                  // Per ogni edizione, verifico se l'utente deve accettare la privacy
                  this.isCoursePrivacyToAccept(this.editions);
                  if (loadMaterials) {
                    this.loadAllCountMaterials()
                      .then(() => {
                        this.loadUserMaterialList('MATERIAL_PRE_WORK', null, true);
                      });
                  }
                  this.isLoadingEditions = false;
                })
                  .catch(() => {
                    this.isLoadingEditions = false;
                  })
              })
                .catch(() => {
                  this.isLoadingEditions = false;
                })
              this.canPreiscritionOnEditions = this.checkIfUserCanPreiscritionOnEditions();
              // Il toastr di errore si vede o quando non è un corso del cm2 oppure quando è un corso del cm2 ma con associato un item library.
              // Questo per evitare che lo stesso messaggio di errore si veda anche nel box principale getMainTextOfMainBox()
              if (this.showNoAuthToOperateMessage() && !this.canPreiscritionOnEditions) {
                this.toastr.error(this.translate.instant('errors.CANT_DO_SUGGESTED_ITEM'));
              }
              // Recupero i taker dell'utente
              this.getUserTakers();
            } else {
              if (loadMaterials) {
                this.loadAllCountMaterials()
                  .then(() => {
                    this.loadUserMaterialList('MATERIAL_PRE_WORK', null, true);
                  });
              }
              this.isLoadingEditions = false;
            }
          }
        }, (err) => {
          this.isLoadingEditions = false;
          if (err && err.message) {
            // Vedo se c'è la traduzione dell'errore
            this.toastr.error(this.translate.instant('errors.' + err.message));
          }
        })



      //recupero la lingua scelta se presente
      this.hasLanguage = false;

      if (ItemUtil.getAttributeByKey(this.syllabus, 'LANGUAGE')) {
        this.translate.get([
          'awWizard.generalInfo.syllabusPlaceholders.ITALIAN',
          'awWizard.generalInfo.syllabusPlaceholders.ENGLISH'
        ]).subscribe(translations => {
          this.languageList = [
            { name: translations['awWizard.generalInfo.syllabusPlaceholders.ITALIAN'], code: 'it' },
            { name: translations['awWizard.generalInfo.syllabusPlaceholders.ENGLISH'], code: 'en' }];

          this.hasLanguage = true;
          if (ItemUtil.getAttributeByKey(this.syllabus, 'LANGUAGE').attributeValue == 'it') {
            this.selectedLanguage = this.languageList[0].name
          } else {
            this.selectedLanguage = this.languageList[1].name
          }
        });
      }

    } else {
      this.isLoadingEditions = false;
    }
  }

  isFruitionInfoPanelVisible() {
    if ((this.itemDetails && (this.itemDetails.isVideo || this.scormPlayerVisibile))
      || (this.itemLinkedToCourse && (this.itemLinkedToCourse.isVideo || this.itemLinkedToCourse.scormPlayerVisibile))) {
      return true;
    }
    return false;
  }

  isHourValueInfoPanelVisible() {
    if (this.hourValueText || (this.itemLinkedToCourse && this.itemLinkedToCourse.hourValueText)
      || (this.isOnlineCourse && this.durationSyllabusFilled())
    ) {
      return true;
    }

    return false;
  }

  isItemInfoPanelVisible() {
    if (this.isHourValueInfoPanelVisible() || this.courseEndDate
      || this.isFruitionInfoPanelVisible()) {
      return true;
    }

    return false;
  }

  isEditionsSectionVisible() {
    if (!this.isAssessment && !this.isLoadingEditions && this.isSyllabusCourse && this.editions && this.editions.length) {
      return true;
    }

    return false;
  }

  // Verifica se mostrare l'errore "Non hai più le autorizzazioni per operare su questo contenuto"
  showNoAuthToOperateMessage() {
    // return this.itemDetails && (!this.isInCatalog && !this.itemDetails.existsItemTakerVisibility)
    // || (this.isInCatalog && !this.canAskCatalogAsSimpleMail && this.canAccessUserCatalog && !this.canRequestFromCatalog);
    return !(
      !this.itemDetails ||
      (this.itemDetails && this.itemDetails.existsItemTakerVisibility) ||
      (this.isInCatalog && (this.canAskCatalogAsSimpleMail || (this.canAccessUserCatalog && this.canRequestFromCatalog)))
    );
  }

  // Verifica se mostrare il messaggio per richiedere la partecipazione
  showAskPartecipationMessage() {
    return this.isInCatalog && this.canRequestFromCatalog && this.canAccessUserCatalog && this.canAskCatalogAsSimpleMail;
  }


  getMainTextOfMainBox() {
    let text = '';
    if (this.hasStatusNegatedOnInitiative) {
      text += this.translate.instant('errors.CANT_REGISTER');
    } else if (!this.isCurrentlyInvitedOrApprovedInInitiativeOrEdition
      && (!this.canPreiscritionOnInitiative && !this.canCancelPreiscritionOnInitiative && !this.canPreiscritionOnEditions)
      && (this.showNoAuthToOperateMessage() || this.showAskPartecipationMessage())) {
      if (this.showNoAuthToOperateMessage()) {
        text += this.translate.instant('errors.CANT_DO_SUGGESTED_ITEM');
      } else if (this.showAskPartecipationMessage()) {
        text += this.translate.instant('errors.ASK_PARTECIPATION_TO_OPEN_ITEM');
      }
    } else if (!this.isCurrentlyInvitedOrApprovedInInitiativeOrEdition && (this.canPreiscritionOnInitiative || this.canCancelPreiscritionOnInitiative)) {
      if (this.canPreiscritionOnInitiative) {
        text += this.translate.instant('errors.CAN_PREREGISTER_ON_COURSE');
      } else if (this.canCancelPreiscritionOnInitiative) {
        text += this.translate.instant('errors.CAN_CANCEL_PREREGISTER_ON_COURSE');
      }
    } else {
      if (this.itemDetails) {
        if (this.isAssessment) {
          if (this.itemDetails && this.editions && this.editions.length) {
            if (!this.itemDetails.isCertified) {
              // Se non ho già completato il questionario, allora aggiungo la data di scadenza
              text = text + this.translate.instant('generic.DO_SURVEY');
              // La rilevazione avrà sempre e solo un'edizione
              for (let d = 0, editionsLength = this.editions.length; d < editionsLength; d++) {
                let currentEdition: any = this.editions[d];
                if (currentEdition.formattedStopDate) {
                  text = text + ' ' + this.translate.instant('generic.WITHIN_THE').toLowerCase() + ' ' + currentEdition.formattedStopDate;
                }
                break;
              }
            } else {
              text = text + this.translate.instant('generic.YOU_COMPLETED_SURVEY');
            }
          } else {
            // Sono in una survey del cm2 ma non ci sono edizioni (magari sono tutte nel futuro), quindi avverto l'utente
            text = text + this.translate.instant('generic.NO_AVAILABLE_SURVEYS');
          }
        } else {
          let itemRef = this.isOnlineCourse && this.itemLinkedToCourse && this.itemLinkedToCourse.itemId ? this.itemLinkedToCourse : this.itemDetails;
          let presubEnabled = itemRef?.itemAttributes.find((attr: any) => attr.attributeType == ItemAttributeTypes.PRE_REGISTRATION_ENABLED && attr.attributeValue == "true");

          // controllo se è abilitata la preiscrizione
          if (!this.isCurrentlyInvitedOrApprovedInInitiativeOrEdition && presubEnabled && presubEnabled.attributeId && !this.canPreiscritionOnEditions) {
            let preEndDate = itemRef?.itemAttributes.find((attr: any) => attr.attributeType == ItemAttributeTypes.PRE_REGISTRATION_END_DATE);
            // se può preiscriversi ma la data di fine preiscrizioni è scaduta, non mostro il pulsante
            if (preEndDate && moment().isAfter(moment(preEndDate.attributeValue))) {
              text = text + this.translate.instant('generic.PRESUB_ENDED');
            }
          } else if (!this.isCurrentlyInvitedOrApprovedInInitiativeOrEdition && this.canPreiscritionOnEditions) {
            text = text + this.translate.instant('items.CHOOSE_AVAILABLE_EDITION');
          } else {
            let subscribedEditions = this.getSubscribedEditions();
            if (subscribedEditions && subscribedEditions.length) {
              text = text + this.translate.instant('generic.VIEW_ALL_AVAILABLE_EDITIONS');
            } else {
              // Se è un corso esterno online o un evento, non ho la possibilità di iscrivermi ai corsi
              if (this.isExternalCourseOnline || this.isExternalEventOnline) {
                text = text + this.translate.instant('generic.VIEW_EDITIONS_FOR_SUBSCRIBE_LINK_LABEL');
              } else {
                text = text + this.translate.instant('items.CHOOSE_AVAILABLE_EDITION');
              }
            }
          }
        }
      }
    }

    return text;
  }

  // Recupera l'oggetto Library collegato al corso online
  getLibraryItemLinkedToCourse() {
    this.getItemDetails$ = this.itemService.getConsumableItemByIdForUser(this.translate, this.itemIdLinkedToCourse, true, 4, attributeTypesToRetrieve, !this.adminMode, (this.isItemsAdminPreview || this.isInitiativesAdminPreview || this.isInitiativesAdminFromHomePreview || this.isManagerPreview))
      .pipe(
        map((linkedItem) => {
          if (linkedItem && linkedItem.itemId) {
            linkedItem.hourValueText = this.getHourValueText(linkedItem.hourValue);

            if (linkedItem.itemAttributes) {
              this.linkUrls = [];
              for (let k = 0; k < linkedItem.itemAttributes.length; k++) {
                if ((linkedItem.itemAttributes[k].attributeType === ItemAttributeTypes.TECH_COMPETENCES
                  || linkedItem.itemAttributes[k].attributeType === ItemAttributeTypes.SOFT_COMPETENCES)
                  && linkedItem.itemAttributes[k].crossReferenceObject && linkedItem.itemAttributes[k].crossReferenceObject.title) {
                  this.competences.push(linkedItem.itemAttributes[k].crossReferenceObject.title);
                } else if (linkedItem.itemAttributes[k].attributeType === ItemAttributeTypes.LINK) {
                  this.linkUrls.push(linkedItem.itemAttributes[k].attributeValue);
                }
              }
            }
            if (!linkedItem.isLearningPlan && !linkedItem.isProject && !linkedItem.isBlendedProject) {
              if (linkedItem.itemType && (linkedItem.itemType === ItemTypes.DAM_ITEM ||
                linkedItem.itemType === ItemTypes.DAM_ITEM_ENCLOSED)) {
                linkedItem.damPlayerVisible = true;
              };

              if (linkedItem.itemType && (linkedItem.itemType === ItemTypes.SCORM_FREE ||
                linkedItem.itemType === ItemTypes.SCORM_INVITE)) {
                linkedItem.scormPlayerVisibile = true;
              };

              if (linkedItem) {
                linkedItem.damPlayerSeekTo = linkedItem.itemRegistration && linkedItem.itemRegistration.currentSeconds;
              }
            };

            this.getItemChildsInfo(linkedItem);
            this.getCertificationData(linkedItem);

            // Recupero i propedeuticReferenceIds degli itemChilds
            this.propedeuticReferenceIds = ItemUtil.getPropedeuticReferenceIds(linkedItem.itemChilds, true);
          }


          this.itemLinkedToCourse = linkedItem;
          this.isTraditionalPlayer = this.itemLinkedToCourse?.itemAttributes?.find(el => el.attributeType == ItemAttributeTypes.PLAYER_PREVENT_USER_CONTROL) ? true : false;
          this.damPlayerSeekTo = this.itemLinkedToCourse.itemRegistration && this.itemLinkedToCourse.itemRegistration.currentSeconds;

          let stageId = this.itemDetails.itemId;
          let editionId = this.editions && this.editions.length && this.editions[0].courseDateId;

          // Non è chiaro il perché del controllo sul damPlayerVisible
          let canEnroll = this.itemDetails.existsItemTakerVisibility &&
            (this.itemLinkedToCourse.isVideo && this.itemLinkedToCourse.damPlayerVisible || !this.itemLinkedToCourse.isVideo)
            && (this.itemDetails.itemTakers && this.itemDetails.itemTakers.length);

          if ((canEnroll) && this.isOnlineCourse && this.itemLinkedToCourse && stageId && editionId) {
            this.tryLibraryItemTakerEnroll$ = this.takerService.tryLibraryItemTakerEnroll(this.itemLinkedToCourse.itemId, stageId, editionId)
              .subscribe(data => {
                if (data.error || !data.response) {
                  if (data.error != 'AVAILABLE_TAKERS_NOT_FOUND') {
                    this.toastr.error(this.translate.instant('errors.' + data.error));
                  }
                  this.isOpenDamBtnDisabled = true;
                } else if (data.response) {
                  this.isOpenDamBtnDisabled = false;
                }
              },
                (err) => {
                  this.isOpenDamBtnDisabled = true;
                });
          } else {
            // Controllo se l'oggetto associato è atomico
            let isLinkedItemAtomic = ItemUtil.isAtomicItem(this.itemLinkedToCourse);
            // Controllo se è un dam
            if (this.itemLinkedToCourse.itemType && (this.itemLinkedToCourse.itemType === ItemTypes.DAM_ITEM ||
              this.itemLinkedToCourse.itemType === ItemTypes.DAM_ITEM_ENCLOSED)) {
              this.damPlayerVisible = true;
            };
            // Oppure se è uno scorm
            if (this.itemLinkedToCourse.itemType && (this.itemLinkedToCourse.itemType === ItemTypes.SCORM_FREE ||
              this.itemLinkedToCourse.itemType === ItemTypes.SCORM_INVITE)) {
              this.scormPlayerVisibile = true;
            };

            if (!(isLinkedItemAtomic && (this.damPlayerVisible || this.scormPlayerVisibile))) {
              this.singleItemWithoutStageDamOpenBtnDisabled = true;
              this.isOpenDamBtnDisabled = true;
            } else {
              this.isOpenDamBtnDisabled = false;
            }
          }

          return true;
        })
      )
      .subscribe(data => {
        // Ora che ho tutti i dati necessari, iniziatlizzo le tab
        this.loadTabs(true);
      });
  }

  // Per ogni edizione controllo se l'utente deve accettare la privacy
  isCoursePrivacyToAccept(editions: Array<any>) {
    let promises = [];
    // Oltre che la survey nelle edizioni devo cercarla anche a livello di iniziativa; per questo, aggiungo temporaneamente all'array di edizioni anche l'iniziativa (poi la rimuovo)
    if (!editions) {
      editions = [];
    }
    let tmpList = editions.concat(this.itemDetails);
    for (let j = 0, editionsLength = tmpList.length; j < editionsLength; j++) {
      let currentEdition = tmpList[j];
      promises.push(new Promise((resolve: Function, reject: Function) => {
        this.takerService.isCoursePrivacyToAccept(this.loggedUser.user.userId, currentEdition.assessmentId || currentEdition.courseDateId || currentEdition.itemId)
          .subscribe(
            (data: SenecaResponse<boolean>) => {
              if (data.error) {
                this.toastr.error(this.translate.instant('errors.' + data.error));
                reject();
              } else {
                currentEdition.hasToAcceptPrivacy = data.response;
                resolve();
              }
            },
            (err) => {
              this.toastr.error(this.translate.instant('errors.' + err.message));
              reject();
            });
      }));
    }
    // Risolvo le promesse coi dati recuperati
    return Promise.all(promises);
  }

  getDayOfDate(day) {
    return day && day.dayDate && moment(day.dayDate).format('DD');
  }

  getMonthOfDate(day) {
    return day && day.dayDate && moment(day.dayDate).format('MMM');
  }

  getYearOfDate(day) {
    return day && day.dayDate && moment(day.dayDate).format('YYYY');
  }

  getEditionLocation(edition) {
    if (edition.days && edition.days.length) {
      let courseDay = edition.days[0];
      return locationNameForCard(courseDay.location);
    }
    return '';
  }

  isCompetencesSectionVisible() {
    if (this.itemDetails && this.competences && this.competences.length) {
      return true;
    }

    return false;
  }

  isLinksSectionVisible() {
    if (this.itemDetails && this.linkUrls && this.linkUrls.length) {
      return true;
    }

    return false;
  }

  isDescriptionSectionVisible() {
    if (this.itemDetails && !this.isSyllabusCourse &&
      (this.itemDetails.description || this.isItemInfoPanelVisible())) {
      return true;
    }

    return false;
  }

  isSyllabusSectionVisible() {
    if (this.itemDetails && this.isSyllabusCourse && (this.showSyllabusBodyContent() || this.isItemInfoPanelVisible())) {
      return true;
    }

    return false;
  }

  isExternalCredentialsVisible() {
    if (this.itemDetails && this.itemDetails.itemTakers && this.itemDetails.itemTakers.length && this.externalCredentials.externalLogin && this.externalCredentials.externalPsw) {
      return true;
    }
    return false;
  }

  // Costruisce le tab
  loadTabs(autoselect?: boolean) {
    if (this.tabs && this.tabs.length) {
      this.tabs.length = 0;
    } else {
      this.tabs = [];
    };
    if (this.isSyllabusCourse) {
      if (this.isSyllabusSectionVisible()) {
        this.tabs.push({ id: 'syllabus', name: this.translate.instant('generic.SYLLABUS_FIX') });
      }

      if (this.isExternalCredentialsVisible()) {
        this.tabs.push({ id: 'externalCredentials', name: this.translate.instant('generic.EXTERNAL_CREDENTIALS') });
      }

      if (this.isCourseMaterialsSectionVisible()) {
        this.tabs.push({ id: 'initiativeMaterials', name: this.translate.instant('generic.MATERIALS') });
      }

      if (!this.itemLinkedToCourse) {
        if (!this.isAssessment && this.isSyllabusCourse && this.editions && this.editions.length && this.itemDetails && (this.itemDetails.itemTakers && this.itemDetails.itemTakers.length)) {
          this.tabs.push({ id: 'editionsList', name: !this.isAssessment ? this.translate.instant('generic.EDITIONS') : this.translate.instant('generic.SESSIONS') });
        }
        if (!this.isAssessment && this.isSyllabusCourse && this.teachersTutorsList && this.teachersTutorsList.length) {
          this.tabs.push({ id: 'teacherTutor', name: this.translate.instant('generic.TEACHER_TUTOR') });
        }
        if (this.isSessionSectionVisible()) {
          this.tabs.push({ id: 'editionsSurvey', name: this.translate.instant('generic.SURVEYS') });
        }
        if (this.isCertificationsOfEditionsSectionVisible()) {
          this.tabs.push({ id: 'certifications', name: this.translate.instant('generic.CERTIFICATIONS') });
        }
      } else {
        if (this.isCompetencesSectionVisible()) {
          this.tabs.push({ id: 'competences', name: this.translate.instant('generic.TAXONOMY') });
        }
        if (this.isLinksSectionVisible()) {
          this.tabs.push({ id: 'linkUrls', name: this.translate.instant('card.skills.LINKS') });
        }
        if (this.showAttachmentsTabContent()) {
          this.tabs.push({ id: 'attachments', name: this.translate.instant('generic.ATTACHMENTS') });
        }
        if (this.areProjectChildsVisible()) {
          this.tabs.push({ id: 'sectionsOfItem', name: this.translate.instant('generic.MODULES') });
        }
        if (this.areLearningPlanChildsVisible()) {
          this.tabs.push({ id: 'itemsOfLP', name: this.translate.instant('generic.MODULES') });
        }
        if (this.isCertificationSectionVisible()) {
          this.tabs.push({ id: 'certification', name: this.translate.instant('generic.CERTIFICATION') });
        }
      }
    } else {
      // Si tratta di un oggetto dell Library
      if (this.isDescriptionSectionVisible()) {
        this.tabs.push({ id: 'description', name: this.translate.instant('generic.DESCRIPTION') });
      }
      if (this.isCompetencesSectionVisible()) {
        this.tabs.push({ id: 'competences', name: this.translate.instant('generic.TAXONOMY') });
      }
      if (this.isLinksSectionVisible()) {
        this.tabs.push({ id: 'linkUrls', name: this.translate.instant('card.skills.LINKS') });
      }
      if (this.showAttachmentsTabContent()) {
        this.tabs.push({ id: 'attachments', name: this.translate.instant('generic.ATTACHMENTS') });
      }
      if (this.areLearningPlanChildsVisible()) {
        this.tabs.push({ id: 'itemsOfLP', name: this.translate.instant('generic.MODULES') });
      }
      if (this.areProjectChildsVisible()) {
        this.tabs.push({ id: 'sectionsOfItem', name: this.translate.instant('generic.MODULES') });
      }
      if (this.isCertificationSectionVisible()) {
        this.tabs.push({ id: 'certification', name: this.translate.instant('generic.CERTIFICATION') });
      }
    };

    if (this.itemDetails && this.itemDetails.competences && this.itemDetails.competences.earnableCompetences && this.itemDetails.competences.earnableCompetences.length) {
      this.tabs.push({ id: 'acquisibleCompetences', name: this.translate.instant('generic.ACQUISIBLE_COMPETENCES_CLAIMS') });
    }

    if (this.itemDetails && this.itemDetails.competences && this.itemDetails.competences.requiredCompetences && this.itemDetails.competences.requiredCompetences.length) {
      this.tabs.push({ id: 'requiredCompetences', name: this.translate.instant('generic.REQUIRED_COMPETENCES_CLAIMS') });
    }

    if (autoselect) {
      if (this.isSyllabusCourse) {
        this.selectTab('syllabus', true);
      } else {
        this.selectTab('description', true);
      }
    }

    // Se presenti, aggiungo la tab delle persone che mi hanno suggerito l'oggetto
    if (this.hasPrompter) {
      this.tabs.push({ id: 'prompterList', name: this.translate.instant('generic.PROMPTER_LIST_BY') });
    }

    this.isLoadingTabs = false;
  }

  // Gestisce il click sul download del certificato
  onDownloadCertificate() {
    let itemRef = this.isOnlineCourse && this.itemLinkedToCourse && this.itemLinkedToCourse.itemId ? this.itemLinkedToCourse : this.itemDetails;
    if (!this.isCertificationObtained()) {
      if (itemRef.isLearningPlan) {
        // Scrollo fino agli item aggiunti
        this.scrollIntoPage('itemsOfLP');
      } else if (itemRef.isProject) {
        // Scrollo fino agli item aggiunti
        this.scrollIntoPage('sectionsOfItem');
      } else if (itemRef.isBlendedProject) {
        // Scrollo fino agli item aggiunti
        this.scrollIntoPage('sectionsOfItem');
      }
    } else {
      this.downloadCertification();
    }
  }

  // Verifica se il certificato è stato ottenuto
  isCertificationObtained() {
    if (!this.adminMode &&
      (this.itemLinkedToCourse && this.hasTheUserTheCertificateAssigned &&
        (this.itemLinkedToCourse.isCertified || (this.itemLinkedToCourse.isConsumed && !this.isThereSurveyToStart(this.itemLinkedToCourse) && !this.isThereSurveyToContinue(this.itemLinkedToCourse))))) {
      return true;
    }

    return false;
  }

  // Verifica se mostrare la sezione contenente gli item aggiunti al learning plan
  areLearningPlanChildsVisible() {
    let itemRef = this.isOnlineCourse && this.itemLinkedToCourse && this.itemLinkedToCourse.itemId ? this.itemLinkedToCourse : this.itemDetails;
    if (itemRef && itemRef.isLearningPlan && itemRef.itemChilds && itemRef.itemChilds.length) {
      return true;
    }
    return false;
  }

  // Verifica se mostrare la sezione contenente gli item aggiunti al progetto
  areProjectChildsVisible() {
    let itemRef = this.isOnlineCourse && this.itemLinkedToCourse && this.itemLinkedToCourse.itemId ? this.itemLinkedToCourse : this.itemDetails;
    if (itemRef && (itemRef.isProject || itemRef.isBlendedProject) && itemRef.itemChilds && itemRef.itemChilds.length) {
      return true;
    }
    return false;
  }

  // Verifica se mostrare la sezione contenente l'attestato
  isCertificationSectionVisible() {
    if (this.itemLinkedToCourse && this.itemDetails && this.itemDetails.isCourseCertificationEnabled && ((this.itemLinkedToCourse.isConsumed && this.hasTheUserTheCertificateAssigned) || (!this.itemLinkedToCourse.isConsumed && !this.hasTheUserTheCertificateAssigned))) {
      return true;
    }
    return false;
  }

  // Verifica se mostrare la sezione contenente gli attestati delle edizioni
  isCertificationsOfEditionsSectionVisible() {
    if (!this.isOnlineCourse && this.hasTheUserTheCertificateAssigned && this.itemDetails && this.itemDetails.isCourseCertificationEnabled && this.certifiedEditions && this.certifiedEditions.length && this.isSyllabusCourse) {
      return true;
    }
    return false;
  }

  loadLinkedItems() {
    this.isGettingLinkedItems = true;
    // Carico gli item collegati
    let source: any = this.isSyllabusCourse ?
      this.itemService.getSuggestedCatalogCourses({
        withCompletionPercentage: true,
        saveItemAttributes: true,
        saveItemTypeLabel: true,
        saveCardTypeLabel: true,
        saveItemIconType: true,
        saveItemHourValue: true,
        suggestedItems: true,
        numRecords: 20,
      },
        this.itemDetails.itemId)
      :
      this.itemService.searchAllAvailableItems({
        withCompletionPercentage: true,
        saveItemAttributes: true,
        saveItemTypeLabel: true,
        saveCardTypeLabel: true,
        saveItemIconType: true,
        saveItemHourValue: true,
        suggestedItems: true,
        numRecords: 20,
        item: this.itemDetails,
        itemId: this.itemDetails.itemId
      }, this.translate);

    source
      .subscribe(
        (items: any) => {
          if (!this.isSyllabusCourse) {
            if (items && items.length) {
              for (let i = 0, itemsLength = items.length; i < itemsLength; i++) {
                let currentItem = items[i];
                currentItem.hourValueText = this.getHourValueText(currentItem.hourValue);
                if (currentItem.credits && currentItem.credits.length) {
                  CourseEditionUtil.formatCreditsForFeCards(currentItem);
                }
              }
            }
            this.linkedItems = items;
          } else {
            if (items && items.response && items.response.length) {
              for (let i = 0, itemsLength = items.response.length; i < itemsLength; i++) {
                let currentItem = items.response[i];
                if (currentItem.credits && currentItem.credits.length) {
                  CourseEditionUtil.formatCreditsForFeCards(currentItem);
                }
              }
            }
            this.linkedItems = items.response;
          }
          this.isGettingLinkedItems = false;
        }
      )
  }

  getEditionsByPlace() {
    //  return this.editionsByPlace || [];
    return this.editions || [];
  }

  getPlaceName(editionsByPlace) {
    return `${editionsByPlace.place} (${editionsByPlace.editions.length})`;
  }

  getAllDistinctEditions(): CourseEdition[] {
    return (this.editions || []).concat((this.editionsListWithPastEditions || [])).reduce((prev, curr) => {
      return prev.includes(curr) ? prev : prev.concat([curr]);
    }, []);
  }

  // Verifica se mancano i materiali nella tab selezionata
  public areMissingMaterialsInSelectedTab(): boolean {
    let areMissing: boolean = true;
    if (this.selectedMaterialTab) {
      if ((this.selectedMaterialTab === 'MATERIAL_PRE_WORK' && this.preworkMaterialsCounter)
        || (this.selectedMaterialTab === 'MATERIAL_CLASSROOM' && this.classroomMaterialsCounter)
        || (this.selectedMaterialTab === 'MATERIAL_POST_WORK' && this.postworkMaterialsCounter)
      ) {
        areMissing = false;
      }
    }
    return areMissing;
  }

  // Verifica se mostrare il blocco contenente l'informazione di materiali non trovait
  public showNoMaterialFoundLabel(): boolean {
    let isVisible = false;

    // I materiali possono esserci, ma non nella tab correntemente selezionata
    if (!this.isFetchingMaterialsFiles && (
      (!this.materials || !this.materials.length) || this.areMissingMaterialsInSelectedTab()
    )) {
      isVisible = true;
    }
    return isVisible;
  }

  loadAllCountMaterials() {
    let promises = [];
    const materialTypes = [ItemAttributeMaterialTypes.MATERIAL_CLASSROOM, ItemAttributeMaterialTypes.MATERIAL_POST_WORK];

    for (let l = 0, typesLength = materialTypes.length; l < typesLength; l++) {
      let currentMaterialType = materialTypes[l];

      promises.push(new Promise((resolve: Function, reject: Function) => {
        let classroom = currentMaterialType === ItemAttributeMaterialTypes.MATERIAL_CLASSROOM;
        let postwork = currentMaterialType === ItemAttributeMaterialTypes.MATERIAL_POST_WORK;
        let itemIds = [this.itemDetails.itemId, ...this.getAllDistinctEditions().filter((edition: CourseEdition) => {
          if (classroom) {
            return moment().utc().isSameOrAfter(moment(edition.firstDayDate).subtract(MATERIAL_VISIBILITY_DELTA_HOURS, 'hours'));
          } else if (postwork) {
            return moment().utc().isSameOrAfter(moment(edition.lastDayDate).subtract(MATERIAL_VISIBILITY_DELTA_HOURS, 'hours'));
          }
          return false;
        }).map((edition: any) => edition.courseDateId)];

        this.takerService.countUserMaterialsForParent(this.loggedUser.user.userId, itemIds, [currentMaterialType])
          .subscribe(data => {
            if (data.error) {
              // Mostro il toaster di errore
              this.toastr.error(this.translate.instant('errors.' + data.error));
              reject();
            } else {
              if (classroom) {
                this.classroomMaterialsCounter = classroom ? data.response : this.classroomMaterialsCounter;
              } else {
                this.postworkMaterialsCounter = postwork ? data.response : this.postworkMaterialsCounter;
              }
              resolve();
            }
          })
      }));
    }
    return Promise.all(promises);
  }

  loadUserMaterialList(materialType: string, pageToLoad?: number, loadTabs?: boolean) {
    this.currentMaterialListPage = !!pageToLoad ? pageToLoad : 1;
    this.selectedMaterialTab = materialType;
    this.isFetchingMaterialsFiles = true;
    let prework = materialType === ItemAttributeMaterialTypes.MATERIAL_PRE_WORK;
    let classroom = materialType === ItemAttributeMaterialTypes.MATERIAL_CLASSROOM;
    let postwork = materialType === ItemAttributeMaterialTypes.MATERIAL_POST_WORK;

    let itemIds = [this.itemDetails.itemId, ...this.getAllDistinctEditions().map((edition: any) => edition.courseDateId)];

    if (!this.materials) {
      this.materials = [];
    } else {
      this.materials.length = 0;
    }

    this.takerService.countUserMaterialsForParent(this.loggedUser.user.userId, itemIds, [materialType]).pipe(
      switchMap((senecaResponse: SenecaResponse<number>) => {
        if (senecaResponse && senecaResponse.error) {
          return throwError(senecaResponse.error)
        }
        this.currentMaterialListCounter = senecaResponse.response;
        this.preworkMaterialsCounter = prework ? this.currentMaterialListCounter : this.preworkMaterialsCounter;
        this.classroomMaterialsCounter = classroom ? this.currentMaterialListCounter : this.classroomMaterialsCounter;
        this.postworkMaterialsCounter = postwork ? this.currentMaterialListCounter : this.postworkMaterialsCounter;

        if (senecaResponse.response) {
          // Calcolo la paginazione
          let fromRecord = 0;
          if (this.currentMaterialListPage) {
            fromRecord = (this.currentMaterialListPage - 1) * this.materialListNumRecords;
          } else {
            fromRecord = 0;
          }
          return this.takerService.listUserMaterialsForParent(this.loggedUser.user.userId, fromRecord, this.materialListNumRecords, itemIds, [materialType]);
        }
        return of(new SenecaResponse(null, null));
      })
    ).subscribe((senecaResponse: SenecaResponse<ItemFrontEndWrapper[]>) => {
      if (senecaResponse && senecaResponse.error) {
        this.toastr.error(this.translate.instant('errors.' + senecaResponse.error));
      }
      // Recupero le etichette per la tipologia
      this.itemService.setItemTypologyTranslations(senecaResponse.response);
      this.materials = senecaResponse.response && senecaResponse.response.map((itemWrap) => {
        return {
          ...itemWrap,
          materialType: ItemUtil.getAttributeValue(itemWrap.item, ItemAttributeTypes.MATERIAL_TYPE),
          objectType: ItemUtil.getAttributeValue(itemWrap.item, ItemAttributeTypes.OBJECT_TYPE)
        }
      }) || [];
      if (loadTabs) {
        this.loadTabs(true);
      }
      this.isFetchingMaterialsFiles = false;
    }, (err) => {
      if (loadTabs) {
        this.loadTabs(true);
      }
      this.isFetchingMaterialsFiles = false;
      this.toastr.error(this.translate.instant('errors.' + err));
    });
  }

  isSessionSectionVisible() {
    if (this.isSyllabusCourse && this.editionsSurvey && this.editionsSurvey.length) {
      return true;
    }
    return false;
  }

  toDoCoursesCardsMouseUp(e) {
    this.toDoCoursesCardsClicked = false;
  }

  toDoCoursesCardsMouseDown(e: MouseEvent) {
    this.toDoCoursesCardsClicked = true;
    const courseCardWrapperElement = document.getElementById("course-cards-wrapper");
    if (courseCardWrapperElement) {
      this.startX = e.pageX - courseCardWrapperElement.offsetLeft;
      this.scrollLeft = courseCardWrapperElement.scrollLeft;
      this.clickY = e.x;
    }
  }

  toDoCoursesCardsMouseMove(e) {
    if (!this.toDoCoursesCardsClicked || this.isGettingLinkedItems) return;
    e.preventDefault();
    const courseCardWrapperElement = document.getElementById("course-cards-wrapper");
    if (courseCardWrapperElement) {
      const x = e.pageX - courseCardWrapperElement.offsetLeft;
      const walk = (x - this.startX) * 1.5;
      courseCardWrapperElement.scrollLeft = this.scrollLeft - walk;
    }
  }

  // Autoscroll delle card a destra
  scrollToDoCourses(direction: string) {
    const scrollDistance = 280;
    // Recupero l'elemento in cui c'è lo scroll
    const courseCardWrapperElement = document.getElementById("course-cards-wrapper");
    if (courseCardWrapperElement) {
      const sl = courseCardWrapperElement.scrollLeft,
        cw = courseCardWrapperElement.scrollWidth;
      if (direction === 'right') {
        if ((sl + scrollDistance) >= cw) {
          courseCardWrapperElement.scrollTo({
            left: cw,
            behavior: 'smooth'
          });
          // courseCardWrapperElement.scrollTo(cw, 0);
        } else {
          courseCardWrapperElement.scrollTo({
            left: sl + scrollDistance,
            behavior: 'smooth'
          });
          //   courseCardWrapperElement.scrollTo((sl + scrollDistance), 0);
        }
      } else {
        if ((sl - scrollDistance) <= 0) {
          // courseCardWrapperElement.scrollTo(0, 0);
          courseCardWrapperElement.scrollTo({
            left: 0,
            behavior: 'smooth'
          });
        } else {
          courseCardWrapperElement.scrollTo({
            left: sl - scrollDistance,
            behavior: 'smooth'
          });
          //    courseCardWrapperElement.scrollTo((sl - scrollDistance), 0);
        }
      }
    }
  }

  isMaterialToOpen(materialItem: any) {
    return objectTypesToOpen.includes(materialItem && materialItem.typology);
  }

  isMaterialToDownload(materialItem: any) {
    return objectTypesToDownload.includes(materialItem && materialItem.typology);
  }

  isMaterialToDownloadDAM(materialItem: any) {
    return objectTypesToDownload.includes(materialItem && materialItem.subtype);
  }

  toggleAzurePlayerModal(materialItem?) {
    if (materialItem) {
      this.selectedMaterialForModal = materialItem;
      this.selectedMaterialForModal.azureImage = this.selectedMaterialForModal.mimeType && this.selectedMaterialForModal.mimeType.includes("image") ? this.selectedMaterialForModal.damObjectUrl : null;
      this.modalService.open('azurePlayerModal');
      document.getElementById("azurePlayerModal").focus();
    } else {
      this.modalService.close('azurePlayerModal');
      this.selectedMaterialForModal = null;
    }
  }

  downloadMultimediaItem(materialItem) {
    if (materialItem) {
      let externalObjectIdAttr = ItemUtil.getAttributeByKey(materialItem, ItemAttributeTypes.EXTERNAL_OBJECT_ID);
      let externalObjectId = externalObjectIdAttr && externalObjectIdAttr.attributeValue;
      if (externalObjectId) {
        let getAttachmentUrlPromise = ItemUtil.getAttachmentUrl(externalObjectId, this.adminMode, materialItem, null, false, false, this.itemService, this.toastr);
        getAttachmentUrlPromise.then((response: string) => {
          if (response) {
            // Salvo l'url
            this.attachmentUrl = response;
            const regex = /^.*\.(png|jpg|jpeg|pptx|ppsx|ppt|doc|docx|pdf|xls|xlsx|csv|epub)$/gm;
            const ext = this.attachmentUrl.slice(this.attachmentUrl.lastIndexOf('.'), this.attachmentUrl.indexOf('?')).toLowerCase();
            const extFinal = ext.match(regex) ? ext : ''

            // E concludo recuperando il file
            let downloadUrl = ItemUtil.getExternalResourceName(this.attachmentUrl, (materialItem.title || materialItem.name) + extFinal, this.applicationData);
            this.isDownloadingExternalResource = false;
            setTimeout(() => {
              window.location.assign(downloadUrl);
            }, 500)
          }
        })
      }
    }
  }

  downloadMultimediaItemDAM(materialItem) {
    if (materialItem) {
      this.isDownloadingFile = true;

      const childObject = materialItem.itemChilds && materialItem.itemChilds[0] && materialItem.itemChilds[0].childObject;
      let externalObjectIdAttr = ItemUtil.getAttributeByKey(childObject, ItemAttributeTypes.EXTERNAL_OBJECT_ID);

      if (childObject) {
        externalObjectIdAttr = ItemUtil.getAttributeByKey(childObject, ItemAttributeTypes.EXTERNAL_OBJECT_ID);
      }

      let externalObjectId = externalObjectIdAttr && externalObjectIdAttr.attributeValue;

      if (externalObjectId) {
        let getAttachmentUrlPromise = ItemUtil.getAttachmentUrl(externalObjectId, this.adminMode, materialItem, (materialItem && materialItem.itemId), true, true, this.itemService, this.toastr);
        getAttachmentUrlPromise.then((response: string) => {
          if (response) {
            // Salvo l'url
            this.attachmentUrl = response;
            const regex = /^.*\.(png|jpg|jpeg|pptx|ppsx|ppt|doc|docx|pdf|xls|xlsx|csv|epub)$/gm;
            const ext = this.attachmentUrl.slice(this.attachmentUrl.lastIndexOf('.'), this.attachmentUrl.indexOf('?')).toLowerCase();
            const extFinal = ext.match(regex) ? ext : ''
            // E concludo recuperando il file
            let downloadUrl = ItemUtil.getExternalResourceName(this.attachmentUrl, (materialItem.title || materialItem.name) + extFinal, this.applicationData);
            this.isDownloadingExternalResource = false;
            setTimeout(() => {
              window.location.assign(downloadUrl);
            }, 500)

          }
        })
      } else {
        // ExternalObjectId non trovato, quindi non è stato possibile continuare con il download
        this.toastr.error(this.translate.instant('errors.CANNOT_LOAD_ITEM'));
      }
      this.getItemDetails();
    }
  }

  // Todo
  openCourseEditionDetails(materialItem, editionId) {
    return;
  }

  // Gestisce i breadcrumb
  manageBreadcrumbs() {
    this.isGettingBreadcrumbs = true;
    let baseUrl = null;

    if (this.isManagerPreview) {
      baseUrl = '/manager';
    } else if (this.isItemsAdminPreview) {
      baseUrl = '/itemsAdmin/';
    } else if (this.isInitiativesAdminPreview || this.isInitiativesAdminFromHomePreview) {
      baseUrl = '/initiatives/';
    } else {
      if (this.isInLibrary) {
        baseUrl = '/takers/library/';
      } else if (this.isInTrainingBooklet) {
        baseUrl = '/takers/trainingBooklet/';
      } else if (this.isInCatalog) {
        baseUrl = '/takers/catalog/';
      } else if (this.isInTrainingBooklet) {
        baseUrl = '/takers/trainingBooklet/';
      } else {
        baseUrl = '/takers/';
      }
    }
    if (this.lpId) {
      let serviceToUse = null;
      // Chiamo il servizio per recuperare il nome del Learning Plan
      this.itemService.getItemTitle(this.lpId)
        .subscribe(learningPlanDetails => {
          if (learningPlanDetails.error) {
            this.isGettingBreadcrumbs = false;
            this.toastr.error(this.translate.instant('errors.' + learningPlanDetails.error));
          } else if (learningPlanDetails && learningPlanDetails.response) {
            // Learning plan
            let learningPlanBreadcrumb = {
              label: learningPlanDetails.response,
              redirectPage: baseUrl + 'itemDetails/' + this.lpId
            };

            // Oggetto ad esso assocciato
            let itemBreadcrumb = {
              label: (this.syllabusTitle || this.itemDetails.name),
              redirectPage: baseUrl + 'itemDetails/' + this.itemDetails.itemId,
              isActive: true
            };

            // e li aggiungo al contenitore dei Breadcrumb
            this.breadcrumbs.push(learningPlanBreadcrumb);
            this.breadcrumbs.push(itemBreadcrumb);
          }
          this.isGettingBreadcrumbs = false;
          this.getCourseEndDateOfWrappedContainer(this.lpId);
        });
    } else if (this.projectId && this.sectionId && this.itemId) {
      // Se sono giunto qui, significa che sto per aprire un oggetto aggiunto in una sezione di un progetto
      this.getBreadcrumbsOfItemInSection();
    } else if (this.editingProjectId && this.editingSectionId && this.itemId) {
      // Oggetto raggiunto tramite la pagina di editing di una sezione
      this.getBreadcrumbsOfItemInSection(true, false);
    } else if (this.editingProjectId && this.editingSectionId && this.itemToAddId) {
      // Oggetto raggiunto tramite la pagina di ricerca di un elemento da aggiungere a una sezione in editing
      this.getBreadcrumbsOfItemInSection(true, true);
    } else if (this.editingLearningPlanId && this.itemToAddId) {
      // Oggetto raggiunto tramite la pagina di ricerca di un elemento da aggiungere a un Learning Plan in editing
      this.itemService.getItemTitle(this.editingLearningPlanId)
        .subscribe(learningPlanDetails => {
          if (learningPlanDetails.error) {
            this.toastr.error(this.translate.instant('errors.' + learningPlanDetails.error));
          } else if (learningPlanDetails && learningPlanDetails.response) {
            // Learning Plan
            let editLearningPlanBreadcrumb: any = null;
            if (this.adminMode) {
              let componentRefUrl = null;
              if (this.isItemsAdminPreview) {
                componentRefUrl = '/itemsAdmin/';
              } else if (this.isInitiativesAdminPreview || this.isInitiativesAdminFromHomePreview) {
                componentRefUrl = '/initiatives/';
              }
              // Edit Learning plan lato amministratore
              editLearningPlanBreadcrumb = {
                label: learningPlanDetails.response,
                redirectPage: componentRefUrl + "editLearningPlanAdmin/" + this.editingLearningPlanId
              };
            } else {
              // Edit Learning plan lato amministratore
              editLearningPlanBreadcrumb = {
                label: learningPlanDetails.response,
                redirectPage: "/itemsAdmin/editLearningPlan/" + this.editingLearningPlanId
              };
            }

            // Breadcrumb della pagina di ricerca
            let searchingPage = {
              label: this.translate.instant("generic.SEARCH"),
              redirectPage: "/itemsAdmin/addItemToCurrentLearningPlan/" + this.editingLearningPlanId + "/" + this.isDraft
            }

            // Oggetto ad esso assocciato
            let itemBreadcrumb = {
              label: this.syllabusTitle || this.itemDetails.name,
              redirectPage: "/itemsAdmin/itemDetailToAddToLearningPlan/" + this.editingLearningPlanId + "/" + this.itemToAddId + "/",
              isActive: true
            };

            // e li aggiungo al contenitore dei Breadcrumb
            this.breadcrumbs.push(editLearningPlanBreadcrumb);
            this.breadcrumbs.push(searchingPage);
            this.breadcrumbs.push(itemBreadcrumb);
          }
          this.isGettingBreadcrumbs = false;
        })
    } else if (this.editingLpId && this.itemId) {
      // Se sono giunto qui, significa che arrivo dalla pagina di edit di un Learning Plan. Dunque chiamo il servizio per recuperare il nome del Learning Plan
      this.itemService.getItemTitle(this.editingLpId)
        .subscribe(learningPlanDetails => {
          if (learningPlanDetails.error) {
            this.toastr.error(this.translate.instant('errors.' + learningPlanDetails.error));
          } else if (learningPlanDetails && learningPlanDetails.response) {
            // Learning Plan
            let editLearningPlanBreadcrumb: any = null;
            if (this.adminMode) {
              let componentRefUrl = null;
              if (this.isItemsAdminPreview) {
                componentRefUrl = '/itemsAdmin/';
              } else if (this.isInitiativesAdminPreview || this.isInitiativesAdminFromHomePreview) {
                componentRefUrl = '/initiatives/';
              }
              // Edit Learning plan lato amministratore
              editLearningPlanBreadcrumb = {
                label: learningPlanDetails.response,
                redirectPage: "/itemsAdmin/editLearningPlanAdmin/" + this.editingLpId
              };
            } else {
              // Edit Learning plan lato amministratore
              editLearningPlanBreadcrumb = {
                label: learningPlanDetails.response,
                redirectPage: "/itemsAdmin/editLearningPlan/" + this.editingLpId
              };
            }

            // Oggetto ad esso assocciato
            let itemBreadcrumb = {
              label: this.syllabusTitle || this.itemDetails.name,
              redirectPage: "/itemsAdmin/itemDetailsEditLp/" + this.editingLpId + "/" + this.itemDetails.itemId,
              isActive: true
            };

            // e li aggiungo al contenitore dei Breadcrumb
            this.breadcrumbs.push(editLearningPlanBreadcrumb);
            this.breadcrumbs.push(itemBreadcrumb);
          }
          this.isGettingBreadcrumbs = false;
        })
    } else {
      // Preparo il breadcrumb dell'oggetto singolo, non associato a nulla
      let itemBreadcrumb = {
        label: (this.syllabusTitle || (this.itemDetails && this.itemDetails.name)) || '',
        redirectPage: baseUrl + '/itemDetails/' + this.itemDetails.itemId,
        link: "app.libraryApp.itemDetail({ itemId: '" + this.itemDetails.itemId + "'})",
        isActive: true
      };

      // e lo aggiungo al contenitore dei breadcrumb
      this.breadcrumbs.push(itemBreadcrumb);
      this.isGettingBreadcrumbs = false;
    }
  }

  // Recupero i dati dell'item
  getItemDetails() {
    this.isGettingItemDetails = true;
    let itemId = this.itemId || this.itemToAddId;
    const attributeTypesToRetrieve = [
      ItemAttributeTypes.COMPETENCE_LEVEL_REQUIRED,
      ItemAttributeTypes.COMPETENCE_AWARD,
      ItemAttributeTypes.AIMS,
      ItemAttributeTypes.AGENDA,
      ItemAttributeTypes.PREREQS,
      ItemAttributeTypes.APPROACH,
      ItemAttributeTypes.PARTNER,
      ItemAttributeTypes.TECH_COMPETENCES,
      ItemAttributeTypes.SOFT_COMPETENCES,
      ItemAttributeTypes.OBJECT_TYPE,
      ItemAttributeTypes.OBJECT_TYPE_SPECIALIZATION,
      ItemAttributeTypes.DURATION,
      ItemAttributeTypes.VALUE,
      ItemAttributeTypes.WISHLIST_ENABLED,
      ItemAttributeTypes.EXTERNAL_OBJECT_ID,
      ItemAttributeTypes.RENTABLE,
      ItemAttributeTypes.TUTOR_CONTACT,
      ItemAttributeTypes.TEACHER_CONTACT,
      ItemAttributeTypes.SUPPORT_CONTACT,
      ItemAttributeTypes.ENABLE_COURSE_CERTIFICATION,
      ItemAttributeTypes.LINK,
      ItemAttributeTypes.AVAILABLE_IN_CATALOG,
      ItemAttributeTypes.PRE_REGISTRATION_ENABLED,
      ItemAttributeTypes.PRE_REGISTRATION_START_DATE,
      ItemAttributeTypes.PRE_REGISTRATION_END_DATE,
      ItemAttributeTypes.PRE_REGISTRATION_TYPE,
      ItemAttributeTypes.DAM_SUBTITLE,
      ItemAttributeTypes.COURSE_CATEGORY,
      ItemAttributeTypes.FUNCTIONAL_AREAS,
      ItemAttributeTypes.STREAMS,
      ItemAttributeTypes.PLAYER_PREVENT_USER_CONTROL
    ];

    this.getItemDetails$ = this.itemService.getConsumableItemByIdForUser(this.translate, itemId, true, 4, attributeTypesToRetrieve, !this.adminMode, (this.isItemsAdminPreview || this.isInitiativesAdminPreview || this.isInitiativesAdminFromHomePreview || this.isManagerPreview))
      .pipe(
        map((itemReturned) => {
          this.itemDetails = itemReturned;
          // Controllo se devo limitare i controlli nel player
          this.isTraditionalPlayer = itemReturned.itemAttributes?.find(el => el.attributeType == ItemAttributeTypes.PLAYER_PREVENT_USER_CONTROL) ? true : false;

          this.itemDetails.cardCover = sanitazeUrl(this.itemDetails.cardCover);
          // se può preiscriversi ma la data di fine preiscrizioni è scaduta e non ci sono edizioni o linkedItem
          let preEndDate = this.itemDetails?.itemAttributes?.find((attr: any) => attr.attributeType == ItemAttributeTypes.PRE_REGISTRATION_END_DATE);
          if (preEndDate && moment().isAfter(moment(preEndDate.attributeValue))) {
            this.itemDetails.presubscriptionEndedNoEdition = true;
          } else if (preEndDate && moment().isSameOrBefore(moment(preEndDate.attributeValue))) {
            // se può preiscriversi e non ci sono edizioni o linkedItem
            this.itemDetails.presubscriptionEndedNoEdition = true;
          }

          if (this.itemDetails && this.itemDetails.itemTakers && this.itemDetails.itemTakers.length) {
            let externalLoginAttr = this.itemDetails.itemTakers[0].takerAttributes?.find(atype => atype.attributeType == ItemTakerAttributeTypes.EXTERNAL_LOGIN);
            let externalPswAttr = this.itemDetails.itemTakers[0].takerAttributes?.find(atype => atype.attributeType == ItemTakerAttributeTypes.EXTERNAL_PASSWORD);
            this.externalCredentials = { externalLogin: externalLoginAttr && externalLoginAttr.attributeValue, externalPsw: externalPswAttr && externalPswAttr.attributeValue }
          }

          if (this.itemDetails?.extendedItem?.itemAttributes?.length) {
            this.searchableTags = this.itemDetails.extendedItem.itemAttributes.filter(item =>
              this.searchableTagTypes.includes(item.attributeType))
          } else if (this.itemDetails?.itemAttributes?.length) {
            this.searchableTags = this.itemDetails.itemAttributes.filter(item =>
              this.searchableTagTypes.includes(item.attributeType))
          }

          this.alreadyAskedForCatalog = this.itemDetails.catalogItemRequestDate;

          this.canRequestFromCatalog = this.itemDetails.isInCatalog;

          this.editionActionDisabledForOnlineCourse = [ItemTypes.COURSE_ONLINE_STAGE, ItemTypes.EVENT_ONLINE_STAGE].includes(this.itemDetails.itemType);


          this.isActivePreiscrition = this.itemDetails.isActivePreiscrition;
          this.startDatePreiscrition = this.itemDetails.startDatePreiscrition;
          this.endDatePreiscrition = this.itemDetails.endDatePreiscrition;
          this.onWhatPreiscrition = this.itemDetails.onWhatPreiscrition;
          let noTakersOnInitiative = false;
          let noTakersOnInitiativehasStatusPreregistered = false;

          if (this.itemDetails && this.itemDetails.itemTakers && this.itemDetails.itemTakers.length) {
            let myTakers = this.itemDetails.itemTakers.filter((x: any) => {
              return x.allowedUserId == this.loggedUser.user.userId && x.masterItemId == this.itemId && !x.editionId;
            })
            if (myTakers && myTakers.length) {
              for (let i = 0; i < myTakers.length; i++) {
                let myTaker = myTakers[i];
                if (myTaker.takerEnrolls && myTaker.takerEnrolls.length) {
                  if (myTaker.takerEnrolls[0].takerEnrollStatuses && myTaker.takerEnrolls[0].takerEnrollStatuses.length) {
                    if (myTaker.takerEnrolls[0].takerEnrollStatuses[0].statusType == "USER_STATUS_NEGATED") {
                      this.hasStatusNegatedOnInitiative = true;
                    } else
                      if (myTaker.takerEnrolls[0].takerEnrollStatuses[0].statusType == "USER_STATUS_INVITED") {
                        this.hasStatusInvitedOnInitiative = true;
                      } else if (myTaker.takerEnrolls[0].takerEnrollStatuses[0].statusType == "USER_STATUS_APPROVED") {
                        this.hasStatusApprovedOnInitiative = true;
                      } else if (myTaker.takerEnrolls[0].takerEnrollStatuses[0].statusType == "USER_STATUS_CANCELLED") {
                        this.hasStatusCancelledOnInitiative = true;
                      }
                  }
                }
              }
            }
          }

          if (this.startDatePreiscrition && this.endDatePreiscrition) {
            let startDate = moment(this.startDatePreiscrition).utc();
            let endDate = moment(this.endDatePreiscrition).utc();
            let today = moment(new Date()).utc();
            if (today.isSameOrAfter(startDate) && today.isSameOrBefore(endDate)) {
              this.isOnRangePreiscrition = true;
            }
          } else {
            this.isOnRangePreiscrition = true;
          }

          if (this.isActivePreiscrition && this.onWhatPreiscrition == 'INITIATIVE_ONLY' ||
            this.onWhatPreiscrition == 'ALL') {
            if (this.itemDetails && this.itemDetails.itemTakers && this.itemDetails.itemTakers.length) {
              let myTakers = this.itemDetails.itemTakers.filter((x: any) => {
                return x.allowedUserId == this.loggedUser.user.userId && x.masterItemId == this.itemId && !x.editionId;
              })
              if (myTakers && myTakers.length) {
                for (let i = 0; i < myTakers.length; i++) {
                  let myTaker = myTakers[i];
                  if (myTaker.takerEnrolls && myTaker.takerEnrolls.length) {
                    if (myTaker.takerEnrolls[0].takerEnrollStatuses && myTaker.takerEnrolls[0].takerEnrollStatuses.length) {
                      if (myTaker.takerEnrolls[0].takerEnrollStatuses[0].statusType == "USER_STATUS_CANCELLED") {
                        noTakersOnInitiative = true;
                      } else if (myTaker.takerEnrolls[0].takerEnrollStatuses[0].statusType == "USER_STATUS_PREREGISTERED") {
                        noTakersOnInitiativehasStatusPreregistered = false;
                      } else {
                        noTakersOnInitiative = false;
                      }
                    } else {
                      noTakersOnInitiative = true;
                    }
                  } else {
                    noTakersOnInitiative = true;
                  }
                }
              } else {
                noTakersOnInitiative = true;
              }
            } else {
              noTakersOnInitiative = true;
            }
          }

          // if (noTakersOnInitiative && this.isOnRangePreiscrition && !noTakersOnInitiativehasStatusPreregistered) {
          //   this.canPreiscritionOnInitiative = true;
          // } else if (this.isOnRangePreiscrition) {
          //   this.canCancelPreiscritionOnInitiative = true;
          // } else {
          //   this.canPreiscritionOnInitiative = false;
          // }

          // Se l'oggetto è a catalogo e l'utente ha il taker significa che può fruirlo
          if (this.itemDetails && this.itemDetails.existsItemTakerVisibility && this.canRequestFromCatalog) {
            // this.canRequestFromCatalog = false;
            this.canRequestFromCatalog = true;
          }

          this.syllabusTitle = this.itemDetails && this.itemDetails.extendedItem && this.itemDetails.extendedItem.itemLangs && this.itemDetails.extendedItem.itemLangs.find((x: any) => {
            return x.langCode == this.applicationLang
          }).title;

          // Se non sono admin, imposto come lette le eventuali modifiche
          if (!this.adminMode && this.itemDetails && this.itemDetails.itemId) {
            this.markNotificationAsReadByReference$ = this.takerService.markNotificationAsReadByReference(this.itemDetails.itemId, ItemTypes.ITEM, CommonConstants.APPLICATION_CORPORATE_ACADEMY)
              .subscribe(data => {
                return;
              })
          };

          this.canPreiscritionOnInitiative = false;
          // Se non sono ancora iscritto e sono nel range utile per iscrivermi
          if (noTakersOnInitiative && this.isOnRangePreiscrition) {
            this.canPreiscritionOnInitiative = true;
          }

          this.isAtomicItem = ItemUtil.isAtomicItem(<any>this.itemDetails);
          // Aggiunto per gestire info e azioni della card secondo le nuove logiche di preiscrizione per banca
          this.isCurrentlyInvitedOrApprovedInInitiativeOrEdition = false;
          this.isCurrentlyInvitedOrApprovedInitiative = false;
          this.isCurrentlyInvitedOrApprovedEdition = {};
          // // nuova variabile
          // this.isCurrentlyInvitedOrApproved = false;
          this.isPublicTaker = false;
          // Gestione azioni modale per corsi già iscritti
          this.isCourseAlreadyTaken = false;
          let isUserRegistered = false;
          // controllo tutti i takerEnroll di ogni taker
          if (this.itemDetails.itemTakers && this.itemDetails.itemTakers.length) {
            this.itemDetails.itemTakers.forEach((taker: any) => {
              if (taker.takerEnrolls && taker.takerEnrolls.length) {

                taker.takerEnrolls.forEach((enroll: any) => {
                  if (taker.publicTaker) {
                    this.isPublicTaker = true;
                  }
                  let userInitiativeStatus: any = enroll.takerEnrollStatuses && enroll?.takerEnrollStatuses[0];
                  userInitiativeStatus = userInitiativeStatus && userInitiativeStatus.statusType || '';
                  if (userInitiativeStatus == ItemTakerEnrollStatusTypes.USER_STATUS_PRESENT || userInitiativeStatus == ItemTakerEnrollStatusTypes.USER_STATUS_ABSENT) {
                    this.isCourseAlreadyTaken = true;
                  }
                  isUserRegistered = userInitiativeStatus == ItemTakerEnrollStatusTypes.USER_STATUS_PREREGISTERED || userInitiativeStatus == ItemTakerEnrollStatusTypes.USER_STATUS_CONFIRMED;
                  let invalidStatus = [
                    ItemTakerEnrollStatusTypes.USER_STATUS_DELETED,
                    ItemTakerEnrollStatusTypes.USER_STATUS_NEGATED,
                    ItemTakerEnrollStatusTypes.USER_STATUS_PREREGISTERED
                  ];
                  if (invalidStatus.indexOf(userInitiativeStatus) < 0 && enroll.itemId == null) {
                    this.isCurrentlyInvitedOrApprovedInInitiativeOrEdition = true;
                    this.isCurrentlyInvitedOrApprovedInitiative = true;
                  }
                  if (invalidStatus.indexOf(userInitiativeStatus) < 0 && enroll.itemId != null) {
                    this.isCurrentlyInvitedOrApprovedInInitiativeOrEdition = true;
                    this.isCurrentlyInvitedOrApprovedEdition[enroll.itemId] = true;
                  }
                })

              }
            })
          }

          // se sono nel range per l'iscrizione e sono iscritto posso cancellare l'iscrizione
          if (this.isOnRangePreiscrition && isUserRegistered) {
            this.canCancelPreiscritionOnInitiative = true;
          }


          if (this.itemDetails.itemType && (this.itemDetails.itemType === ItemTypes.DAM_ITEM ||
            this.itemDetails.itemType === ItemTypes.DAM_ITEM_ENCLOSED)) {
            this.damPlayerVisible = true;
          };

          if (this.itemDetails.itemType && (this.itemDetails.itemType === ItemTypes.SCORM_FREE ||
            this.itemDetails.itemType === ItemTypes.SCORM_INVITE)) {
            this.scormPlayerVisibile = true;
          };

          if (this.itemDetails.existsItemTakerVisibility &&
            ((this.isAtomicItem &&
              (this.damPlayerVisible || this.scormPlayerVisibile))
              || this.itemDetails.isProject || this.itemDetails.isBlendedProject || this.itemDetails.isLearningPlan)
          ) {
            this.tryLibraryItemTakerEnroll$ = this.takerService.tryLibraryItemTakerEnroll(this.itemDetails.itemId)
              .subscribe(data => {
                if (data.error || !data.response) {
                  if (data.error != 'AVAILABLE_TAKERS_NOT_FOUND') {
                    this.toastr.error(this.translate.instant('errors.' + data.error));
                  }
                  this.isOpenDamBtnDisabled = true;
                } else if (data.response) {
                  this.isOpenDamBtnDisabled = false;
                }
              },
                (err) => {
                  this.isOpenDamBtnDisabled = true;
                });
          } else {
            if (!(this.isAtomicItem && (this.damPlayerVisible || this.scormPlayerVisibile))) {
              // this.singleItemWithoutStageDamOpenBtnDisabled = true;
              this.isOpenDamBtnDisabled = true;
            } else {
              this.isOpenDamBtnDisabled = false;
            }
          }

          // Categoria di formazione
          let attribute = this.itemDetails.itemAttributes?.find((attribute: any) => attribute.attributeType == ItemAttributeTypes.INITIATIVE_SCOPE);
          if (attribute?.attributeValue === 'EXTERNAL') {
            this.CanViewPresenceAbsence = true;
          }
          // per tutte le metodologie, tranne i questionari e corso online asincrono, sarà possibile settare la presenza/assenza
          // solo se la categoria di formazione è esterna
          if (this.itemDetails.itemType === ItemTypes.EXTERNAL_COURSE_CLASS_STAGE && this.CanViewPresenceAbsence) {
            this.isExternalCourseClass = true;
          }
          if (this.itemDetails.itemType === ItemTypes.EXTERNAL_ONLINE_STAGE && this.CanViewPresenceAbsence) {
            this.isExternalCourseOnline = true;
          }

          if (this.itemDetails.itemType === ItemTypes.COURSE_CLASS_STAGE && this.CanViewPresenceAbsence) {
            this.CanSetPresenceAbsence = true;
          }

          if (this.itemDetails.itemType === ItemTypes.BLENDED_STAGE && this.CanViewPresenceAbsence) {
            this.CanSetPresenceAbsence = true;
          }

          /*if (this.itemDetails.itemType === ItemTypes.COURSE_ONLINE_STAGE && this.CanViewPresenceAbsence) {
            this.CanSetPresenceAbsence = true;
          }
          */

          if (this.itemDetails.itemType === ItemTypes.EVENT_CLASS_STAGE) {
            this.isExternalEventClass = true;
          }
          if (this.itemDetails.itemType === ItemTypes.EVENT_ONLINE_STAGE) {
            this.isExternalEventOnline = true;
          }

          this.isExternalCourse = this.isExternalCourseClass || this.isExternalCourseOnline || this.CanSetPresenceAbsence;
          this.isExternalEvent = this.isExternalEventClass || this.isExternalEventOnline;

          this.isWebinar = InitiativeUtils.isWebinar(this.itemDetails.itemType);
          const objectTypeSpecialization: ItemAttribute = this.itemDetails?.itemAttributes?.find((itemAttribute: ItemAttribute): boolean => {
            return itemAttribute.attributeType === ItemAttributeTypes.OBJECT_TYPE_SPECIALIZATION;
          });

          this.isWebinarSpecialization = objectTypeSpecialization?.attributeValue === ItemTypes.WEBINAR;



          this.isAssessment = InitiativeUtils.isAssessment(<any>this.itemDetails.itemType);
          this.isSyllabusCourse = ItemUtil.isSyllabusCourse(<any>this.itemDetails) || InitiativeUtils.isCourse(<any>this.itemDetails.itemType) || this.isAssessment || this.isExternalCourse || this.isWebinar || this.isExternalEvent;
          this.isSurveyItem = ItemUtil.isSurveyItem(<any>this.itemDetails);
          this.syllabus = this.itemDetails && this.itemDetails.extendedItem;

          if (this.syllabus) {
            SyllabusUtil.formatSyllabus(this.syllabus, this.frontEndSyllabus, this.applicationLang);
            this.formatSyllabusForFrontEnd(this.frontEndSyllabus, this.syllabus, this.applicationLang);
          }
          return true;
        })
      )
      .subscribe(data => {
        if (this.itemDetails && this.itemDetails.itemId) {
          // Recupero la lista di persone che mi hanno suggerito l'oggetto
          let getItemPrompterListPromise = this.getItemPrompterList();
          getItemPrompterListPromise.then(() => {
            if (this.itemDetails.itemType === ItemTypes.ASSESSMENT) {
              this.isSurveyTimeRangeCompatible = this.isSurveySessionTimeCompatible(this.itemDetails)
            } else {
              this.isSurveyTimeRangeCompatible = true;
            }
            this.initData();
          })
          // .catch(() => {
          //   // this.manageItemNotAvailable();
          // })
        } else {
          this.manageItemNotAvailable(true);
        }
      });
  }

  // Controlla se l'utente si può preiscrivere ad un'edizione
  checkIfUserCanPreiscritionOnEditions() {
    let userCanPreiscritionOnEditions = false;
    for (let i = 0; i < this.editions.length; i++) {
      if (this.isPreregisterEnabled(this.editions[i])) {
        userCanPreiscritionOnEditions = true;
      }
    }
    return userCanPreiscritionOnEditions;
  }
  manageItemNotAvailable(openModal?: boolean) {
    this.objectNotFound = true;
    this.isGettingItemDetails = false;
    this.isLoadingEditions = false;
    this.isGettingChilds = false;
    this.isLoadingTabs = false;
    this.isGettingBreadcrumbs = false;
    if (openModal) {
      this.modalService.open('itemNotAvailable');
      document.getElementById('itemNotAvailable').focus();
    }
  }

  closeItemNotAvailableModal() {
    this.modalService.close('itemNotAvailable');
  }

  // Verifica se mostrare la tab degli allegati
  showAttachmentsTabContent() {
    if (this.attachmentList && this.attachmentList.length && ((this.isOnlineCourse && this.itemLinkedToCourse && !this.itemLinkedToCourse.isItemOtherOrPhysicalType)
      || (this.itemDetails && !this.itemDetails.isItemOtherOrPhysicalType))) {
      return true;
    }

    return false;
  }

  // Torna l'avatar di un utente
  getUserAvatar(selectedUser): string {
    return (selectedUser && selectedUser.userOptions && selectedUser.userOptions.avatarImage) || (selectedUser && selectedUser && (!selectedUser.chiaveSesso || selectedUser.chiaveSesso == "M") ? 'assets/img/placeholder_male.svg' : 'assets/img/placeholder_female.svg');
  }

  // Recupera la lista delle persone che hanno suggerito un item
  getItemPrompterList() {
    return new Promise((resolve, reject) => {
      resolve(true);

      this.getItemPrompterList$ = this.itemService.getItemPrompterList(this.itemDetails.itemId)
        .subscribe(data => {
          if (data.error) {
            this.toastr.error(this.translate.instant('errors.' + data.error));
          } else if (data.response && data.response.length) {
            this.prompterList = data.response;
            if (this.prompterList && this.prompterList.length) {
              this.hasPrompter = true;
            } else {
              this.hasPrompter = false;
            }
          };
          resolve(true);
        }
          , catchError((err, caught) => {
            if (err && err.message) {
              // Vedo se c'è la traduzione dell'errore
              this.toastr.error(this.translate.instant('errors.' + err.message));
            }

            reject();
            return err;
          })
        )
    })
  }

  // Switch aggiungi/rimuovi dai preferiti
  addToFavourites() {
    this.isSettingBookmarks = true;
    let serviceToUse = null;

    if (this.syllabus.isBookmarked) {
      serviceToUse = this.itemService.removeCourseFromBookmarks(this.syllabus.itemId);
    } else {
      serviceToUse = this.itemService.addCourseToBookmarks(this.syllabus.itemId);
    };

    serviceToUse
      .subscribe(data => {
        if (data.error) {
          // Mostro il toaster di errore
          this.toastr.error(this.translate.instant('errors.' + data.error));
        } else {
          this.syllabus.isBookmarked = !this.syllabus.isBookmarked;
        }
        // Spengo il loader
        this.isSettingBookmarks = false;
      })
  }

  // Formatta il Syllabus per il front end
  formatSyllabusForFrontEnd(frontEndSyllabus: Syllabus, syllabus: Item, lang: string) {
    let syllabusIndex: number = this.langsService.findItemLangIndex(lang, syllabus);
    let selectedItemLang = syllabus.itemLangs[syllabusIndex];
    frontEndSyllabus.TITLE.data = selectedItemLang;
    frontEndSyllabus.SUBTITLE.data = { ...selectedItemLang };

    // Recupero dal syllabus alcune parti del programma, qualora fossero state precedentemente salvatate, oppure le preparo per i futuri salvataggi (linkandole correttamente all'oggetto che sarà in binding e aggiungendo la lingua agli attributi del Syllabus)
    for (let key in frontEndSyllabus) {
      if (frontEndSyllabus.hasOwnProperty(key)) {
        if (SyllabusUtil.isAttributeWithLang(key) && syllabus.itemAttributes) {
          let langFoundInFrontEndSyllabus = false;
          const currentAttributeLang = ItemUtil.getAttributeLangByKey(syllabus, key, lang);
          if (currentAttributeLang) {
            frontEndSyllabus[key].data = currentAttributeLang;
            langFoundInFrontEndSyllabus = true;
          }

          // Se non ho trovato la lingua, l'aggiungo all'oggetto di front end affinché venga eseguito correttamente il binding nella View
          if (!langFoundInFrontEndSyllabus) {
            let newLangAttribute = ItemUtil.createAttributeWithLang(syllabus.itemId, key, lang, null);
            // syllabus.itemAttributes.push(newLangAttribute);
            frontEndSyllabus[key].data = newLangAttribute.attributeLangs[0];
          }
        }
      }
    }


    this.syllabusFormatted = true;
  }

  // Formatta le date unendole tramite il luogo
  formatEditionsByPlace(courseEditionList: any[]) {
    const editionsByPlace = [];

    const places: any = {};

    // raggruppare per il luogo della prima giornata dell'edizione
    courseEditionList.forEach((courseEdition: any) => {
      if (courseEdition.days && courseEdition.days.length) {
        let courseDay = courseEdition.days[0];
        const placeId = courseDay.location && this.getLocationNameForCard(courseDay.location);
        if (placeId && places[placeId]) {
          places[placeId].editions.push(courseEdition);

        } else if (placeId && !places[placeId]) {
          places[placeId] = { editions: [] };
          places[placeId].editions.push(courseEdition);
        }
      }
    });

    for (const key in places) {
      if (places.hasOwnProperty(key)) {
        const element = places[key];
        editionsByPlace.push({
          place: key,
          editions: element.editions
        });
      }
    }

    // ordino per place
    return editionsByPlace.sort((x, y) => sortByRules<any>(x, y, false, [{ fieldName: 'place' }]));
  }

  getLocationNameForCard(courseDayLocation) {
    return (courseDayLocation.place || courseDayLocation.city || courseDayLocation.address) && (courseDayLocation.place || courseDayLocation.city || courseDayLocation.address).id
  }

  // Setta la tipologia dell'item
  setItemType() {
    let objectType = ItemUtil.getAttributeByKey(this.itemDetails, ItemAttributeTypes.OBJECT_TYPE);
    this.itemType = objectType && objectType.attributeValue;
  }

  // Torna le edizioni a cui l'utente si è iscritto
  getSubscribedEditions() {
    if (this.itemDetails && this.isSyllabusCourse && this.editions && this.editions.length) {
      // Recupero le edizioni a cui l'utente
      let subscribedEditions = this.editions.filter((currentEdition: any) => {
        return currentEdition.isConfirmed || currentEdition.isPresent || currentEdition.isObConfirmed;
      });

      return subscribedEditions;
    }
  }

  // Torna l'edizione di un assessment
  getEditionOfAssessment(assessment) {
    // Se sono nel dettaglio di un assessment, 'editions' conterrà l'edizione della rilevazione; così, devo controllare nel contenitore contenente tutte le edizioni
    //let itemRef = this.isAssessment ? this.editionsListWithPastEditions : this.editions;
    let itemRef = this.editionsListWithPastEditions;
    if (assessment && itemRef && itemRef.length) {
      let editions = itemRef.filter((currentEdition: any) => {
        let addEdition;
        if (currentEdition && currentEdition.assessmentIds && currentEdition.assessmentIds.length) {
          for (let l = 0, idsLength = currentEdition.assessmentIds.length; l < idsLength; l++) {
            const currentId = currentEdition.assessmentIds[l] && currentEdition.assessmentIds[l].itemId;
            addEdition = currentId && (currentId === assessment.stageItemId || currentId === assessment.itemId);
            if (addEdition) {
              break;
            }
          }
        }
        return addEdition;
      });
      // Se è una rilevazione e non ho trovato nessuna iniziativa associata significa che è una rilevazione a sè stante
      if (this.isAssessment && (!editions || !editions.length) && itemRef && itemRef.length) {
        //  return itemRef[0];
        // I dati come isSurveyPassed ecc sono nell'itemDetails se sono nel dettaglio di una rilevazione a sé stante
        return this.itemDetails;
      }
      return editions && editions[0];
    }
  }

  // Torna le edizioni, raggruppate per luogo, a cui l'utente si è iscritto
  getSubscribedEditionsGroupedByPlace() {
    if (this.itemDetails && this.isSyllabusCourse && this.editionsByPlace && this.editionsByPlace.length) {
      // Recupero le edizioni a cui l'utente
      let editionsByPlace = this.editionsByPlace.filter((currentEditionPlace: any) => {
        currentEditionPlace.editions = currentEditionPlace.editions.filter((edition: any) => {
          return edition.isConfirmed || edition.isPresent || edition.isObConfirmed;
        });
        return currentEditionPlace.editions && currentEditionPlace.editions.length;
      });

      return editionsByPlace;
    }
  }

  getAssessmentSessionsToStart() {
    let assessmentSessionsToStart = [];
    if (this.editionsListWithPastEditions && this.editionsListWithPastEditions.length) {
      assessmentSessionsToStart = this.editionsListWithPastEditions.filter((itemRef: any) => {
        return itemRef.assessmentId === this.itemDetails.itemId && !itemRef.isSurveyStarted && !itemRef.isSurveyPassed;
      });
    }

    return assessmentSessionsToStart;
  }

  getAssessmentSessionsStarted() {
    let assessmentSessionsStarted = [];
    if (this.editionsListWithPastEditions && this.editionsListWithPastEditions.length) {
      assessmentSessionsStarted = this.editionsListWithPastEditions.filter((itemRef: any) => {
        return itemRef.assessmentId === this.itemDetails.itemId && itemRef.isSurveyStarted && !itemRef.isSurveyPassed;
      });
    }

    return assessmentSessionsStarted;
  }

  getAssessmentSessionsPassed() {
    let assessmentSessionsPassed = [];
    if (this.editionsListWithPastEditions && this.editionsListWithPastEditions.length) {
      assessmentSessionsPassed = this.editionsListWithPastEditions.filter((itemRef: any) => {
        return itemRef.assessmentId === this.itemDetails.itemId && itemRef.isSurveyPassed;
      });
    }

    return assessmentSessionsPassed;
  }

  // Torna la descrizione della sezione delle edizioni
  getEditionsDescription() {
    if (this.itemDetails && this.isSyllabusCourse && this.editions && this.editions.length) {
      if (this.isExternalEventOnline || this.isExternalCourseOnline) {
        return this.translate.instant('itemDetail.editionsSectionDescription.EXTERNAL_COURSE_ONLINE');
      } else if (this.isAssessment) {
        let descriptionText = '';
        // Recupero le survey da iniziare
        let assessmentsToStart = this.getAssessmentSessionsToStart();
        let assessmentsToStarted = this.getAssessmentSessionsStarted();
        let assessmentsPassed = this.getAssessmentSessionsPassed();
        let assessmentsToStartLength = assessmentsToStart && assessmentsToStart.length;
        let assessmentsToStartedLength = assessmentsToStarted && assessmentsToStarted.length;
        let assessmentsPassedLength = assessmentsPassed && assessmentsPassed.length;

        descriptionText = descriptionText + this.translate.instant('itemDetail.editionsSectionDescription.YOU_HAVE');

        if (assessmentsPassedLength) {
          descriptionText = descriptionText + ' ' + this.translate.instant('itemDetail.editionsSectionDescription.COMPLETED').toLowerCase() + ' ';
          if (!assessmentsToStartedLength && !assessmentsToStartLength) {
            descriptionText = descriptionText + this.translate.instant('itemDetail.editionsSectionDescription.ALL').toLowerCase() + ' ' + this.translate.instant('itemDetail.editionsSectionDescription.THE_SURVEYS').toLowerCase();
          }
          descriptionText = descriptionText + '.'
        }

        if (assessmentsToStartedLength) {
          if (assessmentsPassedLength) {
            descriptionText = descriptionText + '<br>';
          }
          descriptionText = descriptionText + ' ' + assessmentsToStartedLength + ' ';
          if (assessmentsToStartedLength === 1) {
            descriptionText = descriptionText + this.translate.instant('itemDetail.editionsSectionDescription.SURVEY_STARTED').toLowerCase();
          } else {
            descriptionText = descriptionText + this.translate.instant('itemDetail.editionsSectionDescription.SURVEYS_STARTED').toLowerCase();
          }
          descriptionText = descriptionText + '.'
        }

        if (assessmentsToStartLength) {
          if (assessmentsToStartedLength || assessmentsPassedLength) {
            descriptionText = descriptionText + '<br>';
          }
          descriptionText = descriptionText + ' ' + assessmentsToStartLength + ' ';
          if (assessmentsToStartLength === 1) {
            descriptionText = descriptionText + this.translate.instant('itemDetail.editionsSectionDescription.SURVEY_NOT_STARTED').toLowerCase();
          } else {
            descriptionText = descriptionText + this.translate.instant('itemDetail.editionsSectionDescription.SURVEYS_NOT_STARTED').toLowerCase();
          }
          descriptionText = descriptionText + '.'
        }

        return descriptionText;
      } else {
        // Recupero le edizioni a cui l'utente si è iscritto
        let descriptionText = '';
        let subscribedEditions = this.getSubscribedEditions();
        if (!subscribedEditions || (subscribedEditions && !subscribedEditions.length)) {
          // L'utente non si è ancora iscritto a nessuna edizione
          descriptionText = this.translate.instant('itemDetail.editionsSectionDescription.NOT_YET_SUBSCRIBED');
        } else {
          // Eseguo un riassunto delle edizioni confermate dall'utente
          descriptionText = this.translate.instant('itemDetail.editionsSectionDescription.YOU_SUBSCRIBED_TO');
          descriptionText = descriptionText + ' ' + subscribedEditions.length + ' ';

          if (subscribedEditions.length === 1) {
            descriptionText = descriptionText + this.translate.instant('itemDetail.editionsSectionDescription.EDITION').toLowerCase();
          } else {
            descriptionText = descriptionText + this.translate.instant('itemDetail.editionsSectionDescription.EDITIONS').toLowerCase();
          }

          if (!this.isWebinar) {
            descriptionText = descriptionText + ' (';

            let editionsByPlace = this.getSubscribedEditionsGroupedByPlace();
            if (editionsByPlace) {
              for (let q = 0, editionsByPlaceLength = editionsByPlace.length; q < editionsByPlaceLength; q++) {
                let currentPlace = editionsByPlace[q];
                descriptionText = descriptionText + currentPlace.place;
                if (q === editionsByPlaceLength - 2 && editionsByPlaceLength > 1) {
                  descriptionText = descriptionText + ' ' + this.translate.instant('generic.AND').toLowerCase() + ' ';
                } else if (q !== editionsByPlaceLength - 1) {
                  descriptionText = descriptionText + ', ';
                }
              }
            }
            descriptionText = descriptionText + ')';
          }
        }
        return descriptionText;
      }
    }
  }

  // Recuepra i like
  getLikes() {
    let refId = this.itemId || this.itemToAddId;
    this.isGettingLikes = true;
    let getLikesCountPromise = ItemUtil.getLikesCountsByTypesAndReference(refId, ReferenceTypes.ITEM, LikeTypes.VOTE, this.itemService, this.toastr);
    getLikesCountPromise.then((objData: { likes: number, dislikes: number }) => {
      // Salvo i like
      this.likeCounter.likes = objData.likes;

      // Salvo i dislike
      this.likeCounter.dislikes = objData.dislikes;

      // Recupero i miei like
      let getMyLikesCountPromise = ItemUtil.getMyLikeByTypeAndReference(refId, ReferenceTypes.ITEM, LikeTypes.VOTE, this.itemService, this.toastr);
      getMyLikesCountPromise.then((resultData: { currentLike: Like, iLikeThis: boolean, iDislikeThis: boolean }) => {
        if (resultData) {
          this.currentLike = resultData.currentLike;
          this.iLikeThis = resultData.iLikeThis;
        }
        this.isGettingLikes = false;

      })
        .catch(() => {
          this.isGettingLikes = false;
        })
    })
      .catch(() => {
        this.isGettingLikes = false;
      })
  }

  // Apre una modale per condividere l'oggetto con altre persone
  openModalForShareItem() {
    if (this.isItemDisabled) {
      return;
    }
    // Apro la modale
    this.modalService.open('suggestItem');
    document.getElementById('suggestItem').focus();
  }

  // Chiude la modale per suggerire l'oggetto
  closeModalForShareItem(confirm?: boolean) {
    this.modalService.close('suggestItem');
    this.libraryItemsData.searchedText = "";
    if (confirm) {
      this.isGettingItemDetails = true;

      let destinationUser;
      for (let m = 0, usersLength = this.usersToSuggestData.usersToSuggest.length; m < usersLength; m++) {
        let currentUser = this.usersToSuggestData.usersToSuggest[m];
        if (currentUser.userId === this.usersToSuggestData.selectedUserId) {
          destinationUser = currentUser;
          break;
        }
      }
      this.suggestItemToUser$ = this.itemService.addSuggestion(destinationUser, this.itemDetails.itemId)
        .subscribe(data => {
          if (data.error) {
            this.toastr.error(this.translate.instant('errors.' + data.error));
          } else {
            this.toastr.success(this.translate.instant(this.translate.instant('itemDetail.ITEM_CORRECTLY_SUGGESTED')));
          }
          this.deselectUsersToSuggestSelection();
          this.isGettingItemDetails = false;
          this.usersToSuggestData.resetUsersToSuggest = true;
          this.clearUsersToSuggestData();
          this.libraryItemsData.searchedText = "";
          if (this.countUsersToSuggestItem$) {
            this.countUsersToSuggestItem$.unsubscribe();
          }
          this.isFetchingUsersToSuggest = false;
        },
          (err) => {
            this.deselectUsersToSuggestSelection();
            this.isGettingItemDetails = false;
            this.usersToSuggestData.resetUsersToSuggest = true;
            this.clearUsersToSuggestData();
            this.libraryItemsData.searchedText = "";
            if (this.countUsersToSuggestItem$) {
              this.countUsersToSuggestItem$.unsubscribe();
            }
            this.isFetchingUsersToSuggest = false;
          });
    } else {
      this.deselectUsersToSuggestSelection();
    }
  }

  deselectUsersToSuggestSelection() {
    this.usersToSuggestData.selectedUserId = null;
  }

  selectedUserToSuggestChanged(user) {
    this.usersToSuggestData.selectedUserId = user.userId;
  }

  clearUsersToSuggestData(): void {
    this.usersToSuggestData.usersToSuggestLoaded = 0;
    this.usersToSuggestData.usersToSuggestFromRecord = 0;
    this.usersToSuggestData.usersToSuggestCurrentPage = 1;
    this.usersToSuggestData.usersToSuggest = [];
    this.usersToSuggestData.usersToSuggestCounter = 0;
  }

  // Recupera una lista di utenti a cui suggerire l'oggetto
  onGetUsersToSuggest(searchedText?: string, newSearch?: boolean) {
    if (!searchedText) {
      this.usersToSuggestData.resetUsersToSuggest = true;
      this.usersToSuggestData.searchedText = searchedText;
      this.clearUsersToSuggestData();
      if (this.countUsersToSuggestItem$) {
        this.countUsersToSuggestItem$.unsubscribe();
      }
      this.isFetchingUsersToSuggest = false;
      return of(new SenecaResponse(null, []));
    }
    // Avvio il loader
    this.isFetchingUsersToSuggest = true;
    if (newSearch || this.usersToSuggestData.searchedText) {
      searchedText = searchedText ? searchedText : "";
      this.usersToSuggestData.resetUsersToSuggest = true;
      this.usersToSuggestData.searchedText = searchedText;
      this.usersToSuggestData.usersToSuggestLoaded = 0;
      this.usersToSuggestData.usersToSuggestFromRecord = 0;
      this.usersToSuggestData.usersToSuggest = [];
      this.usersToSuggestData.usersToSuggestCounter = 0;
    } else {
      this.usersToSuggestData.resetUsersToSuggest = false;
    }
    if (this.countUsersToSuggestItem$) {
      this.countUsersToSuggestItem$.unsubscribe();
    }
    this.countUsersToSuggestItem$ = this.itemService.countPersonsToSuggestItem(this.itemDetails.itemId, this.usersToSuggestData.searchedText)
      .pipe(
        switchMap(
          (usersCounter: SenecaResponse<number>) => {
            if (usersCounter.error) {
              // Torno un observable simulando una senecaResponse per continuare il flusso dello stream
              return of(new SenecaResponse(usersCounter.error, null))
            } else {
              // Salvo il counter
              this.usersToSuggestData.usersToSuggestCounter = usersCounter.response;

              // Calcolo la paginazione
              let pageNum = 0;
              if (this.usersToSuggestData.usersToSuggestCurrentPage && this.usersToSuggestData.usersToSuggestNumRecords) {
                pageNum = this.usersToSuggestData.usersToSuggestCurrentPage;
              } else {
                pageNum = 0;
              }

              if (this.usersToSuggestData.usersToSuggestCounter) {
                return this.itemService.getPersonsToSuggestItem(this.itemDetails.itemId, this.usersToSuggestData.searchedText, pageNum, this.usersToSuggestData.usersToSuggestNumRecords);
              } else {
                // Torno un observable simulando una senecaResponse per continuare il flusso dello stream
                return of(new SenecaResponse(null, []));
              }
            }
          }
        )
        , catchError((err, caught) => {
          if (err && err.message) {
            // Vedo se c'è la traduzione dell'errore
            this.toastr.error(this.translate.instant('errors.' + err.message));
          }
          this.isFetchingUsersToSuggest = false;
          // Torniamo l'Observable di errore, affinché si possa ri-provare l'operazione
          return err;
        }),
        take(1)
      ).subscribe(
        (usersData: SenecaResponse<any>) => {
          if (usersData.error) {
            // Vedo se c'è la traduzione dell'errore
            this.toastr.error(this.translate.instant('errors.' + usersData.error));
          } else {
            // Se ho i risultati, li formatto
            if (usersData.response && usersData.response.length) {
            }
            // Aggiungo i risultati alla lista, incrementando il numero di risultati ottenuti
            this.usersToSuggestData.usersToSuggestLoaded += usersData.response && usersData.response.length;
            this.usersToSuggestData.usersToSuggest = usersData.response || [];
          }
          this.isFetchingUsersToSuggest = false;
        }
        , (err) => {
          this.isFetchingUsersToSuggest = false;
          if (err && err.message) {
            // Vedo se c'è la traduzione dell'errore
            this.toastr.error(this.translate.instant('errors.' + err.message));
          }
        }
      );
  }

  // Cambia la paginazione alla lista degli utenti da scegliere per condividere l'oggetto
  paginationUsersToSuggestItemChanged(newPage, searchedText?: string) {
    this.usersToSuggestData.usersToSuggestCurrentPage = newPage;
    // Avvio una nuova ricerca
    this.onGetUsersToSuggest(searchedText, false);
  }

  // Verifica se l'utente loggato può accedere alla gestione degli item
  canManageLearningPlan() {
    return this.authService.canManageLearningPlan(this.loggedUser);
  }

  getItemLangInputSubtitle() {
    return this.translate.instant('newContainer.placeholders.INSERT_LEARNING_PLAN_TITLE') + (this.isItemLangTitlesEmpty() ? '*' : '');
  }

  onCurrentItemLangChanged(newVal?) {
    this.currentItemLang.subTitle = newVal;
  }

  onDefaultItemLangChanged(newVal?) {
    this.defaultItemLang.subTitle = newVal;
  }

  getCurrentItemLangDescSubtitle() {
    return this.translate.instant("newContainer.placeholders.INSERT_LEARNING_PLAN_SUBTITLE");
  }

  public isItemLangTitlesEmpty() {
    if (this.newPlaylistObj && this.newPlaylistObj.itemLangs && this.newPlaylistObj.itemLangs.length) {
      for (let i = 0; i < this.newPlaylistObj.itemLangs.length; i++) {
        let itemLang = this.newPlaylistObj.itemLangs[i];
        if (itemLang.langCode === this.defaultLang) {
          if (itemLang && itemLang.title && itemLang.title.length) {
            return false;
          }
        } else {
          if (itemLang && itemLang.title && itemLang.title.length) {
            return false;
          }
        }
      }
    }
    return true;
  }

  onDefaultItemLangTitleChanged(newVal?: string) {
    this.defaultItemLang.title = newVal;
  }

  onCurrentItemLangTitleChanged(newVal?: string) {
    this.currentItemLang.title = newVal;
  }

  private initItemLangs = () => {
    this.langList.forEach((currLang: any) => {
      let index: number = this.findItemLangIndex(currLang.langCode);
      if (index === -1) {
        this.newPlaylistObj.itemLangs.push({
          itemId: null,
          langCode: currLang.langCode,
          title: null,
          subTitle: null,
          description: null
        });
      }
    });

    // Recupero la lingua di default del sistema
    let index: number = this.findItemLangIndex(this.defaultLang);
    if (index !== -1) {
      this.defaultItemLang = this.newPlaylistObj.itemLangs[index];
    }
  }

  selectLang(lang?: Lang) {
    lang = lang || this.currentLang;

    let index: number = this.findItemLangIndex(lang.langCode);
    if (this.newPlaylistObj && this.newPlaylistObj.itemLangs && this.newPlaylistObj.itemLangs.length) {
      this.currentItemLang = this.newPlaylistObj.itemLangs[index];
    }
    // Fix per non far vedere 'null' in edit su ie
    if (this.currentItemLang && !this.currentItemLang.description) {
      this.currentItemLang.description = '';
    }
    this.currentLang = lang;

    this.langInitForPlaylistDone = true;
  }

  private getLangLabel(lang: string) {
    return this.translate.instant(`generic.langs.${lang}`);
  }

  private initLangs() {
    this.langList = [];
    this.langMap = {};

    this.availableLangs.forEach((one: any) => {
      if (one.langCode === this.defaultLang) {
        let curr = <Lang>{
          langCode: String(one.langCode),
          langDesc: this.getLangLabel(one.langCode),
          mandatory: one.mandatory === true,
          active: true
        };
        this.langList.push(curr);
        this.currentLang = curr;
      }
    });

    this.availableLangs.forEach((one: any) => {
      if (one.langCode !== this.defaultLang) {
        this.langList.push(<Lang>{
          langCode: String(one.langCode),
          langDesc: this.getLangLabel(one.langCode),
          mandatory: one.mandatory === true,
          active: true
        });
      }
    });

    this.langList.forEach((lang: Lang) => {
      this.langMap[lang.langCode] = lang;
    });
  }

  isDescriptionValorized() {
    if (this.isCurrentItemLangDefaultLang()) {
      if (this.currentItemLang && this.currentItemLang.description && this.currentItemLang.description.length) {
        return true;
      }
    } else {
      if (this.defaultItemLang && this.defaultItemLang.description && this.defaultItemLang.description.length) {
        return true;
      }
    }

    return false;
  }

  private findItemLangIndex(langCode: string) {
    let index: number = -1;
    if (this.newPlaylistObj && this.newPlaylistObj.itemLangs) {
      this.newPlaylistObj.itemLangs.forEach((one: ItemLang, idx: number) => {
        if (one.langCode === langCode) {
          index = idx;
        }
      });
    }
    return index;
  }

  public isCurrentItemLangDefaultLang(): boolean {
    return this.currentItemLang && this.defaultLang && this.currentItemLang.langCode === this.defaultLang;
  };

  // Toggle, nella modale per aggiungere un oggetto ad una playlist, che permette di creare una playlist nuova
  goToCreateNewPlaylist() {
    if (this.defaultLang && this.availableLangs && this.applicationLang && !this.langInitForPlaylistDone) {
      this.newPlaylistObj = <Item>{ itemLangs: [] };
      this.initLangs();
      this.initItemLangs();
      this.selectLang();

    }

    this.isCreatingNewPlaylist = true;
    setTimeout(() => {
      let contentModal = document.getElementById("add-item-to-playlist-content-modal");
      if (contentModal) {
        contentModal.scrollTop = 0;
      }
    });
  }

  // Chiudo la creazione della nuova Playlist
  closeCreateNewPlaylist(confirm?: boolean) {
    if (confirm) {
      this.isGettingItemDetails = true;
      this.isCreatingPlaylist = true;
      this.itemService.createLearningPlan(this.newPlaylistObj, false, false, CommonConstants.APPLICATION_CORPORATE_ACADEMY)
        .subscribe(data => {
          this.isGettingItemDetails = false;
          this.isCreatingPlaylist = false;
          if (data.error) {
            // Mostro il toaster di errore
            this.toastr.error(this.translate.instant('errors.' + data.error));
            this.resetDataOnUserPlaylistCreation();
          } else {
            this.toastr.success(this.translate.instant('generic.DATA_SAVED'));
            this.refreshCachePlaylist$ = this.itemService.refreshCachePlaylist(this.loggedUser.user.userId).subscribe();
            this.isCreatingNewPlaylist = false;
            this.onGetLibraryItems(null, true);
            this.resetDataOnUserPlaylistCreation();
            setTimeout(() => {
              let contentModal = document.getElementById("add-item-to-playlist-content-modal");
              if (contentModal) {
                contentModal.scrollTop = 0;
              }
            });
          }
        },
          (err) => {
            this.isGettingItemDetails = false;
            this.isCreatingPlaylist = false;
            this.resetDataOnUserPlaylistCreation();
            this.toastr.error(err.message);
          })

    } else {
      this.resetDataOnUserPlaylistCreation();
      this.langInitForPlaylistDone = false;
      this.isCreatingNewPlaylist = false;
    }
  }

  resetDataOnUserPlaylistCreation() {
    this.newPlaylistObj.subTitle = "";
    this.newPlaylistObj.title = "";
    this.newPlaylistObj.description = "";
    this.currentItemLang.subTitle = "";
    this.currentItemLang.title = "";
    this.currentItemLang.description = "";
  }

  // Apre la modale per aggiungere l'item ad una playlist
  addItemToPlaylist() {
    if (this.isItemDisabled) {
      return;
    }
    this.isCreatingNewPlaylist = false;
    this.modalService.open('addItemToPlaylist');
    document.getElementById("addItemToPlaylist").focus();

    setTimeout(() => {
      document.getElementById("addItemToPlaylist").focus();
      let contentModal = document.getElementById("add-item-to-playlist-content-modal");
      if (contentModal) {
        contentModal.scrollTop = 0;
      }
    }, 100);
    this.onGetLibraryItems(null, true);
  }

  closeAddContentModal(confirm?: boolean) {
    this.newPlaylistObj = null;
    this.langInitForPlaylistDone = false;
    this.isCreatingNewPlaylist = false;

    this.modalService.close('addItemToPlaylist');
    if (confirm) {
      this.isGettingItemDetails = true;
      // Creo il contenitore dei childs
      let childsId: Array<string> = [];

      // e ci aggiungo l'id dell'oggetto
      childsId.push(this.itemDetails.itemId);

      this.addChilds$ = this.itemService.addChilds(this.libraryItemsData.selectedItemId, childsId, false)
        .subscribe(data => {
          if (data.error) {
            if (data.error.indexOf('ER_DUP_ENTRY: Duplicate entry') !== -1) {
              this.toastr.error(this.translate.instant('errors.ITEM_ALREADY_ADDED'));
            } else {
              this.toastr.error(this.translate.instant('errors.' + data.error));
            }
          } else {
            this.toastr.success(this.translate.instant(this.translate.instant('itemDetail.ADDED_TO_LP')));
          }
          this.deselectItemSelection();
          this.isGettingItemDetails = false;
        },
          (err) => {
            this.deselectItemSelection();
            this.isGettingItemDetails = false;
          });
    } else {
      this.deselectItemSelection();
    }
  }

  deselectItemSelection() {
    this.libraryItemsData.selectedItemId = null;
  }

  selectedItemOfOnlineCourseChanged(item, i: number) {
    this.libraryItemsData.selectedItemId = item.itemId;
  }

  // Pulisce gli oggetti della Library recuperati
  clearLibraryItemsData(): void {
    this.libraryItemsData.libraryItemsLoaded = 0;
    this.libraryItemsData.libraryItemsFromRecord = 0;
    this.libraryItemsData.libraryItemsCurrentPage = 1;
    this.libraryItemsData.libraryItems = [];
    this.libraryItemsData.libraryItemsCounter = 0;
  }

  // Recupera una lista di oggetti della Library
  onGetLibraryItems(searchedText?: string, newSearch?: boolean) {
    // Avvio il loader
    this.isFetchingLibraryItems = true;
    if (newSearch) {
      searchedText = searchedText ? searchedText : "";
      this.libraryItemsData.resetLibraryItems = true;
      this.libraryItemsData.searchedText = searchedText;
      this.clearLibraryItemsData();
    } else {
      this.libraryItemsData.resetLibraryItems = false;
    }
    if (this.countItems$) {
      this.countItems$.unsubscribe();
    }
    this.countItems$ = this.itemService.countAllMyLearningPlans(this.libraryItemsData.searchedText)
      .pipe(
        switchMap(
          (itemsCounter: SenecaResponse<MapById<number>>) => {
            if (itemsCounter.error) {
              // Torno un observable simulando una senecaResponse per continuare il flusso dello stream
              return of(new SenecaResponse(itemsCounter.error, null))
            } else {
              // Salvo il counter
              this.libraryItemsData.libraryItemsCounter = itemsCounter.response;

              // Calcolo la paginazione
              let fromRecord = 0;
              if (this.libraryItemsData.libraryItemsCurrentPage && this.libraryItemsData.libraryItemsNumRecords) {
                fromRecord = (this.libraryItemsData.libraryItemsCurrentPage - 1) * this.libraryItemsData.libraryItemsNumRecords;
              } else {
                fromRecord = 0;
              }
              // Aggiorno la lista delle playlist
              return this.itemService.getAllMyLearningPlans(this.libraryItemsData.searchedText, fromRecord, this.libraryItemsData.libraryItemsNumRecords);
            }
          }
        )
        , catchError((err, caught) => {
          if (err && err.message) {
            // Vedo se c'è la traduzione dell'errore
            this.toastr.error(this.translate.instant('errors.' + err.message));
          }
          this.isFetchingLibraryItems = false;
          // Torniamo l'Observable di errore, affinché si possa ri-provare l'operazione
          return err;
        }),
        take(1)
      ).subscribe(
        (itemsData: SenecaResponse<any>) => {
          if (itemsData.error) {
            // Vedo se c'è la traduzione dell'errore
            this.toastr.error(this.translate.instant('errors.' + itemsData.error));
          } else {
            // Se ho i risultati, li formatto
            if (itemsData.response && itemsData.response.length) {
            }
            // Aggiungo i risultati alla lista, incrementando il numero di risultati ottenuti
            this.libraryItemsData.libraryItemLoaded += itemsData.response && itemsData.response.length;
            this.libraryItemsData.libraryItems = itemsData.response || [];
          }
          this.isFetchingLibraryItems = false;
        }
        , (err) => {
          this.isFetchingLibraryItems = false;
          if (err && err.message) {
            // Vedo se c'è la traduzione dell'errore
            this.toastr.error(this.translate.instant('errors.' + err.message));
          }
        }
      );
  }

  // Cambia la paginazione alla lista degli Item della Library
  paginationLibraryItemsChanged(newPage) {
    this.libraryItemsData.libraryItemsCurrentPage = newPage;
    // Avvio una nuova ricerca
    this.onGetLibraryItems();
  }

  // Crea un like
  createLike() {
    // Avvio il loader della sezione like/unlike
    this.isGettingLikes = true;
    // Preparo i dati del nuovo like
    let newLike = {
      likeType: LikeTypes.VOTE,
      likeSign: '+'
    }
    let referenceId = this.itemId || this.itemToAddId;
    this.createLike$ = this.itemService.createLike(newLike, referenceId, ItemTypes.ITEM, CommonConstants.APPLICATION_CORPORATE_ACADEMY, CommonConstants.APPLICATION_CORPORATE_ACADEMY)
      .subscribe(data => {
        // Se ci sono errori, li mostor e torno alla lista dei template
        if (data.error) {
          this.isGettingLikes = false;
          this.toastr.error(this.translate.instant('errors.' + data.error));
        } else {
          // Salvo il nuovo like
          this.currentLike = data.response;

          // Salvo il like aggiunto
          this.iLikeThis = true;

          // Aggiorno le informazioni sul conteggio totale
          this.getLikes();
        }
      })
  }

  getItemChildsInfo(refItem: any) {
    if (refItem && refItem.itemChilds) {
      // Se non sono in uno stato valido, disabilito i tasti che fanno il redirect all'oggetto del learning plan
      this.disableLpItemsForInvalidStatus = false;
      //  da settare agli itemChild se non sono in uno stato valido
      if (this.itemDetails.itemTakers && this.itemDetails.itemTakers.length) {
        this.itemDetails.itemTakers.forEach((taker: any) => {
          if (taker.takerEnrolls && taker.takerEnrolls.length) {
            taker.takerEnrolls.forEach((enroll: any) => {
              let userInitiativeStatus: any = enroll.takerEnrollStatuses && enroll?.takerEnrollStatuses[0];
              userInitiativeStatus = userInitiativeStatus && userInitiativeStatus.statusType || '';
              let invalidStatus = [
                ItemTakerEnrollStatusTypes.USER_STATUS_DELETED,
                ItemTakerEnrollStatusTypes.USER_STATUS_NEGATED,
                ItemTakerEnrollStatusTypes.USER_STATUS_PREREGISTERED
              ];
              if (invalidStatus.indexOf(userInitiativeStatus) >= 0) {
                this.disableLpItemsForInvalidStatus = true;
                this.cdr.detectChanges();
              }
            })
          }
        })
      }

      if (refItem.isProject || refItem.isBlendedProject) {
        // Se è un progetto devo recuperare i child di tutte le sezioni
        this.isGettingChilds = true;
        this.childObjects = refItem.itemChilds.map((item: any) => item.childObject);
        // if (this.childObjects && this.childObjects.length) {
        //   for (let d = 0; d < this.childObjects.length; d++) {
        //     let currentSection = this.childObjects[d];
        //     currentSection.description = currentSection.description && currentSection.description.replace(/(\r\n|\n|\r)/gm, "<br />");
        //   }
        // }

        // Contiene la lista di figli del learning plan (chiamato playlist) obbligatori ma che non hanno il certified poiché, in tal caso, controllo lo stato delle loro potenziali survey
        let mandatoryChildsNotCertified: ItemChild[] = [];
        let mandatoryItems = 0;
        let consumedMandatoryItems = 0;

        for (let k = 0; k < this.childObjects.length; k++) {
          let currentObj: any = this.childObjects[k];
          // Scrorro tutti gli item della sezione
          for (let i = 0; i < currentObj.itemChilds.length; i++) {
            this.childsOfModules = this.childsOfModules + 1;

            let currentItemOfSection = currentObj.itemChilds[i];
            let isMandatory = !!currentItemOfSection.mandatory;
            let isCertified = currentItemOfSection.childObject.isCertified;
            let isConsumed = currentItemOfSection.childObject.isConsumed;

            if (isMandatory) {
              mandatoryItems++;
              if (isConsumed) {
                consumedMandatoryItems++;
              };
              if (isCertified) {
                this.itemsInContainerCertified++;
              }
            }
            // Se non è certificato ed è obbligatorio, lo aggiungo alla lista degli figli di cui controllerò lo stato delle eventuali survey
            if (isMandatory && !isCertified) {
              mandatoryChildsNotCertified.push(currentItemOfSection);
            }
          }
        }
        if (mandatoryItems === consumedMandatoryItems) {
          this.isItemCertifiable = true;
        }
        if (mandatoryChildsNotCertified && mandatoryChildsNotCertified.length) {
          let childSurveysPromise = ItemUtil.getSurveyStatusOfItemChildsData(this.loggedUser.user.userId, mandatoryChildsNotCertified, this.takerService, this.toastr, this.translate, this.childsNotCertifiedButCertifiables);
          childSurveysPromise.then((data) => {
            this.totalChildsWithSurvey = this.childsNotCertifiedButCertifiables + this.itemsInContainerCertified;
          })
            .catch(() => {
            })
        } else {
          this.totalChildsWithSurvey = this.itemsInContainerCertified;
        }
        this.isGettingChilds = false;
      } else if (refItem.isLearningPlan) {
        // Contiene la lista di figli del learning plan (chiamato playlist) obbligatori ma che non hanno il certified poiché, in tal caso, controllo lo stato delle loro potenziali survey
        let mandatoryChildsNotCertified: ItemChild[] = [];
        let mandatoryItems = 0;
        let consumedMandatoryItems = 0;

        for (let k = 0; k < refItem.itemChilds.length; k++) {
          this.childsOfModules = this.childsOfModules + 1;

          let currentObj: any = refItem.itemChilds[k];
          let isMandatory = !!currentObj.mandatory;

          if (currentObj && currentObj.childObject) {
            currentObj.itemTypeLabel = ItemUtil.getItemTypeLabel(currentObj.childObject, this.translate);

            let isCertified = currentObj.childObject.isCertified;
            // Se non è certificato ed è obbligatorio, lo aggiungo alla lista degli figli di cui controllerò lo stato delle eventuali survey
            if (isMandatory && !isCertified) {
              mandatoryChildsNotCertified.push(currentObj);
            }
          }
          if (currentObj.childObject && isMandatory) {
            mandatoryItems++;
            if (currentObj.childObject.isConsumed) {
              consumedMandatoryItems++;
            }
          }
        }
        if (mandatoryItems == consumedMandatoryItems) {
          this.isItemCertifiable = true;
        }

        if (mandatoryChildsNotCertified && mandatoryChildsNotCertified.length) {
          let childSurveysPromise = ItemUtil.getSurveyStatusOfItemChildsData(this.loggedUser.user.userId, mandatoryChildsNotCertified, this.takerService, this.toastr, this.translate, this.childsNotCertifiedButCertifiables);
          childSurveysPromise.then((data) => {
            this.totalChildsWithSurvey = this.childsNotCertifiedButCertifiables + this.itemsInContainerCertified;
          })
            .catch(() => {
            })
        } else {
          this.totalChildsWithSurvey = this.itemsInContainerCertified;
        }
      } else {
        this.attachmentList = [];
        for (let k = 0; k < refItem.itemChilds.length; k++) {
          if (this.itemDetails.isLearningPlan) {
            // Verifico gli engagement, cioè le azioni dell'utente sull'Item corrente per ricavare la percentuale
            ItemUtil.getPercentageAndCertificationDataOfItemChildOfSection(refItem.itemChilds[k]);
          }
          if (refItem.itemChilds[k].childObject && (refItem.itemChilds[k].childObject.itemType === ItemTypes.ATTACHMENT ||
            refItem.itemChilds[k].childObject.itemType === ItemTypes.DAM_ATTACHMENT)) {
            if (!this.attachmentList) {
              this.attachmentList = [];
            }
            if (!this.isDownloadingFile) {
              this.attachmentList.push(refItem.itemChilds[k]);
            }
            this.isDownloadingFile = false;
          }
        }
        this.isGettingChilds = false;
      }
    }
  }

  // Recupera i child dell'item
  getItemChildsByIds(itemChilds: ItemChild[]) {
    let promises: Array<any> = [];
    this.childObjects = [];
    this.childObjectTmp = [];
    if (itemChilds && itemChilds.length) {
      for (let j = 0, childsLength = itemChilds.length; j < childsLength; j++) {
        let currentChild = itemChilds[j];
        promises.push(new Promise((resolve: Function, reject: Function) => {
          this.itemService.getConsumableItemByIdForUser(this.translate, currentChild.referenceId, true, 4, attributeTypesToRetrieve, false, (this.isItemsAdminPreview || this.isInitiativesAdminPreview || this.isInitiativesAdminFromHomePreview))
            .pipe(
              map((childData) => {
                // Aggiungo l'elemento nell'array temporaneo
                this.childObjectTmp[j] = childData;
              })
            )
            .subscribe(data => {
              resolve();
            });
        }))
      }
    }
    // Risolvo le promesse coi dati recuperati
    return Promise.all(promises);
  }

  // Recupero i dati della certificazione / attestato
  getCertificationData(refItem: IDataItem) {
    if (refItem) {
      if (refItem.isLearningPlan || refItem.isProject || refItem.isBlendedProject) {
        // Se non ho una certificazione eseguita la cerco
        this.isGettingChilds = true;
        this.childObjects = refItem.itemChilds.map((item: any) => item.childObject);
        // if (this.childObjects && this.childObjects.length) {
        //   for (let d = 0; d < this.childObjects.length; d++) {
        //     let currentSection = this.childObjects[d];
        //     currentSection.description = currentSection.description && currentSection.description.replace(/(\r\n|\n|\r)/gm, "<br />");
        //   }
        // }
        // Contiene la lista di figli del learning plan (chiamato playlist) obbligatori ma che non hanno il certified poiché, in tal caso, controllo lo stato delle loro potenziali survey
        let childsNotCertified: ItemChild[] = [];
        //Se l'oggetto Library è una playlist pongo la lunghezza a 1, poichè non contiene sezioni non ripeto il ciclo inutilmente, invece se è un LP può contenere più sezioni e di conseguenza più itemChild annidati
        let quantityChilds: any = refItem.isLearningPlan ? 1 : this.childObjectTmp.length;
        for (let k = 0; k < quantityChilds; k++) {
          //come prima se è una playlist gli oggetti da cui deve ricavare le info sono direttamente i figli l'oggetto library
          let currentObj: any = refItem.isLearningPlan ? refItem : this.childObjects[k];
          // Scrorro tutti gli item della sezione
          for (let i = 0; i < currentObj.itemChilds.length; i++) {
            let currentItemOfSection = currentObj.itemChilds[i];
            let isCertified = currentItemOfSection.childObject.isCertified;

            // Se non è certificato , lo aggiungo alla lista dei figli di cui controllerò lo stato delle eventuali survey
            if (!isCertified) {
              childsNotCertified.push(currentItemOfSection);
            }
          }
        }
        if (childsNotCertified && childsNotCertified.length) {
          this.isGettingSurveys = true;
          this.surveyMapForPlaylist = {};
          let refId = this.itemId ? this.itemId : this.itemToAddId;
          let surveyPromise = ItemUtil.getSurveyStatusDataOfSection(this.loggedUser.user.userId, refId, this.takerService, this.toastr, childsNotCertified);
          surveyPromise.then((data: { [itemId: string]: { isSurveyStarted: boolean, isSurveyCertificable: boolean, isItemCertifiable: boolean, referenceId: string } }) => {
            if (data) {
              this.surveyMapForPlaylist = data;
              //per non interferire con un possibile questionario dell'iniziativa genero queste variabili
              this.isSurveyStartedLibrary = false;
              this.isSurveyCertificableLibrary = false;
              //controllo dalla risposta quali oggetti Obbligatori contengono una survey e ne verifico lo stato se iniziato o da iniziare
              for (let i = 0; i < childsNotCertified.length; i++) {
                //se una delle survey è da completare pongo la rispettiva variabile a true per andare a disabilitare il download del certificato
                if (childsNotCertified[i].mandatory == true) {
                  if (data[childsNotCertified[i].referenceId].isSurveyStarted == true) {
                    this.isSurveyStartedLibrary = true;
                  }
                  if (data[childsNotCertified[i].referenceId].isSurveyCertificable == true) {
                    this.isSurveyCertificableLibrary = true;
                  }
                }
              }
            }
            this.isGettingSurveys = false;
          })
            .catch(() => {
              this.isGettingSurveys = false;
            })
        }
        this.isGettingChilds = false;
        //se non è ne una playlist ne un learningPlan vuol dire che è un corso quindi per non ricevere una response=null devo chiamare lo stesso servizio adattato per non controllare gli itemChilds poichè non ne ha
      } else if (!this.adminMode) {
        // Se è un oggetto atomico, abilito la possibilità di verificare l'item
        this.isItemCertifiable = true;
        // Se è un'immagine ed è la prima volta che entro nell'item, i servizi avranno già creato l'engagement quindi vedo se posso abilitare la certificazione
        if (refItem.isImage) {
          this.checkEnableCertificaion();
        };
        this.isGettingSurveys = true;
        let refId = refItem.itemId ? refItem.itemId : this.itemToAddId;
        let surveyPromise = ItemUtil.getSurveyStatusData(this.loggedUser.user.userId, refId, this.takerService, this.toastr, null);
        surveyPromise.then((data: { isSurveyStarted: boolean, isSurveyCertificable: boolean }) => {
          if (data) {
            this.isSurveyStarted = data.isSurveyStarted;
            this.isSurveyCertificable = data.isSurveyCertificable;
          }
          this.isGettingSurveys = false;
        })
          .catch(() => {
            this.isGettingSurveys = false;
          })
      }
      // Scorro gli oggetti aggiunti alla sezione, e se hanno bisogno di un oggetto propedeutico associato verifico se quest'ultimo è stato consumato; in tal caso, l'oggetto risulterà sbloccato
      ItemUtil.checkIfChildsAreNotDisabled(refItem.itemChilds);
      // Recupero il certificato
      this.getCertificationImage();
    }
  }

  // Ritorna lo stato dell'utente relativamente alla survey di un'edizione associata
  getAssessmentOfEditionCompletitionStatus(itemRef) {
    let statusLabel;
    if (itemRef) {
      if (!itemRef.isSurveyPassed) {
        if (!itemRef.isSurveyStarted) {
          // Questionario da iniziare
          statusLabel = this.translate.instant('generic.surveyStatuses.TO_START');
          this.isSurveyCertificable = true;
        } else {
          // Questionario da continuare
          statusLabel = this.translate.instant('generic.surveyStatuses.STARTED');
          this.isSurveyStarted = true;
        }
      } else {
        // Questionario concluso e completato con successo
        statusLabel = this.translate.instant('generic.surveyStatuses.COMPLETED');
      }
    }

    return statusLabel;
  }

  // Ritorna lo stato dell'utente relativamente all'item
  getItemCompletitionStatus() {
    // (Da controllare) Forse isOnlineCourse viene considerato in modo da mostrare presente un corso in presenza non appena viene fatto anche se c'è il questionario
    let itemRef = this.isOnlineCourse && this.itemLinkedToCourse && this.itemLinkedToCourse.itemId ? this.itemLinkedToCourse : this.itemDetails;
    let statusLabel;
    if (itemRef) {
      if (this.isThereSurveyToStart(itemRef)) {
        // Questionario da iniziare
        statusLabel = this.translate.instant('generic.surveyStatuses.TO_START');
      } else if (this.isThereSurveyToContinue(itemRef)) {
        // Questionario da continuare
        statusLabel = this.translate.instant('generic.surveyStatuses.STARTED');
      } else if (itemRef.isCertified && itemRef.certificationDate) {
        let formattedDate = moment(itemRef.certificationDate).format('ll');
        // Questionario concluso e completato con successo
        statusLabel = this.translate.instant('generic.surveyStatuses.COMPLETED') + ' ' + this.translate.instant('generic.THE') + ' ' + formattedDate;
      } else if (this.checkIsItemCompleted()) {
        // L'etichetta 'completato' si vede solo se:
        // ho completato l’item e non prevede la survey
        // ho completato l’item e ho superato la survey associata
        statusLabel = this.translate.instant('generic.COMPLETED')
      } else if (this.isSyllabusCourse) {
        let subscribedEditions = this.getSubscribedEditions();
        if (subscribedEditions && subscribedEditions.length) {
          statusLabel = this.translate.instant('generic.SUBSCRIBED');
        }
      }
    }

    return statusLabel;
  }


  // isConsumed o isCertified (uno dei due) su entrambi gli oggetti deve essere a true perchè l'item sia completo
  // controllo sul taker USER_STATUS_PRESENT sempre su entrambi gli oggetti questo controllo serve per le iniziative dove viene settato manualmente a completato lo stato del taker
  // Se isConsumed del padre è false, ma trovo un suo taker in stato presente, vuol direi che è stato messo a mano. 
  // Dato che non posso controllare il taker del questionario (oggetto collegato) considero l'oggetto completato
  checkIsItemCompleted() {
    if (!this.itemDetails.isConsumed && (this.itemDetails.userEnrolledToEditions && this.itemDetails.userEnrolledToEditions[0]?.userStatus == "USER_STATUS_PRESENT")) {
      return true;
    } else {
      let isItemComplete = this.itemDetails.isConsumed || this.itemDetails.isCertified || (this.itemDetails.userEnrolledToEditions && this.itemDetails.userEnrolledToEditions[0]?.userStatus == "USER_STATUS_PRESENT");
      // Aggiunto il controllo sullo scorm, altrimenti verificava anche casi senza il questionario in modo scorretto, riferito sempre al cambio manuale dello stato
      if (this.itemLinkedToCourse && !this.itemLinkedToCourse.isScorm) {
        let isLinkedItemComplete = this.itemLinkedToCourse.isConsumed || this.itemLinkedToCourse.isCertified;
        return isItemComplete && isLinkedItemComplete;
      } else {
        return isItemComplete;
      }
    }
  }

  // Verifica se ci sono survey da iniziare
  isThereSurveyToStart(itemRef) {
    //bisogna specificare il caso degli oggetti questionario poichè a questionario completato non vi è isCertified = true ma bensì il isConsumed = true quindi fino a completamento isConsumed = false
    if (itemRef.itemType === 'SURVEY_ITEM' || itemRef.itemType === "ASSESSMENT") {
      if (itemRef && !itemRef.isConsumed && this.isSurveyCertificable && !itemRef.isCertified && (this.isItemCertifiable || this.enableCertification)) {
        return true;
      }
      return false;
    } else {
      if (itemRef && itemRef.isConsumed && (this.isSurveyCertificable || this.isSurveyCertificableLibrary) && !itemRef.isCertified && (this.isItemCertifiable || this.enableCertification)) {
        return true;
      }
      return false;
    }
  }
  // Verifica se ci sono survey da continuare
  isThereSurveyToContinue(itemRef) {
    //bisogna specificare il caso degli oggetti questionario poichè a questionario completato non vi è isCertified = true ma bensì il isConsumed = true quindi fino a completamento isConsumed = false
    if (itemRef.itemType === 'SURVEY_ITEM' || itemRef.itemType === "ASSESSMENT") {
      if ((itemRef && !itemRef.isConsumed && (this.isSurveyStarted) && !itemRef.isCertified && this.isItemCertifiable)) {
        return true;
      }
      return false;
    } else {
      if ((itemRef && itemRef.isConsumed && (this.isSurveyStarted || this.isSurveyStartedLibrary) && !itemRef.isCertified && this.isItemCertifiable)) {
        return true;
      }
      return false;
    }
  }

  isSurveySessionTimeCompatible(itemRef: IDataItem): boolean {
    let moduleChild: ItemChild[] = itemRef.itemChilds;
    let isCompatible: boolean = false;

    moduleChild.forEach(child => {

      let courseModuleChild: ItemChild[] = child.childObject.itemChilds;
      courseModuleChild.forEach(child => {
        if (child.childObject.itemType === ItemTypes.COURSE_EDITION) {

          let lastDay = ItemUtil.getAttributeByKey(child.childObject, ItemAttributeTypes.LAST_DAY_DATE);
          let firstDay = ItemUtil.getAttributeByKey(child.childObject, ItemAttributeTypes.FIRST_DAY_DATE);
          if (lastDay && firstDay) {
            let firstDayDate: Date = new Date(firstDay.attributeValue);
            let lastDayDate: Date = new Date(lastDay.attributeValue);
            if (firstDayDate.getTime() <= Date.now() && Date.now() <= lastDayDate.getTime()) {
              isCompatible = true
            }
          }
        }
      });

    });

    return isCompatible;
  }

  // Torna il testo della seconda azione
  getSecondaryActionText() {
    if (this.hasStatusNegatedOnInitiative || (this.showAskPartecipationMessage()) || (this.canPreiscritionOnInitiative || this.canCancelPreiscritionOnInitiative)) {
      return;
    }
    let itemRef = this.isOnlineCourse && this.itemLinkedToCourse && !this.itemLinkedToCourse.isContainerItem ? this.itemLinkedToCourse : this.itemDetails;
    if (itemRef && itemRef.itemType != "SURVEY_ITEM" && itemRef.itemType != "ASSESSMENT") {
      if (!this.isSyllabusCourse || (this.isAssessment && itemRef.isCourseCertificationEnabled)) {
        if (this.isThereSurveyToStart(itemRef) || this.isThereSurveyToContinue(itemRef) || (itemRef.isCertified && itemRef.isContainerItem)) {
          this.isForOpenSurvey = false;
          return this.getMainButtonTextOfItemWithouthSurvey(itemRef, true);
        } else if (itemRef.isCertified && !itemRef.isContainerItem) {
          this.isForOpenSurvey = true;
          return this.translate.instant('generic.surveyStatuses.TO_CHECK');
        }
      } else {
        if (!this.isAssessment && (this.isCertificationsOfEditionsSectionVisible() || (!itemRef.isContainerItem && ((!this.itemDetails.isCourseCertificationEnabled && (this.isThereSurveyToStart(itemRef) || this.isThereSurveyToContinue(itemRef) || itemRef.isCertified)) || (itemRef.isImage && !this.isThereSurveyToStart(itemRef) && !this.isThereSurveyToContinue(itemRef) && !itemRef.isCertified && this.itemDetails.isCourseCertificationEnabled))))) {
          this.isForOpenSurvey = false;
          return this.getMainButtonTextOfItemWithouthSurvey(itemRef, false, true);
          //se il certificato è attivo e non sono in presenza di un containerItem per permettere all'utente di rivedere l'oggetto e la survey lo porto direttamente all'oggetto library
        } else if (!itemRef.isContainerItem && itemRef.isCertified && this.itemDetails.isCourseCertificationEnabled) {
          return this.translate.instant('generic.GO_TO_OBJECT')
        }
      }
    }
  }

  onSecondaryActionClicked() {
    if (this.damPlayerVisible || this.itemDetails.damPlayerVisible || this.itemDetails.scormPlayerVisibile || this.scormPlayerVisibile || (this.itemLinkedToCourse && this.itemLinkedToCourse.damPlayerVisible)) {
      //Apro la Survey qualora sia stata completata
      if (!this.itemDetails.isContainerItem && (this.itemDetails.isCertified && this.isForOpenSurvey) || (this.itemLinkedToCourse && this.itemLinkedToCourse.isCertified && !this.itemDetails.isCourseCertificationEnabled)) {
        this.openSurvey()
      } else if ((this.isOpenDamBtnDisabled && (this.damPlayerVisible || this.itemDetails.damPlayerVisible))) {
        return;
      } else if (this.itemLinkedToCourse && (!this.itemLinkedToCourse.isContainerItem && this.itemLinkedToCourse.isConsumed && this.itemLinkedToCourse.isCertified)) {
        this.router.navigate(['takers/itemDetails', this.wholeIdMap.itemId, this.itemLinkedToCourse.itemId])
      }
      else if (this.isMaterialToDownloadDAM(this.itemLinkedToCourse)) {
        this.downloadMultimediaItemDAM(this.itemLinkedToCourse)
      } else {
        // Apro il box con il player qualora sia un oggetto DAM/SCORM, o sia un corso online con associato un DAM/SCORM
        this.switchPlayerBoxOpened();
      }
    } else if (this.itemDetails && this.isSyllabusCourse && !this.isAssessment && this.isCertificationsOfEditionsSectionVisible()) {
      const isLinkedItemScorm = this.isOnlineCourse && this.itemLinkedToCourse && this.itemLinkedToCourse.scormPlayerVisibile;
      const damPlayerVisible = this.isOnlineCourse && this.itemLinkedToCourse && this.itemLinkedToCourse.damPlayerVisible;
      const linkedItemIsLearningPlan = this.isOnlineCourse && this.itemLinkedToCourse && this.itemLinkedToCourse.isLearningPlan;
      const linkedItemIsProject = this.isOnlineCourse && this.itemLinkedToCourse && this.itemLinkedToCourse.isProject;
      const linkedItemIsBlendedProject = this.isOnlineCourse && this.itemLinkedToCourse && this.itemLinkedToCourse.isBlendedProject;
      if (this.scormPlayerVisibile || isLinkedItemScorm || damPlayerVisible) {
        if ((this.isOpenDamBtnDisabled && damPlayerVisible)) {
          return;
        } else {
          this.switchPlayerBoxOpened();
        }
      } else {
        if (linkedItemIsLearningPlan) {
          // Scrollo fino agli item aggiunti
          this.scrollIntoPage('itemsOfLP');
        } else if (linkedItemIsProject) {
          // Scrollo fino agli item aggiunti
          this.scrollIntoPage('sectionsOfItem');
        } else if (linkedItemIsBlendedProject) {
          // Scrollo fino agli item aggiunti
          this.scrollIntoPage('sectionsOfItem');
        } else {
          // Scrollo fino ai corsi
          this.scrollIntoPage('editionsList');
        }
      }
    } else if (this.isForOpenSurvey && this.itemDetails.isCertified && (!this.itemDetails.isContainerItem || this.isAssessment)) {
      this.openSurvey();
    }
  }

  getMainButtonTextOfItemWithouthSurvey(itemRef, skipContainers?: boolean, isSecondaryAction?: boolean) {
    if (itemRef) {
      if (!isSecondaryAction && this.hasTheUserTheCertificateAssigned && (this.isCertificationsOfEditionsSectionVisible() || (this.isCertificationObtained() && this.isOnlineCourse && ((itemRef.isConsumed && !this.isThereSurveyToStart(itemRef) && !this.isThereSurveyToContinue(itemRef)) || itemRef.isCertified) && !this.isSurveyCertificableLibrary && !this.isSurveyStartedLibrary))) {
        return this.translate.instant('generic.DOWNLOAD_CERTIFICATE');
      } else if ((this.isAssessment || (isSecondaryAction && !itemRef.isContainerItem)) && (!itemRef.itemAttributes || itemRef.isCertified)) {
        //permetto all'utente di rivedere il questionario completato
        return this.translate.instant('generic.surveyStatuses.TO_CHECK');
      }
      if ((this.damPlayerVisible || itemRef.damPlayerVisible) && itemRef.isImage) {
        this.isItemPlayerButton = true;
        // Oggetto di tipo immagine
        return this.translate.instant('generic.OPEN_IMAGE');
      } else if ((this.damPlayerVisible || itemRef.damPlayerVisible) && !itemRef.isImage) {
        // Podcast
        if (itemRef.subtype && itemRef.subtype === ItemAttributeObjectTypes.PODCAST) {
          this.isItemPlayerButton = true;
          return this.translate.instant('generic.OPEN_PODCAST');
          //DOCUMENT/EBOOK/GRAPH
        } else if (this.isMaterialToDownloadDAM(itemRef)) {
          this.isItemPlayerButton = true;
          return this.translate.instant('generic.DOWNLOAD_FILE');
        } else {
          this.isItemPlayerButton = true;
          // Video
          return this.translate.instant('generic.WATCH_VIDEO');
        }
      } else if (!skipContainers && (itemRef.isLearningPlan || itemRef.isProject || itemRef.isBlendedProject) && (this.areProjectChildsVisible() || this.areLearningPlanChildsVisible())) {
        // Progetto o playlist
        return this.translate.instant('generic.CHOOSE_COURSE');
      } else if (this.isSyllabusCourse) {
        if (!this.isAssessment) {
          if (!isSecondaryAction && this.isCertificationsOfEditionsSectionVisible()) {
            return this.translate.instant('generic.DOWNLOAD_CERTIFICATE');
          }
          if (this.scormPlayerVisibile || itemRef.scormPlayerVisibile) {
            this.isItemPlayerButton = true;
            // Scorm
            return this.translate.instant('generic.OPEN_COURSE');
          }
          const linkedItemIsLearningPlan = this.isOnlineCourse && this.itemLinkedToCourse && this.itemLinkedToCourse.isLearningPlan;
          const linkedItemIsProject = this.isOnlineCourse && this.itemLinkedToCourse && this.itemLinkedToCourse.isProject;
          const linkedItemIsBlendedProject = this.isOnlineCourse && this.itemLinkedToCourse && this.itemLinkedToCourse.isBlendedProject;
          let subscribedEditions = this.getSubscribedEditions();
          if (subscribedEditions && subscribedEditions.length) {
            const isLinkedItemScorm = this.isOnlineCourse && this.itemLinkedToCourse && this.itemLinkedToCourse.scormPlayerVisibile;
            const damPlayerVisible = this.isOnlineCourse && this.itemLinkedToCourse && this.itemLinkedToCourse.damPlayerVisible;
            const isImage = this.isOnlineCourse && this.itemLinkedToCourse && this.itemLinkedToCourse.isImage;
            const linkedItemSubType = this.isOnlineCourse && this.itemLinkedToCourse && this.itemLinkedToCourse.subtype;
            if (isSecondaryAction) {
              if (this.scormPlayerVisibile || itemRef.scormPlayerVisibile || isLinkedItemScorm) {
                this.isItemPlayerButton = true;
                return this.translate.instant('generic.OPEN_COURSE');
              } else if (damPlayerVisible) {
                if (isImage) {
                  this.isItemPlayerButton = true;
                  return this.translate.instant('generic.OPEN_IMAGE');
                } else {
                  // Podcast
                  if (linkedItemSubType && linkedItemSubType === ItemAttributeObjectTypes.PODCAST) {
                    this.isItemPlayerButton = true;
                    return this.translate.instant('generic.OPEN_PODCAST');
                  } else {
                    this.isItemPlayerButton = true;
                    // Video
                    return this.translate.instant('generic.WATCH_VIDEO');
                  }
                }
              }
            } else {
              return this.translate.instant('generic.VIEW_EDITIONS');
            }
          }
          // Corso (syllabus, iniziativa)
          // Se è un corso esterno online o un evento, non ho la possibilità di iscrivermi ai corsi
          if (this.isExternalCourseOnline || this.isExternalEventOnline) {
            return this.translate.instant('generic.VIEW_EDITIONS');
          } else {
            if (isSecondaryAction && !skipContainers && (linkedItemIsLearningPlan || linkedItemIsProject || linkedItemIsBlendedProject)) {
              // Progetto o playlist
              return this.translate.instant('generic.CHOOSE_COURSE');
            } else {
              // editions array contains only edition that are not passed.
              // If there are no editions, the course is passed and the use can't choose an edition to subscribe
              if (this.editions && this.editions.length === 0) {
                return this.translate.instant('generic.REGISTRATIONS_END');
              }
              return this.translate.instant('generic.CHOOSE_EDITION');
            }
          }
        } else {
          // Se sono nel dettaglio di un assessment (questionario di una edizione) devo dare la possibilità all'utente di compilare il questionario
          if (!this.adminMode && this.isThereSurveyToStart(itemRef) && !this.isSurveyCertificableLibrary) {
            return this.translate.instant('survey.GO_TO_SURVEY');
          }
        }
      } else if (this.scormPlayerVisibile || itemRef.scormPlayerVisibile) {
        // Scorm
        return this.translate.instant('generic.OPEN_COURSE');
      } else if (itemRef.itemType == ItemTypes.EXTERNAL_ITEM) {
        // Scorm
        return this.translate.instant('generic.GO_TO_CONTENT');
      }
    }
  }

  // Torna il testo del pulsante con l'azione principale
  getMainButtonText() {
    let itemRef = this.isOnlineCourse && this.itemLinkedToCourse && this.itemLinkedToCourse.itemId ? this.itemLinkedToCourse : this.itemDetails;
    let presubEnabled = itemRef?.itemAttributes?.find((attr: any) => attr.attributeType == ItemAttributeTypes.PRE_REGISTRATION_ENABLED && attr.attributeValue == "true");
    // controllo se è abilitata la preiscrizione
    if (!this.isCurrentlyInvitedOrApprovedInInitiativeOrEdition && presubEnabled && presubEnabled.attributeId) {
      let preEndDate = itemRef?.itemAttributes.find((attr: any) => attr.attributeType == ItemAttributeTypes.PRE_REGISTRATION_END_DATE);
      // se può preiscriversi ma la data di fine preiscrizioni è scaduta, non mostro il pulsante
      if (preEndDate && moment().isAfter(moment(preEndDate.attributeValue))) {
        return false;
      }
    }
    if (this.hasStatusNegatedOnInitiative) {
      return false;
    } else if (this.showNoAuthToOperateMessage() && !this.isCurrentlyInvitedOrApprovedInInitiativeOrEdition && !this.canPreiscritionOnEditions) {
      return false;
    } else if (this.canPreiscritionOnInitiative && !this.isCurrentlyInvitedOrApprovedInInitiativeOrEdition) {
      return this.translate.instant('generic.PREREGISTER');
    } else if (this.canCancelPreiscritionOnInitiative && !this.isCurrentlyInvitedOrApprovedInInitiativeOrEdition && !this.isAtomicItem) {
      return this.translate.instant('generic.DELETE_PREREGISTER');
    } else if (this.showAskPartecipationMessage()) {
      if (this.alreadyAskedForCatalog) {
        return this.translate.instant('generic.PARTECIPATION_REQUESTED');
      } else {
        return this.translate.instant('generic.REQUEST_PARTECIPATION');
      }
    }
    if (itemRef) {
      // Prima verifico se ho qualche azione riguardante le survey o il certificato, dato che sono le più importanti
      if (this.isThereSurveyToStart(itemRef) && !this.isSurveyCertificableLibrary && this.isSurveyTimeRangeCompatible) {
        // Survey da iniziare
        return this.translate.instant('survey.GO_TO_SURVEY');
      } else if (this.isThereSurveyToContinue(itemRef) && !this.isSurveyStartedLibrary && this.isSurveyTimeRangeCompatible) {
        // Survey iniziata, e da continuare
        return this.translate.instant('itemDetail.CONTINUE_CERTIFICATION');
      }
      else if ((this.isCertificationSectionVisible() || this.isCertificationsOfEditionsSectionVisible()) && itemRef.isCertified) {
        // Survey completata
        return this.translate.instant('generic.DOWNLOAD_CERTIFICATE');
      }
      else if (itemRef.isCertified && (itemRef.itemType === "SURVEY_ITEM" || itemRef.itemType === "ASSESSMENT")) {
        // Quando è completata la Survey di un oggetto library di tipo "questionario" do la possibilità all'utente di rivedere il questionario
        return this.translate.instant('generic.surveyStatuses.TO_CHECK')
      } else {
        return this.getMainButtonTextOfItemWithouthSurvey(itemRef);
      }
    }
  }

  isTodayInAnyEditionForObjects() {
    let tmp = false;
    let today = new Date();
    // se vedo l'oggetto in library allora posso sempre fruirne
    if (window.location.href.indexOf('/library/') >= 0) {
      tmp = true;
    } else if (window.location.href.indexOf('/library/') < 0 && this.editions?.length) {
      // altrimenti se sto vedendo l'oggetto all'interno di un'iniziativa, dev'essere fruibile solo se sono dentro ad un'edizione valida
      // Controllato con disableLpItemsForInvalidStatus, che fa un check sullo stato dell'utente nell'iniziativa
      for (let i = 0; i < this.editions.length; i++) {
        if (moment(today).isSameOrAfter(this.editions[i].startDate) && moment(today).isSameOrBefore(this.editions[i].endDate)) {
          tmp = true;
        }
      }
    }
    return tmp;
  }

  isMainButtonDisabled() {
    let itemTypeWithObjects = [ItemTypes.BLENDED_STAGE, ItemTypes.COURSE_ONLINE_STAGE]
    if ((itemTypeWithObjects.includes(this.itemDetails.itemType) && !this.isTodayInAnyEditionForObjects())
      || (this.isItemPlayerButton && this.singleItemWithoutStageDamOpenBtnDisabled)) {
      return true;
    }
    return false;
  }

  onMainButtonClicked(data?) {
    if (this.router.url.includes("itemDetails") || this.router.url.includes("itemDetailSec")) {
      // ID del genitore (progetto o LP) recuperato dai parametri dell'URL.
      const parentId = this.route.snapshot.paramMap.get("projectId") || this.route.snapshot.paramMap.get("lpId");

      this.isLoadingEditions = true;
      this.itemDetailsForPlanTracker$ = this.getItemDetailsForPlanTracker(parentId);
      this.isLoadingEditions = false;
    }

    let itemRef = this.isOnlineCourse && this.itemLinkedToCourse && this.itemLinkedToCourse.itemId ? this.itemLinkedToCourse : this.itemDetails;
    let isDamOrScorm = this.damPlayerVisible || itemRef.damPlayerVisible || this.scormPlayerVisibile || itemRef.scormPlayerVisibile;
    if (this.isCurrentlyInvitedOrApprovedInInitiativeOrEdition && !isDamOrScorm) {
      this.scrollIntoPage('editionsList');
    } else if (this.canPreiscritionOnInitiative) {
      this.openConfirmPreRegistrationModal();
      return;
    } else if (!isDamOrScorm && this.canCancelPreiscritionOnInitiative) {
      let presubscribedEdition = this.editions.filter((ed: any) => ed.userStatus == ItemTakerEnrollStatusTypes.USER_STATUS_PREREGISTERED);
      // Se ho più di un'edizione a cui sono preiscritto faccio lo scroll sulla lista delle edizioni
      if (presubscribedEdition.length > 1) {
        this.scrollIntoPage('editionsList');
      } else if (presubscribedEdition && presubscribedEdition[0]) {
        // Se ho una sola edizione apro la modale di cancellazione su quella
        this.openCancelSubscriptionModal(presubscribedEdition[0]);
      } else {
        // Altrimenti apro la modale di cancellazione dalla preiscrizione sull'iniziativa
        this.openCancelSubscriptionModal(this.itemDetails);
      }
      return;
    } else if (this.canRequestFromCatalog && this.canAccessUserCatalog && this.canAskCatalogAsSimpleMail) {
      if (!this.alreadyAskedForCatalog) {
        this.itemToRequstCatalogPartecipation = this.itemDetails;
        this.openConfirmCatalogRequestModal();
      }
      return;
    }

    this.externalObjId = this.itemLinkedToCourse && this.itemLinkedToCourse.itemId;
    // Prima verifico se ho qualche azione riguardante le survey o il certificato, dato che sono le più importanti
    if (((itemRef.itemType === "SURVEY_ITEM" || itemRef.itemType === "ASSESSMENT")) || (itemRef.isConsumed && !itemRef.isContainerItem && (this.isThereSurveyToStart(itemRef) || this.isThereSurveyToContinue(itemRef)))) {
      this.createItemTakerEnrollByExternalObjId();
      this.openSurvey();
    } else if ((!itemRef.isContainerItem && this.itemDetails.isCourseCertificationEnabled && (itemRef.isCertified || (itemRef.isImage && itemRef.isConsumed)))
      || (this.itemDetails.isCourseCertificationEnabled && (itemRef.isCertified || (itemRef.isConsumed && (!this.isThereSurveyToStart(itemRef) && !this.isSurveyCertificableLibrary && !this.isThereSurveyToContinue(itemRef) && !this.isSurveyStartedLibrary))))) {
      // Scrollo fino alla sezione del certificato
      this.scrollIntoPage('certification');
    } else if (this.isCertificationsOfEditionsSectionVisible()) {
      // Scrollo fino alla sezione del certificato
      this.scrollIntoPage('certifications');
    } else if (this.damPlayerVisible || itemRef.damPlayerVisible || this.scormPlayerVisibile || itemRef.scormPlayerVisibile) {
      if ((this.isOpenDamBtnDisabled && (this.damPlayerVisible || itemRef.damPlayerVisible))) {
        return;
      } else if (this.isMaterialToDownloadDAM(itemRef)) {
        this.createItemTakerEnrollByExternalObjId();
        this.downloadMultimediaItemDAM(itemRef)
      } else {
        // Nel caso sia un'immagine, devo chiamare il servizio per fare il "consumed"
        if (itemRef.isImage) {
          this.consumeLibraryItemByItemId(this.itemId);
        }
        // Apro il box con il player qualora sia un oggetto DAM/SCORM, o sia un corso online con associato un DAM/SCORM
        this.createItemTakerEnrollByExternalObjId();
        this.switchPlayerBoxOpened();
      }
    } else if (itemRef.isLearningPlan) {
      // Scrollo fino agli item aggiunti
      this.scrollIntoPage('itemsOfLP');
    } else if (itemRef.isProject) {
      // Scrollo fino agli item aggiunti
      this.scrollIntoPage('sectionsOfItem');
    } else if (itemRef.isBlendedProject) {
      // Scrollo fino agli item aggiunti
      this.scrollIntoPage('sectionsOfItem');
    } else if (this.isSyllabusCourse) {
      if (!this.isAssessment) {
        // Scrollo fino ai corsi
        this.scrollIntoPage('editionsList');
      } else if (this.isCertificationSectionVisible() && itemRef.isCertified) {
        this.scrollIntoPage('certification');
      } else {
        // Se sono nel dettaglio di un assessment (questionario di una edizione) devo dare la possibilità all'utente di compilare il questionario
        this.createItemTakerEnrollByExternalObjId();
        this.openSurvey();
      }
    } else if (itemRef.itemType == ItemTypes.EXTERNAL_ITEM) {
      let link = this.linkUrls && this.linkUrls.length && this.linkUrls[0];
      // Lo segno come completato
      this.consumeLibraryItemByItemId(this.itemId);
      this.openLink(link);
    }
  }

  consumeLibraryItemByItemId(itemId: string) {
    this.itemService.consumeLibraryItemByItemId(itemId)
      .subscribe(data => {
        // Se ci sono errori
        if (data.error) {
          this.toastr.error(this.translate.instant('errors.' + data.error));
        } else {
          if (this.itemDetails) {
            this.itemDetails.completionPercentage = '100%';
          } else if (this.itemLinkedToCourse) {
            this.itemLinkedToCourse.completionPercentage = '100%';
          }
        }
      })
  }

  openLink(link: string) {
    // Se è un external item, completo l'oggetto
    if (this.itemType == ItemTypes.EXTERNAL_ITEM) {
      this.consumeLibraryItemByItemId(this.itemId);
    }
    window.open(link);
  }

  copyLinkToClipboard(link) {
    copyToClipboard(link);
    this.toastr.success(this.translate.instant(this.translate.instant('infos.TEXT_COPIED_TO_CLIPBOARD')));
    if (this.itemType == ItemTypes.EXTERNAL_ITEM) {
      this.consumeLibraryItemByItemId(this.itemId);
    }
  }


  goToCourseSurvey(edition) {
    const isInLibrary = this.router.url.indexOf('library') !== -1;
    // const isInTrainingBooklet = this.router.url.indexOf('trainingBooklet') !== -1;
    const isInCatalog = this.router.url.indexOf('catalog') !== -1;
    const isInTrainingBooklet = this.router.url.indexOf('trainingBooklet') !== -1;

    let baseUrl;
    if (isInLibrary) {
      baseUrl = 'takers/library/';
    } else if (isInTrainingBooklet) {
      baseUrl = 'takers/trainingBooklet/';
    } else if (isInCatalog) {
      baseUrl = 'takers/catalog/';
    } else {
      baseUrl = 'takers/';
    }
    this.router.navigate([baseUrl + 'itemDetails', edition.stageItemId]);
  }

  switchPlayerBoxOpened() {
    this.isPlayerBoxOpened = !this.isPlayerBoxOpened;

    if (this.router.url.includes("itemDetails") || this.router.url.includes("itemDetailSec")) {
      // ID del genitore (progetto o LP) recuperato dai parametri dell'URL.
      const parentId = this.route.snapshot.paramMap.get("projectId") || this.route.snapshot.paramMap.get("lpId");

      this.isLoadingEditions = true;
      this.itemDetailsForPlanTracker$ = this.getItemDetailsForPlanTracker(parentId);
      this.isLoadingEditions = false;

      this.getItemDetails();
    }
  }

  // Rimuove un like/dislike
  deleteLike() {
    // Avvio il loader della sezione like/unlike
    this.isGettingLikes = true;
    this.deleteLike$ = this.itemService.deleteLike(this.currentLike.likeId, CommonConstants.APPLICATION_CORPORATE_ACADEMY)
      .subscribe(data => {
        // Se ci sono errori, li mostor e torno alla lista dei template
        if (data.error) {
          this.isGettingLikes = false;
          this.toastr.error(this.translate.instant('errors.' + data.error));
        } else {
          // Pulisco il like oramai cancellato
          this.currentLike = null;

          // Salvo il like tolto
          this.iLikeThis = false;

          // Aggiorno le informazioni sul conteggio totale
          this.getLikes();
        }
      })
  }

  // Click aggiunge/rimuove un like
  like() {
    if (this.isGettingLikes) {
      return;
    }
    if (this.iLikeThis) {
      // Se c'è il pulsante di like attivo, significa che voglio rimuovere il like
      this.deleteLike();
    } else {
      // Altrimenti, aggiungo semplicemente il like
      this.createLike();
    }

    return;
  }

  // Gestisce la progressione di Azure
  itemInProgress(data: { currentTime: number, totalTime: number }) {
    if (!this.adminMode && data.currentTime && data.totalTime && data.currentTime < data.totalTime) {
      // Dato che box di azure può venire chiuso/aperto più volte e la posizione da cui partire viene recuperata solo con l'inizializzazione dell'item, devo aggiornare il valore manualmente
      this.damPlayerSeekTo = data.currentTime;
      if (this.itemLinkedToCourse) {
        this.itemLinkedToCourse.damPlayerSeekTo = data.currentTime;
      }

      if (this.isOnlineCourse && this.itemLinkedToCourse) {
        this.itemLinkedToCourse.completionPercentage = ((Math.floor(data.currentTime * 100 / data.totalTime)).toString()) + '%';
      } else {
        this.itemDetails.completionPercentage = ((Math.floor(data.currentTime * 100 / data.totalTime)).toString()) + '%';
      }
      this.ref.detectChanges();
    }
  }

  // Gestisce la conclusione degli item di Azure
  itemConsumed(isConsumed: boolean) {
    if (isConsumed) {
      if (this.isOnlineCourse && this.itemLinkedToCourse) {
        this.itemLinkedToCourse.completionPercentage = '100%';
        this.itemLinkedToCourse.isConsumed = true;
        if (!this.adminMode && this.isSurveyCertificable && !this.itemLinkedToCourse.isCertified) {
          this.enableCertification = true;
        }
      } else {
        this.itemDetails.completionPercentage = '100%';
        this.itemDetails.isConsumed = true;
        if (!this.adminMode && this.isSurveyCertificable && !this.itemDetails.isCertified) {
          this.enableCertification = true;
        }
      }
      this.ref.detectChanges();
    }
  }

  // Recupera le informazioni per costruire i breadcrumb qualora stessi aprendo un oggetto aggiunto a una sezione
  private getBreadcrumbsOfItemInSection(isEditing?: boolean, wasSearchingAnItem?: boolean) {
    // Avvio il loader
    this.isGettingBreadcrumbs = true;

    // In base ai parametri che ho nell'url, setto gli ID del progetto e della sezione
    let projectId = this.projectId ? this.projectId : this.editingProjectId;
    let sectionId = this.sectionId ? this.sectionId : this.editingSectionId;
    let baseUrl = null;
    if (this.adminMode) {
      if (this.isItemsAdminPreview) {
        baseUrl = 'itemsAdmin/';
      } else if (this.isInitiativesAdminPreview || this.isInitiativesAdminFromHomePreview) {
        baseUrl = 'initiatives/';
      }
    } else {
      if (this.isInLibrary) {
        baseUrl = 'takers/library/';
      } else if (this.isInTrainingBooklet) {
        baseUrl = 'takers/trainingBooklet/';
      } else if (this.isInCatalog) {
        baseUrl = 'takers/catalog/';
      } else {
        baseUrl = 'takers/';
      }
    }

    this.itemService.getItemTitle(projectId)
      .subscribe(projectDetails => {
        // Se ci sono errori, li mostor e torno alla lista dei template
        if (projectDetails.error) {
          // Spengo il loader
          this.isGettingBreadcrumbs = false;
          this.toastr.error(this.translate.instant('errors.' + projectDetails.error));
        } else {
          if (projectDetails.response) {
            // Chiamo il servizio per recuperare le informazioni della sezione
            this.itemService.getItemTitle(sectionId)
              .subscribe(sectionDetails => {
                if (sectionDetails.error) {
                  // Spengo il loader
                  this.isGettingBreadcrumbs = false;
                  this.toastr.error(this.translate.instant('errors.' + projectDetails.error));
                } else {
                  // I link dei breadcrumb devono cambiare il relazione al fatto se ero in editing della sezione/progetto
                  if (isEditing) {
                    // Progetto
                    let projectBreadcrumb = {
                      label: projectDetails.response,
                      redirectPage: "/itemsAdmin/editLearningPlan/" + this.editingProjectId
                    };

                    // Sezione del progetto
                    let sectionBaseUrl = '';
                    if (this.isEditingBlendedSection) {
                      sectionBaseUrl = "/itemsAdmin/editSection/";
                    } else {
                      sectionBaseUrl = "/itemsAdmin/editBlendedSection/";
                    }
                    let sectionBreadcrumb = {
                      label: sectionDetails.response,
                      redirectPage: sectionBaseUrl + this.editingProjectId + "/" + this.editingSectionId
                    };

                    // Oggetto associato alla sezione
                    let itemBreadcrumb = {
                      label: (this.syllabusTitle || this.itemDetails.name),
                      redirectPage: baseUrl + "itemDetails/" + this.itemDetails.itemId,
                      isActive: true
                    };

                    let searchingPage: any = null;
                    // Se l'utente stava cercando un elemento, aggiungo nel breadcrumb anche la pagina di ricerca
                    if (wasSearchingAnItem) {
                      searchingPage = {
                        label: this.translate.instant("generic.SEARCH"),
                        redirectPage: "/itemsAdmin/addItemToCurrentSection/" + this.editingProjectId + "/" + this.editingSectionId
                      }
                    }

                    // e li aggiungo al contenitore dei Breadcrumb
                    this.breadcrumbs.push(projectBreadcrumb);
                    this.breadcrumbs.push(sectionBreadcrumb);
                    if (searchingPage) {
                      this.breadcrumbs.push(searchingPage);
                    }
                    this.breadcrumbs.push(itemBreadcrumb);
                  } else {
                    // Progetto
                    let projectBreadcrumb = {
                      label: projectDetails.response,
                      redirectPage: baseUrl + "itemDetails/" + this.projectId
                    };

                    // Sezione del progetto
                    let sectionBreadcrumb = {
                      label: sectionDetails.response,
                      redirectPage: baseUrl + "itemDetailSec/" + this.projectId + "/" + sectionId + "/" + this.itemDetails.itemId,
                      isActive: true
                    };

                    // Oggetto associato alla sezione
                    let itemBreadcrumb = {
                      label: this.syllabusTitle || this.itemDetails.name,
                      redirectPage: baseUrl + "itemDetailSec/" + this.projectId + "/" + sectionId + "/" + this.itemDetails.itemId,
                      isActive: true
                    };

                    // e li aggiungo al contenitore dei Breadcrumb
                    this.breadcrumbs.push(projectBreadcrumb);
                    this.breadcrumbs.push(sectionBreadcrumb);
                    this.breadcrumbs.push(itemBreadcrumb);

                    this.getCourseEndDateOfWrappedContainer(this.projectId);
                  }
                }
                // Spengo il loader
                this.isGettingBreadcrumbs = false;
              })
          } else {
            // Spengo il loader
            this.isGettingBreadcrumbs = false;
            // Torno in home page
            this.onCancel();
          }
        }
      });
  }

  // Torna la data di scadenza del corso online che wrappa l'oggetto contenitore
  getCourseEndDateOfWrappedContainer(id: string) {
    if (id) {
      this.isLoadingEditionsOfWrappedContainer = true;
      // Potrebbe essere che il progetto sia wrappato da un corso online (CM) quindi nelle breadcrumb si vedrà il titolo del corso; recupero pertanto le info su quest'ultimo per mostrare i dati di neuromarketing come la scandeza
      this.itemService.getConsumableItemByIdForUser(this.translate, id, false, null, null, false, (this.isItemsAdminPreview || this.isInitiativesAdminPreview || this.isInitiativesAdminFromHomePreview))
        .subscribe(projectDetailsData => {
          const syllabus = projectDetailsData && projectDetailsData.extendedItem;
          if (syllabus && syllabus.itemId && projectDetailsData && projectDetailsData.itemId && projectDetailsData.itemType &&
            (projectDetailsData.itemType === ItemTypes.COURSE_ONLINE_STAGE || projectDetailsData.itemType === ItemTypes.BLENDED_STAGE)) {
            this.itemService.getCourseEditions(syllabus.itemId, this.itemId, true, true)
              .subscribe((editionsOfOnlineCourse) => {
                if (editionsOfOnlineCourse && editionsOfOnlineCourse.response && editionsOfOnlineCourse.response.length) {
                  this.editionsOfWrappedContainer = editionsOfOnlineCourse.response;
                  if (editionsOfOnlineCourse.response[0].userStatus && (editionsOfOnlineCourse.response[0].userStatus === ItemTakerEnrollStatusTypes.USER_STATUS_INVITED || editionsOfOnlineCourse.response[0].userStatus === ItemTakerEnrollStatusTypes.USER_STATUS_CONFIRMED)) {
                    if (!editionsOfOnlineCourse.response[0].stopDate && editionsOfOnlineCourse.response[0].endDate) {
                      this.courseEndDate = new Date(editionsOfOnlineCourse.response[0].endDate).toLocaleString([], { year: 'numeric', month: 'short', day: 'numeric', hour: '2-digit', minute: '2-digit' }).replace(',', '');
                    } else if (editionsOfOnlineCourse.response[0].stopDate) {
                      this.courseEndDate = new Date(editionsOfOnlineCourse.response[0].stopDate).toLocaleString([], { year: 'numeric', month: 'short', day: 'numeric', hour: '2-digit', minute: '2-digit' }).replace(',', '');
                    }
                  }
                }
                this.isLoadingEditionsOfWrappedContainer = false;
              }, catchError((err, caught) => {
                if (err && err.message) {
                  // Vedo se c'è la traduzione dell'errore
                  this.toastr.error(this.translate.instant('errors.' + err.message));
                }
                this.isLoadingEditionsOfWrappedContainer = false;
                // Torniamo l'Observable di errore, affinché si possa ri-provare l'operazione
                return err;
              })
              );
          } else {
            this.isLoadingEditionsOfWrappedContainer = false;
          }
        }
          , catchError((err, caught) => {
            if (err && err.message) {
              // Vedo se c'è la traduzione dell'errore
              this.toastr.error(this.translate.instant('errors.' + err.message));
            }
            this.isLoadingEditionsOfWrappedContainer = false;
            // Torniamo l'Observable di errore, affinché si possa ri-provare l'operazione
            return err;
          })
        )
    }
  }

  showCompletionPercentage() {
    if ((this.itemDetails && this.itemDetails.itemId && this.itemDetails.completionPercentage) || (this.itemLinkedToCourse && this.itemLinkedToCourse.completionPercentage)) {
      return true;
    }

    return false;
  }

  // Gestisce il click sulle breadcrumb
  onBreadcrumbClick(breadcrumb) {
    if (breadcrumb) {
      if (breadcrumb.isActive) {
        return;
      } else {
        this.router.navigateByUrl(breadcrumb.redirectPage);
      }
    }
  }

  // Scarica un allegato
  getAttachmentUrl(attachment) {
    // Avvio il loader
    this.attachmentUrl = null;
    this.isDownloadingExternalResource = true;

    let attachmentId: string = null;
    let forcedItemIdEngagement: string = null;
    let isItemOtherType: boolean = ItemUtil.isOtherTypeItem(this.itemType);
    // Recuper l'id dell'allegato, che si trova come 'EXTERNAL_OBJECT_ID' fra i suoi attributi
    for (let i = 0; i < attachment.childObject.itemAttributes.length; i++) {
      if (attachment.childObject.itemAttributes[i].attributeType === ItemAttributeTypes.EXTERNAL_OBJECT_ID) {
        attachmentId = attachment.childObject.itemAttributes[i].attributeValue;
        forcedItemIdEngagement = attachment.childObject.itemAttributes[i].itemId;
        break;
      }
    }

    // Se non sono amministratore, in qualsiasi caso devo generare l'evento consumed quando si scarica un allegato
    let forceCreateConsumedEngagement: boolean = false;
    if (!this.adminMode) {
      forceCreateConsumedEngagement = true;
    }

    if (isItemOtherType || forcedItemIdEngagement) {
      forceCreateConsumedEngagement = null;
      forcedItemIdEngagement = null;
    }

    let getAttachmentUrlPromise = ItemUtil.getAttachmentUrl(attachmentId, this.adminMode, this.itemDetails, forcedItemIdEngagement, forceCreateConsumedEngagement, isItemOtherType, this.itemService, this.toastr);
    getAttachmentUrlPromise.then((response: string) => {
      // Salvo l'url
      this.attachmentUrl = response;
      const regex = /^.*\.(png|jpg|jpeg|pptx|ppsx|ppt|doc|docx|pdf|xls|xlsx|csv|epub)$/gm;
      const ext = this.attachmentUrl.slice(this.attachmentUrl.lastIndexOf('.'), this.attachmentUrl.indexOf('?')).toLowerCase();
      const extFinal = ext.match(regex) ? ext : ''

      // E concludo recuperando il file
      let downloadUrl = ItemUtil.getExternalResourceName(this.attachmentUrl, (attachment.childObject.title || attachment.childObject.name) + extFinal, this.applicationData);
      this.isDownloadingExternalResource = false;
      setTimeout(() => {
        // Scarico il file
        window.location.assign(downloadUrl);

        // Abilito la certificazione
        this.checkEnableCertificaion();
      }, 500)
    })
      .catch(() => {
        this.isDownloadingExternalResource = false;
      })
  }

  // Apre una modale che indica quale elemneto è propedeutico per aprire quello selezionato
  showPropedeuticalItemToThisChild(objs) {
    this.propedeuticalObject = null;
    this.propedeuticalObject = ItemUtil.getPropedeuticalItemToThisChild(objs.selectedItem, objs.item);

    // Apro la modale
    this.modalService.open('showPropedeuticalToThis');
    document.getElementById('showPropedeuticalToThis').focus();
  }

  // Apre una modale che indica quale sezione è propedeutica per aprire quello selezionata
  showPropedeuticalItemToThis(selectedItem) {
    let itemRef = this.isOnlineCourse && this.itemLinkedToCourse && this.itemLinkedToCourse.itemId ? this.itemLinkedToCourse : this.itemDetails;
    this.propedeuticalObject = null;
    this.propedeuticalObject = ItemUtil.getPropedeuticalItemToThis(selectedItem, itemRef.itemChilds);

    // Apro la modale
    this.modalService.open('showPropedeuticalToThis');
    document.getElementById('showPropedeuticalToThis').focus();
  }

  // Chiude la modale di propedeuticità
  closePropedeuticalModal() {
    this.propedeuticalObject = null;
    this.modalService.close('showPropedeuticalToThis');
  }

  // Apre la modale che indica quale elemneto è propedeutico per aprire quello selezionato
  openShowPropedeuticalItemToThisModal(selectedItem: ItemChild) {
    let itemRef = this.isOnlineCourse && this.itemLinkedToCourse && this.itemLinkedToCourse.itemId ? this.itemLinkedToCourse : this.itemDetails;
    this.showPropedeuticalItemToThisModal.selectedItem = selectedItem;
    // Cerco il primo oggetto propedeutico
    this.showPropedeuticalItemToThisModal.propedeuticalObject = ItemUtil.getFirstPropedeuticalItem(itemRef, selectedItem);
    // Se il mio item propedeutico possiede un altro item propedeutico, lo cerco
    if (this.showPropedeuticalItemToThisModal.propedeuticalObject && this.showPropedeuticalItemToThisModal.propedeuticalObject.propedeuticReferenceId) {
      ItemUtil.searchPropedeuticalObject(itemRef, this.showPropedeuticalItemToThisModal.propedeuticalObject);
    }

    // Apro la modale
    this.modalService.open('showPropedeuticalItemToThisModal');
    document.getElementById('showPropedeuticalItemToThisModal').focus();
  }

  // Chiude la modale che indica quale elemneto è propedeutico per aprire quello selezionato
  closeShowPropedeuticalItemToThisModal() {
    // Apro la modale
    this.modalService.close('showPropedeuticalItemToThisModal');
  }

  // Verifica se abilitare la certificazione
  checkEnableCertificaion() {
    if (!this.adminMode) {
      this.isGettingItemDetails = false;
      let isForLinkedItem = this.itemLinkedToCourse && this.isOnlineCourse ? true : false;
      // Metto un piccolo ritardo per consentire all'engagement di crearsi
      setTimeout(() => {
        let id = isForLinkedItem ? (this.itemLinkedToCourse.itemId) : (this.itemId || this.itemToAddId);
        this.itemService.getConsumableItemByIdForUser(this.translate, id)
          .subscribe(data => {
            if (isForLinkedItem) {
              this.itemLinkedToCourse.isConsumed = data.isConsumed;
              this.itemLinkedToCourse.isCertified = data.isCertified;
              this.itemLinkedToCourse.completionPercentage = data.completionPercentage;
            } else {
              this.itemDetails.isConsumed = data.isConsumed;
              this.itemDetails.isCertified = data.isCertified;
              this.certificationDate = data.certificationDate;
              this.itemDetails.completionPercentage = data.completionPercentage;
            }

            // Se non ho una certificazione eseguita la cerco
            if (!this.itemDetails.isCertified) {
              this.isGettingSurveys = true;
              let refId = isForLinkedItem ? (this.itemLinkedToCourse.itemId) : (this.itemId ? this.itemId : this.itemToAddId);
              let surveyPromise = ItemUtil.getSurveyStatusData(this.loggedUser.user.userId, refId, this.surveyService, this.toastr);
              surveyPromise.then((data: { isSurveyStarted: boolean, isSurveyCertificable: boolean }) => {
                if (data) {
                  this.isSurveyStarted = data.isSurveyStarted;
                  this.isSurveyCertificable = data.isSurveyCertificable;
                }
                this.isGettingSurveys = false;
              })
                .catch(() => {
                  this.isGettingSurveys = false;
                })
            } else if (!this.adminMode) {
              // Recupero il certificato
              this.getCertificationImage();
            };

            // Se l'elemento è certificabile e non è già stata conclusa nè iniziata alcuna certificazione, abilito la possibilità di iniziarne una nuova
            if (isForLinkedItem) {
              if (!this.adminMode && this.itemLinkedToCourse.isConsumed && this.isSurveyCertificable && !this.itemLinkedToCourse.isCertified) {
                this.enableCertification = true;
              }
            } else {
              if (!this.adminMode && this.itemDetails.isConsumed && this.isSurveyCertificable && !this.itemDetails.isCertified) {
                this.enableCertification = true;
              }
            }
            this.isGettingItemDetails = false;
          })
        // metto un block-ui perchè la scrittura dell'engagement potrebbe richiedere anche 2 secondi
      }, 1000);
    }
  }

  onCancel() {
    if (this.isInLibrary || this.isInCatalog || this.isInTrainingBooklet) {
      this.goBack();
    } else {
      this.router.navigate(['../'], { relativeTo: this.route });
    }
  }
  findPos(obj) {
    var curtop = 0;
    if (obj.offsetParent) {
      do {
        curtop += obj.offsetTop;
      } while (obj = obj.offsetParent);
      return [curtop];
    }
  }
  scrollIntoPage(elementId) {
    setTimeout(() => {
      const element = document.getElementById(elementId);
      if (element) {
        let elementPosition = element.offsetTop;
        let scrollDistance = elementPosition - 125;
        let appInitiatives = document.getElementsByClassName("ng-sidebar__content") && document.getElementsByClassName("ng-sidebar__content")[0];
        if (appInitiatives) {
          if (this.isUsingInternetExplorer) {
            appInitiatives.scrollTop = scrollDistance;
          } else {
            appInitiatives.scrollTo({
              top: scrollDistance,
              behavior: 'smooth',
            });
          }
        }
      }
    })
  }

  // Apre la modale con la lista di oggetti blended che contengono l'iniziativa
  openBlendedParentLibraryItems() {
    this.modalService.open('blendedParentLibraryItems');
    document.getElementById('blendedParentLibraryItems').focus();
  }

  // Chiude la modale con la lista di oggetti blended che contengono l'iniziativa
  closeBlendedParentLibraryItems() {
    this.modalService.close('blendedParentLibraryItems');
  }

  isInternetExplorerBrowser() {
    const ua = window.navigator.userAgent;
    const msie = ua.indexOf("MSIE ");

    if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./)) {
      return true;
    }
    return false;
  }

  // Recupera l'immagine del certificato
  getCertificationImage() {
    if (this.hasTheUserTheCertificateAssigned || (this.itemDetails && this.itemDetails.isCourseCertificationEnabled)) {
      this.showItemCertificationLoader = true;
      const itemIdForCertificate = this.isOnlineCourse && this.itemIdLinkedToCourse ? (this.editions && this.editions.length && this.editions[0] && this.editions[0].courseDateId) : (this.itemDetails && this.itemDetails.itemId);
      this.getCertificationFile$ = this.itemService.getCertificate(itemIdForCertificate, true, false, true)
        .subscribe(data => {
          // Se ci sono errori, li mostor e torno alla lista dei template
          if (data.response) {
            let downloadUrl = ItemUtil.getDownloadTempFileUrl(data.response, this.applicationData);
            // Salvo l'url dell'immagine
            this.certificationImageUrl = downloadUrl;
            let linkedinUrl = ItemUtil.getPublicCertificate(this.loggedUser.user.userId, itemIdForCertificate, this.applicationData);
            // Salvo l'url dell'immagine
            this.linkedinUrl = linkedinUrl;
          }
          this.showItemCertificationLoader = false;
        }
          , catchError((err, caught) => {
            this.showItemCertificationLoader = false;
            // Torniamo l'Observable di errore, affinché si possa ri-provare l'operazione
            return err;
          })
        )
    }
  }

  // Scarica il certificato
  downloadCertification() {
    if (this.hasTheUserTheCertificateAssigned) {
      this.showItemCertificationLoader = true;
      let itemIdForCertificate = this.isOnlineCourse && this.itemLinkedToCourse && this.itemLinkedToCourse.itemId ? (this.editions && this.editions.length && this.editions[0] && this.editions[0].courseDateId) : (this.itemDetails && this.itemDetails.itemId);
      this.getCertificationFile$ = this.itemService.getCertificate(itemIdForCertificate, false, false, false, true)
        .subscribe(data => {
          // Se ci sono errori, li mostor e torno alla lista dei template
          if (data.error) {
            this.toastr.error(this.translate.instant('errors.' + data.error));
            this.showItemCertificationLoader = false;
          } else {
            let downloadUrl = data.response;
            setTimeout(() => {
              // Scarico il file
              window.location.assign(downloadUrl);
              document.location.assign(downloadUrl);
              this.showItemCertificationLoader = false;
            }, 500);
          }
        })
    }


    /* this.getCertificationFile$ = this.itemService.getCertificate(itemIdForCertificate, false)
      .subscribe(data => {
        // Se ci sono errori, li mostor e torno alla lista dei template
        if (data.error) {
          this.isGettingItemDetails = false;
          this.showItemCertificationLoader = false;
          this.toastr.error(this.translate.instant('errors.' + data.error));
        } else {
          let certificateName = encodeURIComponent(data.response);
          let customName = this.translate.instant('generic.CERTIFICATE');
          customName = customName + ' ' + itemNameForCertificate + '.pdf';
          customName = encodeURIComponent(customName);
          let downloadUrl = ItemUtil.getDownloadTempFileUrl(certificateName, this.applicationData, customName);
          this.isGettingItemDetails = false;
          this.showItemCertificationLoader = false;
          setTimeout(() => {
            // Scarico il file
            window.location.assign(downloadUrl);
            document.location.assign(downloadUrl);
          }, 500);
        }
      }) */
  }

  // Torna indietro di pagina
  goBack() {
    let redirectPage = null;
    if (this.adminMode) {
      if (this.isItemsAdminPreview) {
        redirectPage = '/itemsAdmin/itemList';
      } else if (this.isInitiativesAdminPreview) {
        redirectPage = '/initiatives';
      } else if (this.isInitiativesAdminFromHomePreview) {
        redirectPage = '/home';
      };
    } else {
      if (this.isInLibrary) {
        // Verifico quanti elementi ho nel Session Storage
        let count = this.sessionStorageService.count();

        // ci sarà sempre un altro elemento nel session storage, dunque decremento il risultato
        count = count ? (count - 1) : 0;
        redirectPage = '/takers/library/' + count;
      } else if (this.isInCatalog) {
        // Verifico quanti elementi ho nel Session Storage
        let count = this.sessionStorageCatalogService.count();

        // ci sarà sempre un altro elemento nel session storage, dunque decremento il risultato
        count = count ? (count - 1) : 0;
        redirectPage = '/takers/catalog/' + count;
      } else if (this.isInTrainingBooklet) {
        redirectPage = '/takers/trainingBooklet/home';
      } else {
        redirectPage = '/takers/home';
      }
    }

    if (this.breadcrumbs && this.breadcrumbs.length) {
      for (let j = this.breadcrumbs.length - 1; j >= 0; j--) {
        if (!this.breadcrumbs[j].isActive) {
          redirectPage = this.breadcrumbs[j].redirectPage;
          break;
        }
      }
    }
    this.router.navigateByUrl(redirectPage);
  }

  // Apre un oggetto correlato
  openLinkedItem(itemId: string, containerId?: string) {
    if (itemId) {
      const isInLibrary = this.router.url.indexOf('library') !== -1;
      const isInCatalog = this.router.url.indexOf('catalog') !== -1;
      const isInTrainingBooklet = this.router.url.indexOf('trainingBooklet') !== -1;
      let baseUrl;
      if (isInLibrary) {
        baseUrl = 'takers/library/itemDetails';
      } else if (isInTrainingBooklet) {
        baseUrl = 'takers/trainingBooklet/itemDetails';
      } else if (isInCatalog) {
        baseUrl = 'takers/catalog/itemDetails';
      } else {
        baseUrl = 'takers/itemDetails';
      }
      if (containerId) {
        this.router.navigate([baseUrl, itemId, containerId]);
      } else {
        this.router.navigate([baseUrl, itemId]);
      }
    }
  }

  // Porta alla home
  goToHome() {
    if (this.isInLibrary) {
      // Verifico quanti elementi ho nel Session Storage
      let count = this.sessionStorageService.count();

      // ci sarà sempre un altro elemento nel session storage, dunque decremento il risultato
      count = count ? (count - 1) : 0;
      this.router.navigate(['/takers/library', count]);
    } else if (this.isInCatalog) {
      // Verifico quanti elementi ho nel Session Storage
      let count = this.sessionStorageCatalogService.count();

      // ci sarà sempre un altro elemento nel session storage, dunque decremento il risultato
      count = count ? (count - 1) : 0;
      this.router.navigate(['/takers/catalog', count]);
    } else if (this.isInTrainingBooklet) {
      this.router.navigate(['/takers/trainingBooklet/home']);
    } else {
      this.router.navigate(['/takers/home']);
    }
  }

  // Porta alla lista degli item
  goToListOfItems() {
    this.router.navigate(['/itemsAdmin/itemList']);
  }

  // Porta alla lista delle iniziative
  goToInitiatives() {
    this.router.navigate(['/initiatives']);
  }

  // Porta alla home lato admin
  goToHomeAdmin() {
    this.router.navigate(['/home']);
  }

  // Porta alla home lato manager
  goToManagerHome() {
    this.router.navigate(['/manager/home']);
  }

  translateAssessmentStatus(edition) {
    return this.translate.instant('generic.surveyStatuses.' + edition.surveyStatus);
  }

  ngOnDestroy(): void {
    if (this.scrollEvent$) {
      this.scrollEvent$.unsubscribe();
    }
    if (this.addStandaloneCatalogItemRequest$) {
      this.addStandaloneCatalogItemRequest$.unsubscribe();
    }
    if (this.getApplicationLang$) {
      this.getApplicationLang$.unsubscribe();
    }
    if (this.addChilds$) {
      this.addChilds$.unsubscribe();
    }
    if (this.suggestItemToUser$) {
      this.suggestItemToUser$.unsubscribe();
    }
    if (this.tryLibraryItemTakerEnroll$) {
      this.tryLibraryItemTakerEnroll$.unsubscribe();
    }
    if (this.countItems$) {
      this.countItems$.unsubscribe();
    }
    if (this.countUsersToSuggestItem$) {
      this.countUsersToSuggestItem$.unsubscribe();
    }
    if (this.refreshCachePlaylist$) {
      this.refreshCachePlaylist$.unsubscribe();
    }
    this.resetSubscriptions();
  }

  // Per ogni edizione, se non è stato segnato negli engagement, controllo manualmente se è già stata fatta una verifica
  searchCertificationsDone(editionsListWithPastEditions: Array<any>) {
    let promises = [];
    // Oltre che la survey nelle edizioni devo cercarla anche a livello di iniziativa; per questo, aggiungo temporaneamente all'array di edizioni anche l'iniziativa (poi la rimuovo)
    if (!editionsListWithPastEditions) {
      editionsListWithPastEditions = [];
    }
    let tmpList = editionsListWithPastEditions.concat(this.itemDetails);

    for (let j = 0, editionsLength = tmpList.length; j < editionsLength; j++) {
      let currentEdition = tmpList[j];
      if (currentEdition) {
        if (currentEdition.engagements && currentEdition.engagements.length) {
          for (let m = 0, engagementsLength = currentEdition.engagements.length; m < engagementsLength; m++) {
            let currentEngagement = currentEdition.engagements[m];
            if (currentEngagement.moduleName === ReferenceTypes.ITEM) {
              if (currentEngagement.eventName == ReferenceTypes.EVENT_COURSE_USER_PRESENT) {
                // L'item è stato concluso
                currentEdition.isConsumed = true;
              }
              if (currentEngagement.eventName === ReferenceTypes.EVENT_ITEM_CERTIFIED) {
                currentEdition.isItemAlreadyCertified = true;
                // dunque salvo la data
                currentEdition.certifiedDate = currentEngagement.creationDate;
              }
            }
          }
        }
        let responses = [];
        promises.push(new Promise((resolve: Function, reject: Function) => {
          // L'assessmentId è per le edizioni che hanno una (o più) survey associata,
          // courseDateId per chi non ha la survey, e itemId che rappresenta l'item a livello di iniziativa aggiunta con il concat precedente
          let idsPromises = [];
          let refIds = [];
          if (currentEdition.assessmentIds && currentEdition.assessmentIds) {

            for (let d = 0, assessmentIdsLength = currentEdition.assessmentIds.length; d < assessmentIdsLength; d++) {
              refIds.push(currentEdition.assessmentIds[d].itemId);
            }
          } else {
            refIds.push(currentEdition.courseDateId || currentEdition.itemId);
          }

          for (let f = 0, idsLength = refIds.length; f < idsLength; f++) {
            let currentRefId = refIds[f];
            idsPromises.push(new Promise((res: Function, rej: Function) => {
              this.takerService.getSurveys(this.loggedUser.user.userId, currentRefId)
                .subscribe(
                  (data: SenecaResponse<any>) => {
                    if (data.response && data.response.length) {
                      responses.push(data.response);
                    }
                    if (data.error) {
                      this.toastr.error(this.translate.instant('errors.' + data.error));
                      rej();
                    } else {
                      currentEdition.isSurveyStarted = false;
                      currentEdition.isSurveyCertificable = false;
                      currentEdition.survey = data.response;
                      currentEdition.isSurveyNotRepeteable = false;
                      currentEdition.referenceId = data.response && data.response.referenceId;

                      if (currentEdition.survey && currentEdition.survey.referenceId) {
                        this.assessmentIdRelated = currentEdition.survey.referenceId;
                      }

                      if (data.response && data.response.surveyStatus) {
                        if (data.response.surveyAttributes && data.response.surveyAttributes.length && data.response.surveyAttributes.some(attr => attr.attributeType == SurveyAttributeTypes.IS_SURVEY_NOT_REPEATABLE)) {
                          currentEdition.isSurveyNotRepeteable = true;
                        }
                        if (data.response.surveyStatus === SurveyStatuses.STARTED) {
                          // Survey iniziata
                          currentEdition.isSurveyStarted = true;
                        } else if (data.response.surveyStatus === SurveyStatuses.VALID) {
                          // Se lo status è valido, significa che ho una survey certificabile
                          currentEdition.isSurveyCertificable = true;
                        } else if ((data.response.surveyStatus === SurveyStatuses.PASSED || data.response.surveyStatus === SurveyStatuses.ENDED) && !currentEdition.isSurveyStarted) {
                          // Survey completata con successo
                          currentEdition.isSurveyPassed = true;
                          // Salvo la data in cui è stata fatta l'operazione
                          currentEdition.surveyPassedDate = data.response.modifyDate;
                        } else if (data.response.surveyStatus === SurveyStatuses.NOT_PASSED) {
                          // Survey completata ma senza raggiungere un punteggio sufficiente
                          currentEdition.isSurveyNotPassed = true;
                        }
                        currentEdition.surveyStatus = data.response.surveyStatus;
                        currentEdition.assessmentType = 'survey.OTHER';
                        if (currentEdition.courseId == currentRefId || currentEdition.stageItemId == currentRefId || currentEdition.stageItemId == data.response.referenceId) {
                          currentEdition.surveyStatus = data.response.surveyStatus;
                        }
                      }
                      if ((currentEdition.isSurveyNotPassed && currentEdition.isSurveyNotRepeteable) || currentEdition.isSurveyStarted || currentEdition.isSurveyPassed || currentEdition.isSurveyCertificable || currentEdition.isItemAlreadyCertified) {
                        let alreadyAdded = !!this.editionsSurvey.find(edition => {
                          return edition.courseDateId == currentEdition.courseDateId;
                        })
                        if (!alreadyAdded) {
                          if (this.isAssessment) {
                            //Controllo il numero di edizioni in modo da confrotarle col figlio stageItem
                            // Gestione dell'assessment
                            //Se l'edizione è quella legata allo stage, controllo le date di inizio e di fine dell'edizione, nel caso il corso fosse già terminato o ancora da iniziare, non mostro il questionario.
                            if (currentEdition.itemId == this.itemDetails.itemId && !moment(currentEdition.lastDayDate).isBefore(new Date().toUTCString()) && !moment(currentEdition.firstDayDate).isAfter(new Date().toUTCString())) {
                              this.editionsSurvey.push(currentEdition);
                            };
                          } else {
                            // Gestione di una iniziativa che ha un assessment collegato ad una delle sue edizioni
                            for (let i = 0; i < this.editionsListWithPastEditions.length; i++) {
                              let currentPastEdition = this.editionsListWithPastEditions[i];
                              if (currentPastEdition.assessmentIds && currentPastEdition.assessmentIds.length) {
                                // Ora cerco fra le edizioni, quella che realmente è la rilevazione
                                for (let m = 0; m < this.editionsListWithPastEditions.length; m++) {
                                  let currentEditionPast = this.editionsListWithPastEditions[m];
                                  let currentPastEditionAlreadyAdded = !!this.editionsSurvey.find(edition => {
                                    return edition.courseDateId == currentEditionPast.courseDateId;
                                  })
                                  let isValidDate = !moment(currentEditionPast.lastDayDate).isBefore(new Date().toUTCString()) && !moment(currentEditionPast.firstDayDate).isAfter(new Date().toUTCString());

                                  for (let b = 0, assessmentIdsLength = currentPastEdition.assessmentIds.length; b < assessmentIdsLength; b++) {
                                    const currentAssessment = currentPastEdition.assessmentIds[b] && currentPastEdition.assessmentIds[b];

                                    if (!isValidDate && currentAssessment && currentAssessment.itemId && currentAssessment.itemId === currentEditionPast.stageItemId
                                      && !currentPastEditionAlreadyAdded) {

                                      for (let t = 0; t < this.editions.length; t++) {
                                        let alreadyAddedEdition: any = this.editions[t];
                                        if (alreadyAddedEdition.stageItemId && alreadyAddedEdition.stageItemId === currentEditionPast.stageItemId) {
                                          this.editions.splice(t, 1);
                                          break;
                                        }
                                      }
                                    }

                                    if (isValidDate && currentAssessment && currentAssessment.itemId && currentAssessment.itemId === currentEditionPast.stageItemId
                                      && !currentPastEditionAlreadyAdded) {

                                      if (!currentAssessment.userEnrollStatusType) {
                                        for (let t = 0; t < this.editions.length; t++) {
                                          let alreadyAddedEdition: any = this.editions[t];
                                          if (alreadyAddedEdition.stageItemId && alreadyAddedEdition.stageItemId === currentEditionPast.stageItemId) {
                                            this.editions.splice(t, 1);
                                            break;
                                          }
                                        }
                                      } else {
                                        if (!currentEditionPast.formattedStopDate) {
                                          currentEditionPast.formattedStopDate = currentEditionPast.endDate && moment(currentEditionPast.endDate).format('DD/MM/YYYY') || '';
                                        }

                                        // Traduco la tipologia di assessment (altro, gradimento, apprendimento)
                                        let assessmentType = 'survey.OTHER';

                                        if (currentAssessment && currentAssessment.itemAttributes && currentAssessment.itemAttributes.length) {
                                          let notRepeable = currentAssessment.itemAttributes.filter((attribute: any) => attribute.attributeType === SurveyAttributeTypes.IS_SURVEY_NOT_REPEATABLE);
                                          if (notRepeable && notRepeable[0]) {
                                            currentEditionPast.isSurveyNotRepeteable = notRepeable[0].attributeValue == 'true';
                                          } else {
                                            currentEditionPast.isSurveyNotRepeteable = false;
                                          }
                                          for (let e = 0, assessmentAttributesLength = currentAssessment.itemAttributes.length; e < assessmentAttributesLength; e++) {
                                            if (currentAssessment.itemAttributes[e].attributeType && currentAssessment.itemAttributes[e].attributeType === ItemAttributeTypes.SURVEY_TYPE) {
                                              currentEditionPast.customStatus = true;
                                              assessmentType = 'survey.' + currentAssessment.itemAttributes[e].attributeValue;
                                              // break;
                                            }
                                            if (currentAssessment.itemAttributes[e].attributeType && currentAssessment.itemAttributes[e].attributeType === ItemAttributeTypes.STATUS) {
                                              if (currentAssessment.itemAttributes[e].attributeValue == SurveyStatuses.PASSED) {
                                                currentEditionPast.isSurveyPassed = true;
                                              } else if (currentAssessment.itemAttributes[e].attributeValue == SurveyStatuses.STARTED || currentAssessment.itemAttributes[e].attributeValue === "IN_PROGRESS") {
                                                currentEditionPast.isSurveyStarted = true;
                                              } else if (currentAssessment.itemAttributes[e].attributeValue == SurveyStatuses.NOT_PASSED) {
                                                currentEditionPast.isSurveyNotPassed = true;
                                              }
                                            }
                                          }
                                        }

                                        currentEditionPast.assessmentType = assessmentType;
                                        if (data.response) {
                                          if (currentEditionPast.courseId == currentRefId || currentEditionPast.stageItemId == currentRefId || currentEditionPast.referenceId == data.response.referenceId) {
                                            currentEditionPast.surveyStatus = data.response.surveyStatus;
                                          }
                                          if (!currentEditionPast.surveyStatus && responses) {
                                            for (let z = 0; z < responses.length; z++) {
                                              if ((responses[z].referenceId == currentEditionPast.courseId) || (responses[z].referenceId == currentEditionPast.courseDateId) || (responses[z].referenceId == currentEditionPast.stageItemId)) {
                                                currentEditionPast.surveyStatus = responses[z].surveyStatus;
                                              }
                                            }
                                          }
                                        }

                                        this.editionsSurvey.push(currentEditionPast);
                                        // Tale edizione, per non trovarmela nella lista delle edizioni (bensì solo nelle sessioni), la tolgo
                                        for (let t = 0; t < this.editions.length; t++) {
                                          let alreadyAddedEdition: any = this.editions[t];
                                          if (alreadyAddedEdition.stageItemId && alreadyAddedEdition.stageItemId === currentEditionPast.stageItemId) {
                                            this.editions.splice(t, 1);
                                            break;
                                          }
                                        }
                                        break;
                                      }
                                    }
                                  }
                                }
                              }
                              /* Se l'edizione è quella legata allo stage, controllo le date di inizio e di fine dell'edizione, nel caso il corso fosse già terminato o ancora da iniziare, non mostro il questionario.
                              if (this.editionsListWithPastEditions[i].stageItemId == this.itemDetails.itemId &&
                                !moment(this.editionsListWithPastEditions[i].lastDayDate).isBefore(new Date().toUTCString()) && !moment(this.editionsListWithPastEditions[i].firstDayDate).isAfter(new Date().toUTCString())) {
                                this.editionsSurvey.push(currentEdition);
                              }; */
                            }
                          }
                        }
                      }
                      /*
                      if (currentEdition.itemId && currentEdition.itemId === this.itemDetails.itemId) {
                        this.itemDetails = JSON.parse(JSON.stringify(currentEdition));
                      } */
                      res();
                    }
                    // NON togliere, è ridondante ma lo status per qualce motivo si perde ed è necessario riassociarlo
                    if (this.editionsSurvey && this.editionsSurvey.length && responses && responses.length) {
                      for (let edition of this.editionsSurvey) {
                        for (let response of responses) {
                          if (response && ((edition.courseId == response.referenceId) || (edition.courseDateId == response.referenceId) || (edition.stageItemId == response.referenceId))) {
                            edition.surveyStatus = response.surveyStatus;
                          }
                        }
                      }
                    }
                  },
                  (err) => {
                    this.toastr.error(this.translate.instant('errors.' + err.message));
                    rej();
                  });
            }));
          }
          Promise.all(idsPromises).then(() => {
            resolve();
          })
        }));
      }
    }
    // Risolvo le promesse coi dati recuperati
    return Promise.all(promises);
  }

  // Scarica il certificato dell'edizione
  downloadEditionCertification(edition) {
    if (this.hasTheUserTheCertificateAssigned) {
      // Avvio il loader
      this.isGettingItemDetails = true;
      if (this.downloadEditionCertification$) {
        this.downloadEditionCertification$.unsubscribe();
      }
      this.downloadEditionCertification$ = this.itemService.getCertificate(edition.courseDateId, false, false, false, true)
        .subscribe(data => {
          this.isGettingItemDetails = false;
          // Se ci sono errori, li mostor e torno alla lista dei template
          if (data.error) {
            this.toastr.error(this.translate.instant('errors.' + data.error));
          } else {
            let downloadUrl = data.response;
            setTimeout(() => {
              // Scarico il file
              window.location.assign(downloadUrl);
              document.location.assign(downloadUrl);
            }, 500);
          }
        })
    }
  }

  // Recupera le survey (rilevazioni) associate alle edizioni
  getAssessmentsOfEditions(editionsListWithPastEditions: Array<any>) {
    let promises = [];
    // Oltre che la survey nelle edizioni devo cercarla anche a livello di iniziativa; per questo, aggiungo temporaneamente all'array di edizioni anche l'iniziativa (poi la rimuovo)
    if (!editionsListWithPastEditions) {
      editionsListWithPastEditions = [];
    }
    for (let j = 0, editionsLength = editionsListWithPastEditions.length; j < editionsLength; j++) {
      let currentEdition = editionsListWithPastEditions[j];
      promises.push(new Promise((resolve: Function, reject: Function) => {
        this.takerService.getEditionAssessmentsForPublic(this.loggedUser.user.userId, currentEdition.courseDateId)
          .subscribe(
            (assessmentData: SenecaResponse<Item[]>) => {
              if (assessmentData.error) {
                // Vedo se c'è la traduzione dell'errore
                this.toastr.error(this.translate.instant('errors.' + assessmentData.error));
                reject();
              } else if (assessmentData && assessmentData.response && assessmentData.response.length) {
                for (let k = 0, surveysLength = assessmentData.response.length; k < surveysLength; k++) {
                  const currentSurvey = assessmentData.response[k];

                  if (!currentEdition.assessmentIds) {
                    currentEdition.assessmentIds = [];
                  }

                  if (currentSurvey && currentSurvey.referenceId && currentSurvey.referenceId === currentEdition.courseDateId) {
                    for (let q = 0; q < this.editions.length; q++) {
                      let edition = this.editionsListWithPastEditions[q];
                      if (edition.stageItemId == currentSurvey.itemId) {
                        currentSurvey.userEnrollStatusType = edition.userStatus || null;
                      }
                    }
                    currentEdition.assessmentIds.push(currentSurvey);
                  }
                }
                resolve();
              } else {
                currentEdition.assessmentIds = null;
                resolve();
              }
            },
            (err) => {
              this.toastr.error(this.translate.instant('errors.' + err.message));
              reject();
            });
      }));
    }
    // Risolvo le promesse coi dati recuperati
    return Promise.all(promises);
  }

  // Recupera i taker dell'utente
  getUserTakers() {
    return new Promise((resolve, reject) => {
      const editionIds = this.editions.map(edition => edition.courseDateId);

      if (!editionIds || !editionIds.length) {
        resolve(false);
        return;
      } else {
        this.takerService.listUserTakers(<CourseManagerItem.ListUserTakersForPublic>{
          userId: this.loggedUser.user.userId,
          editionItemIds: editionIds || [],
          stageItemId: this.itemDetails.itemId,
          allData: true
        })
          .subscribe(
            (senecaResponse: SenecaResponse<EnrichedItemTaker[]>) => {

              if (senecaResponse.error) {
                // Vedo se c'è la traduzione dell'errore
                this.toastr.error(this.translate.instant('errors.' + senecaResponse.error));
                reject();
              } else {
                const takers = senecaResponse.response;
                this.takers = takers;
                let allUserEnroll = takers && takers
                  .map(taker => taker.takerEnrolls)
                  .reduce((flat, arr) => flat.concat(arr), []) || [];
                this.editions.forEach((edition: any) => {
                  let takerEnroll = allUserEnroll.find(enroll => enroll && enroll.itemId === edition.courseDateId);
                  edition.usedTakes = (takerEnroll && takerEnroll.usedTakes) || 0;
                });
                resolve(true);
              }
            },
            (err) => {
              this.toastr.error(this.translate.instant('errors.' + err.message));
              reject();
            });
      }
    });
  }

  // Apre una survey
  openSurvey() {
    let baseUrl;
    if (this.isInLibrary) {
      baseUrl = 'takers/library';
    } else if (this.isInCatalog) {
      baseUrl = 'takers/catalog';
    } else if (this.isInTrainingBooklet) {
      baseUrl = 'takers/trainingBooklet';
    } else {
      baseUrl = 'takers';
    }
    if ((this.isSyllabusCourse && this.itemLinkedToCourse) || !this.isSyllabusCourse) {
      this.router.navigate([baseUrl + '/itemSurvey', this.itemLinkedToCourse ? (this.assessmentIdRelated || this.itemLinkedToCourse) : this.itemDetails.itemId]);
    } else if (this.isAssessment && this.editions && this.editions.length) {
      let itemId: any[] = this.editions.filter((currentEdition: any) => {
        return currentEdition.stageItemId === this.itemDetails.itemId;
      });
      if (itemId && itemId.length) {
        this.router.navigate([baseUrl + '/survey', this.itemDetails.itemId, itemId[0].stageItemId]);
      }
    }
  }

  // crea la lista di docenti e relatori (tutor) con ruolo e curriculum
  getTeachersAndTutors(tmpTeachersTutors) {
    // array che tiene gli id delle persone aggiunte per evitare doppioni
    // se ho del personale
    if (tmpTeachersTutors) {
      for (let key in tmpTeachersTutors) {
        // per ognuno controllo se è già stato aggiunto all'oggetto
        let teacher = tmpTeachersTutors[key];
        if (this.idsAdded.indexOf(teacher.id) === -1) {
          // creo l'oggetto temporaneo che sarà aggiunto alla lista e controllo se ha degli attributi
          let teacherData = teacher.reference;
          if (teacherData?.supplierPersonAttributes) {
            for (let k in teacherData.supplierPersonAttributes) {
              let attribute = teacherData.supplierPersonAttributes[k];
              // salvo il ruolo
              if (attribute.attributeValue === SupplierPersonAttributeRoles.TEACHING_ROLE) {
                teacherData.isTeacher = true;
              } else if (attribute.attributeValue === SupplierPersonAttributeRoles.TUTOR_ROLE) {
                teacherData.isTeacher = false;
              }
              // oppure tutto l'attribute del curriculum se c'è
              if (attribute.attributeType === SupplierPersonAttributeTypes.CURRICULUM) {
                teacherData.curriculum = attribute;
              }
            }
          }
          // salvo l'ids per i prossimi check
          this.idsAdded.push(teacher.id);
          // aggiungo l'oggetto alla lista
          this.teachersTutorsList.push(teacherData);
        }
      }
    }
  }

  openBiographyModal(person) {
    this.selectedPerson = person;
    this.modalService.open("biographyModal");
    document.getElementById('biographyModal').focus();
  }

  closeBiographyModal() {
    this.selectedPerson = null;
    this.modalService.close("biographyModal");
  }

  // scarica il curriculum allegato al docente o tutor
  getCvUrl(uploadId: string) {
    // chiamo il supplierService per avere l'oggetto upload associato al curriculum
    let getCvUrlPromise = this.supplierService.getSupplierPersonAttachment(uploadId);
    getCvUrlPromise.
      subscribe((senecaResponse: SenecaResponse<Upload>) => {
        // Salvo l'url
        let updateObj = senecaResponse.response;
        setTimeout(() => {
          // Scarico il file
          window.location.assign(updateObj.url);
        }, 500)
      }, (err) => {
        this.toastr.error(this.translate.instant('errors.' + err.message));
      })
  }

  checkUserTheCertificateAssigned(stageId: string) {
    return new Promise((resolve, reject) => {
      if (stageId) {
        this.itemService.hasUserCertificaAssigned(stageId)
          .subscribe(
            (hasTheUserTheCertificateAssignedResponse: SenecaResponse<boolean>) => {
              if (hasTheUserTheCertificateAssignedResponse.error) {
                // Vedo se c'è la traduzione dell'errore
                this.toastr.error(this.translate.instant('errors.' + hasTheUserTheCertificateAssignedResponse.error));
              } else if (hasTheUserTheCertificateAssignedResponse && hasTheUserTheCertificateAssignedResponse.response) {
                this.hasTheUserTheCertificateAssigned = true;
                resolve(true);
              } else {
                this.hasTheUserTheCertificateAssigned = false;
                resolve(true);
              }
            },
            (err) => {
              this.toastr.error(this.translate.instant('errors.' + err.message));
              reject();
            });
      }
      resolve(true);
    })
  }

  createItemTakerEnrollByExternalObjId() {
    // Controllo inizialmente se sono in un corso, poi se ho un id di una playlist colleagata, nel caso non avessi nessuno di questo mi recupero l'itemId
    let externalObjId = (this.editionsOfWrappedContainer && this.editionsOfWrappedContainer.length && this.editionsOfWrappedContainer[0].externalObjectId) || this.lpId || this.externalObjId || this.itemId;
    if (externalObjId) {
      this.itemService.createItemTakerEnrollByExternalObjId(externalObjId)
        .subscribe(
          (done: SenecaResponse<any>) => {
          },
          (err) => {
            this.toastr.error(this.translate.instant('errors.' + err.message));
          });
    }
  }

  async teacherAvailabilityCheck(personId, days, stageItemId, dayIdToIgnore) {
    try {
      let res = await this.initiativeService.listCoursesInSameConflictByTeacher(personId, days, stageItemId, dayIdToIgnore).toPromise();
      if (res.error) {
        this.toastr.error(this.translate.instant('errors.' + res.error));
      } else {
        if (res.response.length) {
          this.teacherAvailabilityResponse = res.response;
        }
      }
    } catch (error) {
    }
  }

  async availabilityCheckVPL(stageItemId, days, userIds, dayIdToIgnore) {
    try {
      let res = await this.takerService.availabilityCheckVPL(stageItemId, days, userIds, dayIdToIgnore).toPromise();
      if (res.error) {
        this.toastr.error(this.translate.instant('errors.' + res.error));
        this.availabilityError = res.error;
      } else {
        this.availabilityResponse = res.response;
      }
    } catch (error) {
    }
  }

  openTeacherAvailabilityConflictModal() {
    let teachersInConflict = document.getElementById("teacherNotAvailable");

    this.teacherAvailabilityResponse[0].conflictedEditions.forEach(ed => {
      teachersInConflict.innerHTML +=
        `<li><ul style="list-style-type:none;">`;

      ed.days.forEach(day => {
        let dayStart = moment(day.dayStart);
        let dayEnd = moment(day.dayEnd).format("HH:mm");
        let c =
          `<li><span style="font-weight: bold;">${ed.title}</span> - ${dayStart.format(this.translate.instant("generic.DATE_FORMAT_FULL"))} ` +
          `(${this.translate.instant("meetings.CONFIRM_PRESENCE.HOUR_FROM")} ${dayStart.format("HH:mm")} ` +
          `${this.translate.instant("meetings.CONFIRM_PRESENCE.HOUR_TO")} ${dayEnd})</li>`;


        teachersInConflict.innerHTML += c;
      });

      teachersInConflict.innerHTML +=
        `</ul></li>`;
    });

    this.modalService.open("teacherNAModal");
    document.getElementById("teacherNAModal").focus();
  }

  closeTeacherAvailabilityConflictModal() {
    let teachersInConflict = document.getElementById("teacherNotAvailable");
    this.modalService.close("teacherNAModal");
    teachersInConflict.innerHTML = "";
    this.teacherAvailabilityResponse = null;
  }

  openAvailabilityConflictModal() {
    this.modalService.open("availabilityConflictModal");
    document.getElementById("availabilityConflictModal").focus();
  }

  closeAvailabilityConflictModal() {
    this.modalService.close("availabilityConflictModal");
    this.editionsInConflict = null;
  }

  openVplModal() {
    this.modalService.open("vplModal");
    document.getElementById("vplModal").focus();
  }

  closeVplModal() {
    this.modalService.close("vplModal");
    this.errorMessageVPL = null;
    this.errorCodesVPL = null;
  }

  // Titolo modale di cancellazione iscrizione/preiscrizione
  getCancelSubscriptionTitle() {
    let isPreregister = false;
    if (this.itemDetails && this.itemDetails.itemTakers && this.itemDetails.itemTakers.length) {
      this.itemDetails.itemTakers.forEach((taker: any) => {
        if (taker.takerEnrolls && taker.takerEnrolls.length) {
          taker.takerEnrolls.forEach((enroll: any) => {
            let userInitiativeStatus: any = enroll.takerEnrollStatuses && enroll?.takerEnrollStatuses[0];
            userInitiativeStatus = userInitiativeStatus && userInitiativeStatus.statusType || '';
            if (userInitiativeStatus == ItemTakerEnrollStatusTypes.USER_STATUS_PREREGISTERED) {
              isPreregister = true;
            }
          })
        }
      })
    }
    return isPreregister ? 'takers.courseSubscription.modal.cancelSubscription.PRE_TITLE' : 'takers.courseSubscription.modal.cancelSubscription.TITLE';
  }

  // Testo modale di cancellazione iscrizione/preiscrizione
  getCancelSubscriptionText() {
    let isPreregister = false;
    if (this.itemDetails && this.itemDetails.itemTakers && this.itemDetails.itemTakers.length) {
      this.itemDetails.itemTakers.forEach((taker: any) => {
        if (taker.takerEnrolls && taker.takerEnrolls.length) {
          taker.takerEnrolls.forEach((enroll: any) => {
            let userInitiativeStatus: any = enroll.takerEnrollStatuses && enroll?.takerEnrollStatuses[0];
            userInitiativeStatus = userInitiativeStatus && userInitiativeStatus.statusType || '';
            if (userInitiativeStatus == ItemTakerEnrollStatusTypes.USER_STATUS_PREREGISTERED) {
              isPreregister = true;
            }
          })
        }
      })
    }
    return isPreregister ? 'takers.courseSubscription.modal.cancelSubscription.DO_YOU_WANT_TO_CANCEL_PRESUB' : 'takers.courseSubscription.modal.cancelSubscription.DO_YOU_WANT_TO_CANCEL_SUBSCRIPTION';

  }

  /* 
   * Funzione che disabilita il vedi dettagli degli oggetti dei learning plan blended
   * nel caso non ci sia la visibilità pubblica e l'utente non abbia uno stato valido per la fruizione
   * ovvero confermato, invitato, presente o approvato  
  */
  isTakerValidForLPB() {
    let validLPBStatuses = [ItemTakerEnrollStatusTypes.USER_STATUS_APPROVED, ItemTakerEnrollStatusTypes.USER_STATUS_CONFIRMED, ItemTakerEnrollStatusTypes.USER_STATUS_INVITED, ItemTakerEnrollStatusTypes.USER_STATUS_PRESENT];
    // se sono in library evito il controllo e lascio che siano le altre funzioni dedicate agli oggetti library a decidere se l'utente può o meno usufruire dell'oggetto
    if (this.isInLibrary) {
      return true;
    } else if (this.itemDetails && this.itemDetails.itemTakers) {

      // Devo abilitare il pulsante se almeno uno degli enroll dell'utente ha uno stato valido
      // Ad esempio se ho uno stato cancellato a livello di iniziativa ma sono confermato a livello di edizione, devo poter vedere i dettagli
      return Boolean(
        this.itemDetails.itemTakers
          ?.some(taker => taker.takerEnrolls
            ?.some(enroll => enroll.takerEnrollStatuses
              ?.some(status => validLPBStatuses.includes(status.statusType))))
      );
    }
    return false;
  }

  isExternalObj: boolean = false;
  getItemDetailsForPlanTracker(parentId: string, isExternalObj?: boolean): Observable<IDataItem> {
    return this.itemService.getConsumableItemByIdForUser(
      this.translate,
      parentId,
      true,
      5,
      attributeTypesToRetrieve
    ).pipe(
      switchMap(res => {
        this.isExternalObj = isExternalObj ?? false;

        const externalObj = res.itemAttributes?.find(attr => attr.attributeType === ItemAttributeTypes.EXTERNAL_OBJECT_ID);

        if (externalObj) {
          return this.getItemDetailsForPlanTracker(externalObj.attributeValue, true);
        }

        return of(res);
      })
    )
  }
}