/*
* Componente per una sezione di un learning plan
*/

import { Component, Input, Output, EventEmitter, OnInit, OnChanges, SimpleChanges } from "@angular/core"
import { Item, ItemChild, ItemTypes, ItemAttributeTypes, ScormObjectTypes, ItemAttributeObjectTypes } from 'src/cm2-commonclasses';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { ItemUtil, IDataItem } from 'src/app/shared/models/item.model';
import { TakerService } from 'src/app/takers/services/taker.service';

@Component({
  selector: 'section',
  templateUrl: './section.component.html'
})
export class SectionComponent implements OnInit, OnChanges {
  @Input() item;
  @Input() sectionList: IDataItem[];
  @Input() page: string;
  @Input() loggedUserId?: string;
  @Input() areIDataItems?: boolean;
  @Input() searchMandatoryIn: ItemChild[]; // se sono nel dettaglio del progetto, devo trovare l'obbligatorierà a mano
  @Input() fullItem; // Oggetto Item della sezione
  @Input() propedeuticReferenceId: string; // id dell'oggetto propedeutico per quello attuale
  @Input() searchPropedeuticReferenceIdIn; // se sono nel dettaglio del progetto, devo trovare la propedeuticità a mano
  @Input() propedeuticReferenceIds: string[]; // lista dei propedeuticReferenceIds di ogni itemChilds
  @Input() currentProject: Item; // oggetto Item del progetto
  @Input() index: number; // numero della sezione
  @Input() editMode: boolean; // verifica se sono in edit
  @Input() sectionItemChild: ItemChild;
  @Input() selectedItemChilds: ItemChild[];
  @Input() isInPreview: boolean;
  @Input() disableActionForLpItemsForInvalidStatus: boolean = false; // disabilita le azioni degli oggetti (come vedi dettagli) per i figli del learning plan se non sono in uno stato valido
  @Input() hideDetails: boolean = false; // Disabilita il tasto vedi dettagli

  @Output() showPropedeuticalItemToThis: EventEmitter<any> = new EventEmitter(); // apre una modale per indicare quale oggetto è propedeutico per sbloccare quello corrente
  @Output() showPropedeuticalItemToThisChild: EventEmitter<any> = new EventEmitter(); // apre una modale per indicare quale oggetto è propedeutico per sbloccare quello corrente
  @Output() itemChildMandatoryChanged = new EventEmitter<{ childItemId: string, mandatory: number }>();
  @Output() managePrerequisites = new EventEmitter<ItemChild>();
  @Output() itemChildChecked = new EventEmitter<ItemChild>();

  isItemDisabled: boolean;
  adminMode: boolean;
  isConsumed: boolean;
  mandatoryItemChilds: number;
  consumedMandatorItemChilds: number;
  totalConsumedItemChilds: number;
  isItemAlreadyCertified: boolean;
  isSurveyStarted: boolean;
  isSurveyCertificable: boolean;
  isItemCertifiable: boolean;
  isMandatory: boolean;
  isPropedeutic: boolean;
  prerequisites;
  propedeuticTitle: string;
  totalCertifiedObjInSection: number;
  totalWithAnyActionObjInSection: number;
  totalDisabledObjInSection: number;
  percentageItem: number | string;
  suggestedFromAdmin: boolean;
  mandatory: number;
  numberOfChilds: number;
  itemType: string;
  propedeuticReferenceIdsOfItemsInSection: string[];
  isRequired: boolean;
  totalSurveyMandatoryInSection: number;
  completedSurvey: number;
  surveyMap: {
    [itemId: string]: {
      isSurveyStarted: boolean,
      isSurveyCertificable: boolean,
      isItemCertifiable?: boolean
    }
  };
  isBlendedSection: boolean = false;

  constructor(private toastr: ToastrService,
    private translate: TranslateService,
    private router: Router,
    private takerService: TakerService) {
    // Di base la sezione è abilitata
    this.isItemDisabled = false;

    // Verifica se sono nella parte amministrativa 
    if (this.router.url.indexOf('Blended') !== -1) {
      this.isBlendedSection = true;
    }
    if (this.router.url.indexOf('Admin') !== -1) {
      this.adminMode = true;
    } else {
      this.adminMode = false;
    }

    // Verifica se la sezione è consumata
    this.isConsumed = false;

    // Elementi della sezione obbligatori
    this.mandatoryItemChilds = 0;
    this.consumedMandatorItemChilds = 0;

    this.itemType = ItemAttributeObjectTypes.SECTION;

    // Oggetti totali completati
    this.totalConsumedItemChilds = 0;

    this.numberOfChilds = 0;

    // Controlla se la sezione è verificata
    this.isItemAlreadyCertified = false;

    // Controlla se c'è una verifica iniziata 
    this.isSurveyStarted = false;

    // Verifica se c'è una survey disponibile
    this.isSurveyCertificable = false;

    this.isItemCertifiable = false;

    // Salvo l'obbligatorietà
    this.isMandatory = false;

    // Verifico se questa seziona è propedeutica per qualche altro oggetto
    this.isPropedeutic = false;
    this.prerequisites = null;
    this.propedeuticTitle = null;

    // Totale oggetti certificati della sezione del progetto
    this.totalCertifiedObjInSection = 0;

    // Totale oggetti con il pallino vuoto (nessuna azione è stata fatta) della sezione del progetto
    this.totalWithAnyActionObjInSection = 0;

    // Totale oggetti con il pallino bloccato
    this.totalDisabledObjInSection = 0;

    // Oggetto iniziato
    this.percentageItem = 0;

    // Suggerito dall'amminsitrazione
    this.suggestedFromAdmin = false;
  }

  // Emette l'evento per la propedeuticità
  onManagePrerequisites(event: Event) {
    if (!this.prerequisites.hasPrerequisites) {
      // Se ha prerequisiti, lo sto de-checkando
      event.preventDefault();
    }
    this.managePrerequisites.emit(this.sectionItemChild);
  }

  // Alza l'evento per aprire una modale per indicare quale oggetto è propedeutico per sbloccare quello corrente
  onShowPropedeuticalItemToThis() {
    if (this.isItemDisabled) {
      this.showPropedeuticalItemToThis.emit(this.item);
    }
  }

  onShowPropedeuticalItemToThisChild(data) {
    if (this.areIDataItems) {
      if (data && data.isItemDisabled) {
        let objs = {
          item: this.item,
          selectedItem: data
        }
        this.showPropedeuticalItemToThisChild.emit(objs);
      }
    } else {
      if (data && data.childObject && data.isItemDisabled) {
        let objs = {
          item: this.item,
          selectedItem: data.childObject
        }
        this.showPropedeuticalItemToThisChild.emit(objs);
      }
    }
  }

  // Sta in ascolto sul check della sezione
  onSectionChecked(data?) {
    this.emitItemChecked(data);
  }

  ngOnInit(): void {
    this.surveyMap = {}
    if (this.searchMandatoryIn) {
      for (let i = 0; i < this.searchMandatoryIn.length; i++) {
        if (this.searchMandatoryIn[i].referenceId == this.fullItem.itemId) {
          this.isMandatory = !!this.searchMandatoryIn[i].mandatory;
          break;
        }
      }
    }

    // Se necessario ricerco la propedeuticità
    if (this.searchPropedeuticReferenceIdIn) {
      for (let i = 0; i < this.searchPropedeuticReferenceIdIn.length; i++) {
        if (this.searchPropedeuticReferenceIdIn[i].referenceId == this.fullItem.itemId && this.searchPropedeuticReferenceIdIn[i].propedeuticReferenceId) {
          this.propedeuticReferenceId = this.searchPropedeuticReferenceIdIn[i].propedeuticReferenceId;
          break;
        }
      }
    }

    if (this.propedeuticReferenceIds && this.propedeuticReferenceIds.length) {
      this.checkPropedeuticReferenceIds();
    }

    // Verifico se esiste un altro oggetto propedeutico per quello attuale
    this.prerequisites = {
      hasPrerequisites: this.propedeuticReferenceId ? true : false
    }

    // Se ho un item propedeutico a quello attuale, recupero il suo titolo
    if (this.prerequisites.hasPrerequisites) {
      this.propedeuticTitle = this.getPropedeuticReferenceTitle(this.fullItem);
    }

    // Recupero i propedeuticReferenceIds degli oggetti aggiunti all'interno della sezione
    this.propedeuticReferenceIdsOfItemsInSection = ItemUtil.getPropedeuticReferenceIds(this.item.itemChilds, true);

    // Se sono nella pagina di dettaglio del progetto [chiamato learning plan], devo caricare gli oggetti associati alla sezione
    if (this.page == 'projectDetails') {
      this.numberOfChilds = this.item && this.item.itemChilds && this.item.itemChilds.length || 0;

      // Recupero le informazioni sugli oggetti aggiunti alla sezione
      if (this.item.itemChilds && this.item.itemChilds.length) {
        let propedeuticReferenceIds: any = [];
        for (let j = 0; j < this.item.itemChilds.length; j++) {
          let currentItemChild: any = this.item.itemChilds[j];

          if (currentItemChild.propedeuticReferenceId) {
            propedeuticReferenceIds.push(currentItemChild.propedeuticReferenceId);
          }

          if (currentItemChild.propedeuticReferenceId && !currentItemChild.isNotDisabled) {
            // Disabilito l'item corrente se ce n'è un'altro obbligatorio
            currentItemChild.isItemDisabled = true;
          }

          // Verifico se è un oggetto scorm
          if (this.areIDataItems) {
            currentItemChild.isScorm = currentItemChild && currentItemChild.itemType && (currentItemChild.itemType === ItemTypes.SCORM_FREE || currentItemChild.itemType === ItemTypes.SCORM_INVITE) ? true : false;
          } else {
            currentItemChild.isScorm = currentItemChild && currentItemChild.childObject && currentItemChild.childObject.itemType && (currentItemChild.childObject.itemType === ItemTypes.SCORM_FREE || currentItemChild.childObject.itemType === ItemTypes.SCORM_INVITE) ? true : false;
          }

          // Sottotipo
          currentItemChild.subtype = null;

          let itemAttributes = this.areIDataItems ? currentItemChild.itemAttributes : (currentItemChild.childObject && currentItemChild.childObject.itemAttributes);

          // Verifico glia ttributi per recueprare la tipologia di oggetto e le ore formative
          if (itemAttributes) {
            for (let k = 0; k < itemAttributes.length; k++) {
              let currentAttribute = itemAttributes[k];

              // Verifico il tipo di oggetto
              if (currentAttribute.attributeType === ItemAttributeTypes.OBJECT_TYPE) {
                currentItemChild.itemType = currentAttribute.attributeValue;
              }

              // Verifico se ha il Sottotipo
              if (currentAttribute.attributeType === ItemAttributeTypes.OBJECT_TYPE_SPECIALIZATION) {
                currentItemChild.subtype = currentAttribute.attributeValue;
              }

              // Verifico se c'è il valore formativo
              if (currentAttribute.attributeType === ItemAttributeTypes.VALUE) {
                let duration = moment.duration(parseInt(currentAttribute.attributeValue), 'seconds');
                currentItemChild.hourValue = {
                  hours: duration.minutes(),
                  minutes: duration.seconds()
                }
              }
            }
          }

          if (!currentItemChild.subtype) {
            if (currentItemChild.itemType) {
              currentItemChild.subtype = currentItemChild.itemType;
            } else if (!currentItemChild.itemType && currentItemChild.isScorm) {
              currentItemChild.subtype = ScormObjectTypes.SCORM;
            }
          }

          ItemUtil.setSubtypeLabelToItem(currentItemChild, this.translate);

          // Verifico gli engagement, cioè le azioni dell'utente sull'Item corrente per ricavare la percentuale
          ItemUtil.getPercentageAndCertificationDataOfItemChildOfSection(currentItemChild);
        }

        if (propedeuticReferenceIds.length) {
          for (let j = 0; j < this.item.itemChilds.length; j++) {
            let currentItemChild: any = this.item.itemChilds[j];

            currentItemChild.isPropedeutic = false;
            let checkPropedeuticReferenceIds = () => {
              for (let m = 0; m < propedeuticReferenceIds.length; m++) {
                if (propedeuticReferenceIds[m] == currentItemChild.referenceId) {
                  currentItemChild.isPropedeutic = true;
                  break;
                } else {
                  currentItemChild.isPropedeutic = false;
                }
              }
            }
            if (propedeuticReferenceIds) {
              checkPropedeuticReferenceIds();
            }

          }
        }

        // Qualora gli Item di questi referenceId fossero già stati completati, li rimuovo
        if (this.propedeuticReferenceIds && this.propedeuticReferenceIds.length && this.sectionList && this.sectionList.length && this.propedeuticReferenceId) {
          this.isItemDisabled = true;
          // Scorro tutti gli id propedeutici (anche se mi aspetto ce ne sia solo uno)
          for (let i = 0; i < this.propedeuticReferenceIds.length; i++) {
            // Scorro tutte altre sezioni del progetto in cerca dell'id
            for (let k = 0; k < this.sectionList.length; k++) {
              if (this.sectionList[k].itemId == this.propedeuticReferenceIds[i]
                // 2017/06/13 fix sblocco sezioni per referenceId propedeutico (attualmente ne viene gestito solo 1, quello su t_item_child)
                && this.propedeuticReferenceId == this.propedeuticReferenceIds[i]) {

                let myPropeduticSection = this.sectionList[k];
                if (myPropeduticSection.isConsumed) {
                  this.isItemDisabled = false;
                }
              }
            }
          }
        }

        // Recupero le info degli itemChilds per i contatori degli stati
        // Oggetti della sezione
        if (this.item.itemChilds) {
          for (let z = 0; z < this.item.itemChilds.length; z++) {
            let currentChild: any = this.item.itemChilds[z];
            const currentChildObject: IDataItem = <any>this.item.itemChilds[z].childObject;
            let isChildMandatory = !!currentChild.mandatory;

            if (currentChildObject) {
              if (isChildMandatory) {
                this.mandatoryItemChilds++;
              }
              if (currentChildObject.isConsumed) {
                if (isChildMandatory) {
                  this.consumedMandatorItemChilds++;
                }
                this.totalConsumedItemChilds++
              }
            }

            let blocked = false;
            // Se ha un propedeutico, verifico se è stato fatto o meno
            if (this.item.itemChilds[z].propedeuticReferenceId) {
              let isEnabled = false;
              for (let p = 0; p < this.item.itemChilds.length && !isEnabled; p++) {
                if (this.item.itemChilds[p].childObject && this.item.itemChilds[p].referenceId == this.item.itemChilds[z].propedeuticReferenceId) {
                  let otherChildObject: any = this.item.itemChilds[p].childObject;
                  if (otherChildObject.isConsumed) {
                    // Se l'oggetto associato è stato consumato, l'oggetto con il propedeuticReferenceId è sbloccato
                    isEnabled = true;
                    break;
                  }
                }
              }
              if (!isEnabled) {
                // Oggetto bloccato
                blocked = true;

              }
            }

            if (blocked) {
              this.totalDisabledObjInSection++;
            } else if (currentChildObject.isCertified) {
              this.totalCertifiedObjInSection++;
            } else {
              // Pallino vuoto, con nessuna azione ancora effettutata
              this.totalWithAnyActionObjInSection++;
            }
          }
        }

        let percentageAndCertificationData = ItemUtil.getPercentageAndCertificationData(this.item);
        if (percentageAndCertificationData) {
          this.percentageItem = percentageAndCertificationData.percentageItem;
          this.isConsumed = percentageAndCertificationData.isConsumed;
          this.isItemCertifiable = percentageAndCertificationData.isItemCertifiable;
          this.isItemAlreadyCertified = percentageAndCertificationData.isItemAlreadyCertified;
        }

        // Verifico se esiste una survey associata a questo oggetto
        if (!this.isItemAlreadyCertified && this.loggedUserId) {
          let childObjLibrary = this.item.itemChilds;
          let surveyPromise = ItemUtil.getSurveyStatusDataOfSection(this.loggedUserId, this.item.itemId, this.takerService, this.toastr, childObjLibrary);
          surveyPromise.then((data: { [itemId: string]: { isSurveyStarted: boolean, isSurveyCertificable: boolean, isItemCertifiable?: boolean } }) => {
            if (data) {
              this.totalSurveyMandatoryInSection = 0;
              this.completedSurvey = 0;
              this.surveyMap = data;
              for (let i = 0; i < childObjLibrary.length; i++) {
                //per ogni oggetto vado a controllare se è obbligatorio e se la survey è stata iniziata/da iniziare/completata per avere il numero Totale
                if (childObjLibrary[i].mandatory == 1 && (data[childObjLibrary[i].referenceId].isSurveyCertificable || data[childObjLibrary[i].referenceId].isSurveyStarted || childObjLibrary[i].childObject.isCertified)) {
                  this.totalSurveyMandatoryInSection++;
                  //Se l'oggetto assocciato alla survey ha, a true isCertified vuol dire che è stato completato
                  if (childObjLibrary[i].childObject.isCertified) {
                    this.completedSurvey++
                  }
                }
              }

            }
          })
            .catch(() => {
            })
        }
      }
    }
  }

  private checkPropedeuticReferenceIds() {
    if (this.propedeuticReferenceIds && this.propedeuticReferenceIds.length) {
      for (let m = 0; m < this.propedeuticReferenceIds.length; m++) {
        if (this.propedeuticReferenceIds[m] == this.fullItem.referenceId || this.propedeuticReferenceIds[m] == this.fullItem.itemId) {
          this.isPropedeutic = true;
          break;
        } else {
          this.isPropedeutic = false;
        }
      }
    }
  }

  // Porta alla pagina di dettaglio di un oggetto aggiunto alla sezione
  goToItemDetail(itemSecId: string) {
    this.router.navigate(['itemsAdmin/itemDetailSec', this.currentProject.itemId, this.item.itemId, itemSecId]);
  }

  // Porta all'edit della sezione
  goToEditSection() {
    if (this.isBlendedSection) {
      this.router.navigate(['itemsAdmin/editBlendedSection', this.currentProject.itemId, this.item.itemId]);
    } else {
      this.router.navigate(['itemsAdmin/editSection', this.currentProject.itemId, this.item.itemId]);
    }
  }

  // Il mandatory a db è un numero (0 / 1), ma all'md-switch serve un boolean. Così devo gestire manualmente lo switch
  changeMandatory() {
    if (this.isMandatory == true) {
      this.mandatory = 0;
      this.isMandatory = false;
    } else {
      this.mandatory = 1;
      this.isMandatory = true;
    }

    let eventData = {
      childItemId: this.item.itemId,
      mandatory: this.mandatory
    }

    this.itemChildMandatoryChanged.emit(eventData);
  }

  private getPropedeuticReferenceTitle = (item: Item) => {
    const currentItem = this.searchPropedeuticReferenceIdIn.find(prop => prop.referenceId === item.itemId);
    const propItem = this.searchPropedeuticReferenceIdIn.find(prop => prop.referenceId === currentItem.propedeuticReferenceId);

    return propItem.childObject.title;
  }

  // Verifica se un item era già stato checkato
  isItemChecked(item: ItemChild) {
    let isChecked = false;
    if (this.selectedItemChilds && this.selectedItemChilds.length) {
      for (let i = 0, itemsLength = this.selectedItemChilds.length; i < itemsLength; i++) {
        let currentItemChild = this.selectedItemChilds[i];
        if (currentItemChild && item && currentItemChild && currentItemChild.childObject
          && currentItemChild.childObject.itemId === item.childObject.itemId) {
          isChecked = true;
          break;
        }
      }
    }
    return isChecked;
  }

  // Emette l'evento che seleziona l'item child corrent
  emitItemChecked(selectedItem: ItemChild) {
    this.itemChildChecked.emit(selectedItem);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.propedeuticReferenceId && this.sectionItemChild && this.sectionItemChild.propedeuticReferenceId) {
      this.propedeuticTitle = this.getPropedeuticReferenceTitle(this.fullItem);

      this.prerequisites = {
        hasPrerequisites: this.propedeuticReferenceId ? true : false
      }
    }

    // Se non c'è il valore corrente, ma c'era prima, significa che ho tolto il check sulla propedeuticità
    if (changes.propedeuticReferenceId && changes.propedeuticReferenceId.previousValue && !changes.propedeuticReferenceId.currentValue) {
      this.prerequisites = {
        hasPrerequisites: this.propedeuticReferenceId ? true : false
      }
    }

    if (changes.propedeuticReferenceIds) {
      this.isPropedeutic = false;
      this.checkPropedeuticReferenceIds();
    }
  }
}