import { Item, ItemAttribute, ItemAttributeLang, ItemLang, ErrorCodes, SenecaResponse, ItemAttributeTypes, ItemTypes, ReferenceTypes, ItemAttributeObjectTypes, EngagementDetailKeys, CommonConstants, ItemChild, JwtPayload, SurveyStatuses, Survey, ItemFrontEndWrapper, ConsumableItem, ItemTenant, ItemRegistration } from "../../../cm2-commonclasses";
import { LangsService } from '../../core/services/langs.service';
import { ExportService } from "../services/export.service";
import { CourseEdition } from "./course.model";
import { TranslateService } from '@ngx-translate/core';
import { GlobalApplicationData } from './global-application-data.model';
import * as moment from 'moment';
import { ItemTaker, VideoSubtitle } from 'atfcore-commonclasses/bin/classes/item';

export class LibraryItemTags {
  public TECH_COMPETENCES: LibraryItemTag;
  public SOFT_COMPETENCES: LibraryItemTag;
  public LEVELS: LibraryItemTag;
  public LANG: LibraryItemTag;
  public ARGUMENTS: LibraryItemTag;
  public FUNCTIONAL_AREAS: LibraryItemTag;
  public COURSE_CATEGORY: LibraryItemTag;
  public CLUSTERS: LibraryItemTag;
  public STREAMS: LibraryItemTag;
  public ADMIN_TAGS: LibraryItemTag;

  constructor(TECH_COMPETENCES: LibraryItemTag,
    SOFT_COMPETENCES: LibraryItemTag,
    LEVELS: LibraryItemTag,
    LANG: LibraryItemTag,
    ARGUMENTS: LibraryItemTag,
    FUNCTIONAL_AREAS: LibraryItemTag,
    COURSE_CATEGORY: LibraryItemTag,
    CLUSTERS: LibraryItemTag,
    STREAMS: LibraryItemTag,
    ADMIN_TAGS: LibraryItemTag,
  ) {
    this.TECH_COMPETENCES = TECH_COMPETENCES;
    this.SOFT_COMPETENCES = SOFT_COMPETENCES;
    this.LEVELS = LEVELS;
    this.LANG = LANG;
    this.ARGUMENTS = ARGUMENTS;
    this.FUNCTIONAL_AREAS = FUNCTIONAL_AREAS;
    this.CLUSTERS = CLUSTERS;
    this.STREAMS = STREAMS;
    this.ADMIN_TAGS = ADMIN_TAGS;
    this.COURSE_CATEGORY = COURSE_CATEGORY;
  }
}
export class LibraryItemTag {
  public data: any;
  constructor(data: any) {
    this.data = data;
  }
}

export interface AdvancedTabInformations {
  TECH_COMPETENCES?: string[],
  SOFT_COMPETENCES?: string[],
  IS_TECH_SKILL?: boolean,
  IS_SOFT_SKILL?: boolean,
  TOPICS?: string[],
  FUNCTIONAL_AREAS?: string[],
  COURSE_CATEGORY?: string[],
  STREAMS?: string[],
  LEVELS?: string[],
  LANGS?: string[],
};

export interface IDataItem {
  isCourseCertificationEnabled: boolean;
  itemLangs?: ItemLang[];
  isItemOtherOrPhysicalType?: boolean;
  damPlayerSeekTo?: number;
  damPlayerVisible?: boolean;
  scormPlayerVisibile?: boolean;
  isProject?: boolean;
  isBlendedProject?: boolean;
  parentBlendedItems?: any[];
  parentBlendedStages?: any[];
  userEnrolledToEditions?: any[];
  availableEditionsCount?: boolean;
  isLearningPlan?: boolean;
  isLearningPlanBlended?: boolean;
  isVideo?: boolean;
  isImage?: boolean;
  azureImage?: string;
  extendedItem?: any;
  mimeType?: string;
  certificationDate?: any;
  categoryTitle?: string;
  hourValueText?: string;
  damObjectUrl?: string;
  damVideoSubtitle?: VideoSubtitle[];
  itemRegistration?: ItemRegistration;
  itemTenants?: ItemTenant[];
  customBgClass?: string;
  itemId: string;
  subtype?: string;
  cardCover?: string;
  itemType: string,
  name: string;
  subTitle?: string;
  description: string;
  imgBackground: string;
  place?: string;
  startDate?: string;
  itemAttributes?: ItemAttribute[];
  itemTypeLabel?: string;
  iconCode?: string;
  unicodeIcon?: string;
  hourValue?: string;
  scormRegistration?: any;
  cardTypeLabel?: string;
  placeName?: string;
  isAtomicItem?: boolean;
  isContainerItem?: boolean;
  isSyllabusCourse?: boolean;
  isScorm?: boolean;
  completionPercentage?: string;
  isCertified?: boolean;
  isConsumed?: boolean;
  stars?: { id: number, customColor: string }[];
  advancedTabInformations?: AdvancedTabInformations;
  itemChilds?: ItemChild[];
  childrenCount?: number;
  isInWishlist?: boolean;
  isBookmarked?: boolean;
  itemIds?: string[];
  hasRentableAttribute?: boolean;
  existsItemTakerVisibility?: boolean;
  itemTakers?: ItemTaker[];
  catalogItemRequestDate?: any;
  isInCatalog?: boolean;
  isActivePreiscrition?: boolean;
  startDatePreiscrition?: any;
  endDatePreiscrition?: any;
  onWhatPreiscrition?: any;
  competences?: any;
  presubscriptionEndedNoEdition?: boolean;
}


export function newItemsTagForFrontEnd() {
  return new LibraryItemTags({ data: [] }, { data: [] }, { data: null }, { data: [] }, { data: null }, { data: null }, { data: null }, { data: null }, { data: null }, { data: [] });
}


export function mapIDataItemToItem(item: IDataItem) {
  return <Item>{
    itemId: item.itemId,
    itemType: item.itemType,
    title: item.name,
    subTitle: item.subTitle,
    description: item.description,
    itemAttributes: item.itemAttributes,
    /* itemTenants: [<ItemTenant>{
        tenant
    }] */
  }
}

/*
* COMMON Item model Utility
*/
export class ItemUtil {

  constructor() {
    /* this.wrapper.getNativeMap().then((m) => {
    }); */
  }


  // Recupera le stelline (5) in base alla media dei like
  static getStars(item: ConsumableItem) {
    // Preparo il contenitore con le stelline
    let stars = [];
    if (item && item.itemId) {
      // Converto il numero in un intero
      item.averageLikingScore = Math.round(item.averageLikingScore);

      // "Star" è l'icona della stellina piena, "star_border" invece è la stellina con solo il bordo, senza l'interno colorato
      for (let i = 1; i <= 5; i++) {
        let newStar: any = {
          id: i,
          customIcon: i <= item.averageLikingScore ? "../../../assets/icons/icon-star.svg" : "../../../assets/icons/icon-star-o.svg"
        }

        stars.push(newStar);
      }
    }

    return stars;
  }

  // Recupera la traduzione della tipologia di item
  static getItemTypeLabel(item: Item, translate): string {
    let itemTypeLabel = null;
    let objectTypeAttribute = ItemUtil.getAttributeByKey(item, ItemAttributeTypes.OBJECT_TYPE);
    let objectTypeSpecializationAttribute = ItemUtil.getAttributeByKey(item, ItemAttributeTypes.OBJECT_TYPE_SPECIALIZATION);
    let itemType = objectTypeAttribute && objectTypeAttribute.attributeValue || item.itemType;
    let subtype = objectTypeSpecializationAttribute && objectTypeSpecializationAttribute.attributeValue;

    // Se non ho trovato un tipo di oggetto in deroga, lo setto automaticamente prendendo il valore dell'itemType
    if (!subtype && itemType) {
      subtype = itemType;
    }
    if (subtype && subtype !== "ASSESSMENT") {

      itemTypeLabel = translate.instant('generic.itemTypes.' + subtype);

    } else if (subtype === "ASSESSMENT") {
      itemTypeLabel = subtype;
    } else {

      itemTypeLabel = '-';
    }

    return itemTypeLabel;
  }

  // Recupera la traduzione della tipologia di item
  static getCardCover(item: Item): string {
    let cardCoverAttr = ItemUtil.getAttributeByKey(item, ItemAttributeTypes.CARD_COVER);
    return cardCoverAttr && cardCoverAttr.attributeValue || '';
  }

  // Verifica se un oggetto è un oggetto atomico
  static isAtomicItem(item: Item): boolean {
    return item && item.itemType && (
      item.itemType === ItemTypes.SCORM_FREE
      || item.itemType === ItemTypes.SCORM_INVITE
      || item.itemType === ItemTypes.SURVEY_ITEM
      || item.itemType === ItemTypes.CERTIFICATE_ITEM
      || item.itemType === ItemTypes.DAM_ITEM);
  }

  static getItemObjectType(item) {
    let objectTypeAttribute = ItemUtil.getAttributeByKey(item, ItemAttributeTypes.OBJECT_TYPE);
    return objectTypeAttribute && objectTypeAttribute.attributeValue;
  }

  static getItemObjectSubType(item) {
    let objectTypeSpecializationAttribute = ItemUtil.getAttributeByKey(item, ItemAttributeTypes.OBJECT_TYPE_SPECIALIZATION);
    let objectTypeAttribute = ItemUtil.getAttributeByKey(item, ItemAttributeTypes.OBJECT_TYPE);
    let itemType = objectTypeAttribute && objectTypeAttribute.attributeValue;
    let subtype = objectTypeSpecializationAttribute && objectTypeSpecializationAttribute.attributeValue;
    if (!subtype && itemType) {
      subtype = itemType;
    }
    return subtype;
  }

  static isDocumentItem(item) {
    let objectType = (ItemUtil.getAttributeByKey(item, ItemAttributeTypes.OBJECT_TYPE) || { attributeValue: null }).attributeValue;
    return item && item.itemType === ItemTypes.DAM_ITEM
      && item.itemAttributes
      // TODO: aggiungere i tipi corretti
      && [
        ItemAttributeObjectTypes.DOCUMENT
      ].indexOf(objectType) >= 0;
  }

  static isCertificateItem(item) {
    return item && item.itemType === ItemTypes.CERTIFICATE_ITEM;
  }

  static isSurveyItem(item) {
    return item && item.itemType === ItemTypes.SURVEY_ITEM;
  }

  static isExternalOnlineStage(item) {
    return item && item.itemType === ItemTypes.EXTERNAL_ONLINE_STAGE;
  }

  static isEventOnlineStage(item) {
    return item && item.itemType === ItemTypes.EVENT_ONLINE_STAGE;
  }

  static isExternalCourseStage(item) {
    return item && item.itemType === ItemTypes.EXTERNAL_COURSE_CLASS_STAGE;
  }

  static isEventClassStage(item) {
    return item && item.itemType === ItemTypes.EVENT_CLASS_STAGE;
  }

  static isAssessmentStage(item) {
    return item && item.itemType === ItemTypes.ASSESSMENT;
  }

  static isWebinarStage(item) {
    return item && item.itemType === ItemTypes.WEBINAR;
  }

  // Verifica se un oggetto è un oggetto atomico
  static isScormItem(item: Item): boolean {
    let subType = ItemUtil.getItemObjectSubType(item);
    let isScormSubType = subType === ItemAttributeObjectTypes.ELEARNING || subType === 'SCORM';

    return item && item.itemType && (item.itemType === ItemTypes.SCORM_FREE || item.itemType === ItemTypes.SCORM_INVITE) || isScormSubType;
  }

  // Verifica se un oggetto è un oggetto contenitore
  static isContainerItem(item: Item): boolean {
    return item && item.itemType && item.itemType === ItemTypes.CONTAINER;
  }

  // Verifica se un oggetto è un corso del syllabus
  static isSyllabusCourse(item: Item | IDataItem): boolean {
    return item && item.itemType && item.itemType === ItemTypes.COURSE_SYLLABUS;
  }

  // Recupera il valore formativo
  static getItemHourValue(item: Item, translate): string {
    let hourValue = null;
    let hourValueAttribute = item && item.itemType && item.itemType.startsWith("COURSE_") ? ItemUtil.getAttributeByKey(item, ItemAttributeTypes.DURATION) : ItemUtil.getAttributeByKey(item, ItemAttributeTypes.VALUE);
    let attrValue = hourValueAttribute && hourValueAttribute.attributeValue;
    let hourObj = {
      hours: null,
      minutes: null
    }

    if (attrValue) {
      let duration = moment.duration(parseInt(attrValue), 'seconds');
      hourObj.hours = duration && duration.minutes();
      hourObj.minutes = duration && duration.seconds()
    }

    if (hourObj.hours) {
      hourValue = hourObj.hours + translate.instant('hourValue.H').toLowerCase();
    }
    if (hourObj.minutes) {
      hourValue = hourValue + hourObj.minutes + translate.instant('hourValue.M').toLowerCase();
    }

    // Se non ci sono nè le ore nè i minuti, aggiungo un trattino
    if (!hourObj.hours && !hourObj.minutes) {
      hourValue = '';
    }

    return hourValue;
  }

  // Recupera la tipologia della card
  static getItemCardType(item: Item, translate): string {
    let itemCardType = null;
    let itemType = item && item.itemType;

    if (itemType) {
      if (itemType === ItemTypes.CONTAINER || itemType === ItemTypes.BLENDED_CONTAINER) {
        // Oggetto contenitore (learning plan, playlist), prendo la traduzione della tipologia
        // itemCardType = ItemUtil.getItemTypeLabel(item, translate);
        itemCardType = translate.instant('generic.ONLINE');
      } else if (itemType === ItemTypes.SCORM_FREE || itemType === ItemTypes.SCORM_INVITE || itemType === ItemTypes.DAM_ITEM
        || itemType === ItemTypes.COURSE_ONLINE_STAGE
        || itemType === ItemTypes.EXTERNAL_ONLINE_STAGE
        || itemType === ItemTypes.EVENT_ONLINE_STAGE
        || itemType === ItemTypes.ASSESSMENT
      ) {
        // Oggetto atomico, quindi 'Corso online'
        itemCardType = translate.instant('generic.ONLINE');
      } else if (itemType === ItemTypes.BLENDED_STAGE || itemType === ItemAttributeObjectTypes.BLENDED_PROJECT
      ) {
        // Oggetto blended
        itemCardType = translate.instant('generic.BLENDED_COURSE');
      } else if (itemType === ItemTypes.COURSE_SYLLABUS || itemType === ItemTypes.COURSE_CLASS_STAGE || itemType === ItemTypes.EXTERNAL_COURSE_CLASS_STAGE || itemType === ItemTypes.EVENT_CLASS_STAGE) {
        // Oggetto atomico, quindi 'Corso online'
        itemCardType = translate.instant('generic.IN_ROOM');
      }
    }

    return itemCardType;
  }

  // Verifica se è abilitata la wishlist
  static isWishlistEnabled(item: Item): boolean {
    let wishlistEnabled = false;
    let wishlistAttr: any = ItemUtil.getAttributeByKey(item, ItemAttributeTypes.WISHLIST_ENABLED);
    wishlistEnabled = wishlistAttr && wishlistAttr.attributeValue && (wishlistAttr.attributeValue == true || wishlistAttr.attributeValue == "true");
    return wishlistEnabled;
  }

  // Verifica se è abilitata nel catalogo
  static isInCatalog(item: Item): boolean {
    let isInCatalog = false;
    let inCatalogAttr: any = ItemUtil.getAttributeByKey(item, ItemAttributeTypes.AVAILABLE_IN_CATALOG);
    isInCatalog = inCatalogAttr && inCatalogAttr.attributeValue && (inCatalogAttr.attributeValue == true || inCatalogAttr.attributeValue == "true");
    return isInCatalog;
  }

  // Verifica se è abilitato il certificato nel corso
  static isCourseCertificationEnabled(item: Item): boolean {
    let wishlistEnabled = false;
    let wishlistAttr: any = ItemUtil.getAttributeByKey(item, ItemAttributeTypes.ENABLE_COURSE_CERTIFICATION);
    wishlistEnabled = wishlistAttr && wishlistAttr.attributeValue && (wishlistAttr.attributeValue == true || wishlistAttr.attributeValue == "true");
    return wishlistEnabled;
  }

  static getAttributeValue(item: Item | CourseEdition, key: string, order?: number): any {

    order = !isNaN(order) && order >= 0 ? order : 0;
    const attr = this.getAttributeByKeyAndOrder(item, key, order);
    const val = attr && attr.attributeValue;
    return val;
  }

  static getAttributeByKey(item: Item | CourseEdition | IDataItem, key: string): ItemAttribute {
    if (!item || !item.itemAttributes || !item.itemAttributes.length) {
      return null
    }

    for (let i = 0, attrsLength = item.itemAttributes.length; i < attrsLength; i++) {
      const currentAttribute = item.itemAttributes[i];
      if (currentAttribute && currentAttribute.attributeType && currentAttribute.attributeType === key) {
        return currentAttribute;
      }
    }
    return null;
  }

  static findAttributesByKey(item: Item, key: string): Array<ItemAttribute> {
    const attrs = new Array<ItemAttribute>();
    if (item && item.itemAttributes && item.itemAttributes.length) {
      for (let i = 0, attrsLength = item.itemAttributes.length; i < attrsLength; i++) {
        const currentAttribute = item.itemAttributes[i];
        if (currentAttribute.attributeType && currentAttribute.attributeType.includes(key)) {
          attrs.push(currentAttribute);
        }
      }
    }
    return attrs;
  }

  static getAttributesByKey(item: Item, key: string): Array<ItemAttribute> {
    const attrs = new Array<ItemAttribute>();
    if (item && item.itemAttributes && item.itemAttributes.length) {
      for (let i = 0, attrsLength = item.itemAttributes.length; i < attrsLength; i++) {
        const currentAttribute = item.itemAttributes[i];
        if (currentAttribute.attributeType === key) {
          attrs.push(currentAttribute);
        }
      }
    }
    return attrs;
  }

  static getAttributeByKeyAndOrder(item: Item | CourseEdition, key: string, order: number): ItemAttribute {
    if (item && item.itemAttributes && item.itemAttributes.length) {
      for (let i = 0, attrsLength = item.itemAttributes.length; i < attrsLength; i++) {
        const currentAttribute = item.itemAttributes[i];
        if ((currentAttribute.attributeType === key && currentAttribute.attributeOrder === order)) {
          return currentAttribute;
        }
      }
    }
    return null;
  }

  static getLastAttributeByOrderAndKey(item: Item, key: string): ItemAttribute {
    let lastAttributeByOrder: ItemAttribute;
    let lastOrder = 0; if (item && item.itemAttributes && item.itemAttributes.length) {
      for (let i = 0, attrsLength = item.itemAttributes.length; i < attrsLength; i++) {
        const currentAttribute = item.itemAttributes[i];
        if (currentAttribute.attributeType === key && currentAttribute.attributeOrder >= lastOrder) {
          lastAttributeByOrder = currentAttribute;
          lastOrder = currentAttribute.attributeOrder;
        }
      }
    }
    return lastAttributeByOrder;
  }


  static _normValue(val: any) {
    // normalizzo il valore del attributo, al momento normalizza solo i booleani
    let _valueNorm = val === true ? 'true'
      : val === false ? 'false' : val;
    return _valueNorm;
  }

  // aggiorna o aggiunge un itemAttribute, usato per creare/aggiornare attributi che non si ripetono
  static setKeyValueAttribute(item: Item, key: string, value: any, update?: boolean, order?: number, removeIfEmptyOrFalse?: boolean): ItemAttribute {
    let attribute = null;
    order = order || 0;
    if (!!removeIfEmptyOrFalse && !value) {
      ItemUtil.removeItemAttributeByKey(item, key);
    } else if (update) {
      attribute = ItemUtil.getAttributeByKeyAndOrder(item, key, order);
      if (!attribute && value) {
        attribute = ItemUtil._newAttr(key, this._normValue(value), order);
        if (!item.itemAttributes) {
          item.itemAttributes = [];
        }
        item.itemAttributes.push(attribute);
      } else if (attribute) {
        attribute.attributeType = key;
        attribute.attributeValue = this._normValue(value);
      }
    } else {
      attribute = ItemUtil._newAttr(key, this._normValue(value), order);
      if (!item.itemAttributes) {
        item.itemAttributes = [];
      }
      item.itemAttributes.push(attribute);
    }
    return attribute;
  }

  // aggiorna o aggiunge un itemAttribute attraverso key
  static setKeyValueAttributeByKey(item: Item, key: string, value: any, update?: boolean, order?: number, removeIfEmptyOrFalse?: boolean): ItemAttribute {
    let attribute = null;
    order = order || 0;

    if (!!removeIfEmptyOrFalse && !value) {
      ItemUtil.removeItemAttributeByKey(item, key);
    } else if (update) {
      attribute = ItemUtil.getAttributeByKey(item, key);
      if (!attribute && value) {
        attribute = ItemUtil._newAttr(key, this._normValue(value), order);
        if (!item.itemAttributes) {
          item.itemAttributes = [];
        }
        item.itemAttributes.push(attribute);
      } else if (attribute) {
        attribute.attributeType = key;
        attribute.attributeValue = this._normValue(value);
      }
    } else {
      attribute = ItemUtil._newAttr(key, this._normValue(value), order);
      if (!item.itemAttributes) {
        item.itemAttributes = [];
      }
      item.itemAttributes.push(attribute);
    }
    return attribute;
  }

  static _newAttr(key: string, value: any, order?: number,
    referenceType?: string, referenceId?: string, referenceApplicationName?: string) {
    return <ItemAttribute>{
      attributeId: null,
      attributeType: key,
      attributeValue: value,
      attributeOrder: (order || 0),
      attributeWeight: null,
      itemId: null,
      crossReferenceObject: null,
      referenceApplicationName: referenceApplicationName,
      referenceId: referenceId,
      referenceType: referenceType
    };
  }

  /**
   *
   */
  static mapAttributesToItem(item, attrKeys: string[], mapNullIfNotExists?: boolean) {
    // se trovo la chiave mappo l'attributo esistente
    attrKeys.forEach(key => {
      item[key] = ItemUtil.getAttributeByKey(item, key) || (!!mapNullIfNotExists ? null : ItemUtil.setKeyValueAttribute(item, key, null));
    });
  }

  static setAttributeWithReference(item: Item, key: string, referenceId: string, referenceType: string, update?: boolean, order?: number,
    referenceApplicationName?: string, attributeValue?, ignoreSearchByOrder?: boolean): ItemAttribute {
    let attribute: ItemAttribute = null;
    order = order || 0;
    if (update) {
      if (ignoreSearchByOrder) {
        if (item && item.itemAttributes) {
          attribute = item.itemAttributes.find((attr) => attr.attributeType == key && attr.referenceId == referenceId);
        }
        // attribute = ItemUtil.getAttributeByKey(item, key);
      } else {
        attribute = ItemUtil.getAttributeByKeyAndOrder(item, key, order);
      }
      if (!attribute && referenceId) {
        // Attribute non trovato
        attribute = ItemUtil._newAttr(key, undefined, order, referenceType, referenceId, referenceApplicationName);
        if (!item.itemAttributes) {
          item.itemAttributes = [];
        }
        item.itemAttributes.push(attribute);
      } else if (attribute) {
        // Aggiorno l'attribute con i valori richiesti
        attribute = {
          ...attribute,
          attributeType: key,
          attributeValue: attributeValue || undefined,
          attributeOrder: order,
          referenceId: referenceId,
          referenceType: referenceType,
          referenceApplicationName: referenceApplicationName
        };
      }
    } else {
      // Creo l'attribute
      attribute = ItemUtil._newAttr(key, attributeValue || undefined, order, referenceType, referenceId, referenceApplicationName);
      if (!item.itemAttributes) {
        item.itemAttributes = [];
      }
      item.itemAttributes.push(attribute);
    }
    return attribute;
  }

  // aggiunge/aggiorna un itemAttribute che può avere più occorrenze
  static setProgressiveKeyValueAttribute(item: Item, key: string, value: any, update?: boolean): ItemAttribute {
    // cerco l'attributeOrder maggiore per la chiave passata e incremento
    const lastAttributeByOrder: ItemAttribute = ItemUtil.getLastAttributeByOrderAndKey(item, key);
    let lastOrder = 0;
    if (lastAttributeByOrder) {
      lastOrder = lastAttributeByOrder.attributeOrder + 1;
    }
    return ItemUtil.setKeyValueAttribute(item, key, value, update, lastOrder);
  }

  // aggiunge/aggiorna un itemAttribute che può avere più occorrenze
  static setProgressiveAttributeWithReference(item: Item, key: string, referenceId: string, referenceType: string,
    update?: boolean, referenceApplicationName?: string, attributeValue?, ignoreSearchByOrder?: boolean): ItemAttribute {
    // cerco l'attributeOrder maggiore per la chiave passata e incremento
    const lastAttributeByOrder: ItemAttribute = ItemUtil.getLastAttributeByOrderAndKey(item, key);
    let lastOrder = 0;
    if (lastAttributeByOrder) {
      lastOrder = lastAttributeByOrder.attributeOrder + 1;
    }
    return ItemUtil.setAttributeWithReference(item, key, referenceId, referenceType, update, lastOrder, referenceApplicationName, attributeValue, ignoreSearchByOrder);
  }


  // Ritorna la lingua corrente dell'item
  static getItemLang(langsService: LangsService, applicationLang: string, item: Item): ItemLang {
    const initiativeIndex: number = langsService.findItemLangIndex(applicationLang.toLowerCase(), item);
    return item.itemLangs[initiativeIndex];
  }

  /**
   * Create a new ItemAttribute with an ItemAttributeLang with the given langCode-langValue.
   *  - if the langValue is empty so it creates one ItemAttributeLang with an empty value
   * @param itemId
   * @param attrKey
   * @param langCode
   * @param langValue
   * @param subtitle
   */
  static createAttributeWithLang(itemId, attrKey, langCode, langValue: string, subtitle?: string, customAttributeOrder?: number, title?: string) {
    if (title === undefined) {
      title = null;
    }
    const attributeLang = <ItemAttributeLang>{
      attributeId: null,
      description: langValue,
      langCode: langCode,
      subTitle: subtitle,
      title: title
    };
    return <ItemAttribute>{
      attributeId: null,
      itemId: itemId,
      attributeOrder: customAttributeOrder || 0,
      attributeType: attrKey,
      attributeValue: null,
      attributeWeight: null,
      crossReferenceObject: {
        tagLangs: [],
        tagTenants: [],
        title: null
      },
      attributeLangs: [attributeLang],
      referenceType: null,
      referenceApplicationName: null,
      referenceId: null
    };
  }

  static setKeyValueAttributeWithLang(item: Item, key: string, title: string, subTitle: string, description: string, lang: string, update: boolean, langIndex: number, order?: number): ItemAttribute {
    order = order || 0;
    let attribute: ItemAttribute = ItemUtil.getAttributeByKeyAndOrder(item, key, order);
    if (update && attribute) {
      // Aggiorno l'attribute con i valori richiesti
      attribute.attributeLangs[langIndex].title = title;
      attribute.attributeLangs[langIndex].description = description;
      attribute.attributeLangs[langIndex].subTitle = subTitle;
    } else {
      // Creo l'attribute
      attribute = ItemUtil.createAttributeWithLang(item.itemId, key, lang, description, subTitle, null, title);
      if (!item.itemAttributes) {
        item.itemAttributes = [];
      }
      item.itemAttributes.push(attribute);
    }
    return attribute;
  }

  static copyAttributesFromAttributeTypeToAnotherType(item: Item, originAttributeType, destinationAttributeType) {
    if (item && item.itemAttributes) {
      const originAttrs = ItemUtil.getAttributesByKey(item, originAttributeType);
      const destinationAttrs = ItemUtil.getAttributesByKey(item, destinationAttributeType);

      // se il numero di attributi di destinazione è maggiore di quelli della sorgente allora li rimuovo
      if (destinationAttrs.length > originAttrs.length && originAttrs.length) {
        for (let i = 0, attrsLength = destinationAttrs.length; i < attrsLength; i++) {
          ItemUtil.removeItemAttributeById(item, destinationAttrs[i]);
        }
      }

      if (originAttrs && originAttrs.length) {
        originAttrs.forEach((originAttr: ItemAttribute) => {
          let destinationAttr = ItemUtil.getAttributeByKeyAndOrder(item, destinationAttributeType, originAttr.attributeOrder);
          // se non lo trovo provo a cercare il primo senza order preciso
          destinationAttr = !destinationAttr ?
            ItemUtil.getAttributeByKey(item, destinationAttributeType)
            : destinationAttr;
          if (destinationAttr) {
            originAttr = { ...originAttr, attributeId: destinationAttr.attributeId, attributeType: destinationAttributeType };
            Object.assign(destinationAttr, originAttr);
          } else {
            destinationAttr = { ...originAttr, attributeId: null };
            destinationAttr.attributeType = destinationAttributeType;
            item.itemAttributes.push(destinationAttr);
          }
        });
      }
    }
  }

  static removeItemAttributeById(item: Item, itemAttribute: ItemAttribute) {
    const attrToRemove = itemAttribute.attributeId;
    for (let i = 0, attrsLength = item.itemAttributes.length; i < attrsLength; i++) {
      const currentItemAttr = item.itemAttributes[i];
      if (currentItemAttr.attributeId === attrToRemove) {
        item.itemAttributes.splice(i, 1);
        break;
      }
    }
  }

  /**
   * Rimuove il primo attributo trovato per la chiave data
   * @param item
   * @param attributeKey
   */
  static removeItemAttributeByKey(item: Item, attributeKey: string) {
    for (let i = 0, attrsLength = item.itemAttributes.length; i < attrsLength; i++) {
      const currentItemAttr = item.itemAttributes[i];
      if (currentItemAttr.attributeType === attributeKey) {
        item.itemAttributes.splice(i, 1);
        break;
      }
    }
  }

  static getItemChildById(item: Item, itemIdOfChild: string) {
    if (item && item.itemChilds && item.itemChilds.length && itemIdOfChild) {

      const _itemChild = item.itemChilds.find((child) => {
        return child && child.childObject && child.childObject.itemId === itemIdOfChild;
      });

      if (_itemChild) {
        // se ho trovato ritorno
        return _itemChild.childObject;
      } else {
        // altrimenti vado in profondità
        for (let i = 0, attrsLength = item.itemChilds.length; i < attrsLength; i++) {
          const child = item.itemChilds[i];
          const _item = child.childObject;
          if (_item && _item.itemChilds && _item.itemChilds.length) {
            const _foundItem = ItemUtil.getItemChildById(_item, itemIdOfChild);
            if (_foundItem) {
              return _foundItem;
            }
          }
        }
      }
    }
    return null;
  }

  static async downloadDamAttachmentOfItem(exportService: ExportService, materialItem: Item, userId: string, isSupplier: boolean, supplierId: string) {
    if (materialItem && materialItem && materialItem.itemChilds && materialItem.itemChilds.length) {
      let damAttachment = materialItem.itemChilds.find(child => !!child.childObject && child.childObject.itemType == ItemTypes.DAM_ATTACHMENT);
      if (damAttachment) {
        let externalObjectId = ItemUtil.getAttributeValue(damAttachment.childObject, ItemAttributeTypes.EXTERNAL_OBJECT_ID);
        exportService.retriveAttachmentUrl(userId, materialItem, externalObjectId, false, false, isSupplier, supplierId)
          .subscribe((senecaResponse: SenecaResponse<string>) => {
            if (senecaResponse && senecaResponse.error) {
              throw senecaResponse.error;
            } else {
              if (senecaResponse && senecaResponse.response) {
                setTimeout(() => {
                  document.location.assign(senecaResponse.response);
                }, 500)
              }
              return Promise.resolve();
            }
          });
      } else {
        throw ErrorCodes.OBJECT_NOT_FOUND;
      }
    } else {
      return Promise.resolve();
    }
  }


  // Recupera il nome del dominio di un link esterno
  static extractDomain(url: string) {
    let domain: any = null;

    // Rimuovo il protocollo (http, ftp ecc) e recupero l'hostname
    if (url.indexOf("://") > -1) {
      domain = url.split('/')[2];
    } else {
      domain = url.split('/')[0];
      // Tolgo la porta
      domain = domain.split(':')[0];

      // Rimuovo il punto di domanda
      domain = domain.split('?')[0];

      let splitArr = domain.split('.');
      let arrLen = splitArr.length;

      if (arrLen > 2) {
        domain = splitArr[arrLen - 2] + '.' + splitArr[arrLen - 1];
      }

      // Tolgo il suffisso
      domain = domain.replace(/\.[^/.]+$/, "")

      return domain;
    }
  }
  // Verifica se è abilitata nella preiscrizione
  static isActivePreiscrition(item: Item): boolean {
    let isActivePreiscrition = false;
    let isActivePreiscritionAttr: any = ItemUtil.getAttributeByKey(item, ItemAttributeTypes.PRE_REGISTRATION_ENABLED);
    isActivePreiscrition = isActivePreiscritionAttr && isActivePreiscritionAttr.attributeValue && (isActivePreiscritionAttr.attributeValue == true || isActivePreiscritionAttr.attributeValue == "true");
    return isActivePreiscrition;
  }

  // Start date nella preiscrizione
  static startDatePreiscrition(item: Item): boolean {
    let startDate = null;
    let startDateAttr: any = ItemUtil.getAttributeByKey(item, ItemAttributeTypes.PRE_REGISTRATION_START_DATE);
    startDate = startDateAttr && startDateAttr.attributeValue;
    return startDate;
  }

  // End date nella preiscrizione
  static endDatePreiscrition(item: Item): boolean {
    let endDate = null;
    let endDateAttr: any = ItemUtil.getAttributeByKey(item, ItemAttributeTypes.PRE_REGISTRATION_END_DATE);
    endDate = endDateAttr && endDateAttr.attributeValue;
    return endDate;
  }

  // DoveInteressa la preiscrizione
  static onWhatPreiscrition(item: Item): boolean {
    let onWhatPreiscrition = false;
    let onWhatPreiscritionAttr: any = ItemUtil.getAttributeByKey(item, ItemAttributeTypes.PRE_REGISTRATION_TYPE);
    onWhatPreiscrition = onWhatPreiscritionAttr && onWhatPreiscritionAttr.attributeValue;
    return onWhatPreiscrition;
  }

  // Verifica se l'oggetto rientra nella tipologia "altro", quindi se è un ebook, un documento o una sitografia
  static isOtherTypeItem(item) {
    let subType = ItemUtil.getItemObjectSubType(item);
    return subType && (
      subType === ItemAttributeObjectTypes.EBOOK ||
      subType == ItemAttributeObjectTypes.GRAPH ||
      subType == ItemAttributeObjectTypes.DOCUMENT);
  }

  static isCreatingOtherTypeItem(itemType: string) {
    if (itemType && (
      itemType === ItemAttributeObjectTypes.EBOOK ||
      itemType == ItemAttributeObjectTypes.GRAPH ||
      itemType == ItemAttributeObjectTypes.DOCUMENT)) {
      return true;
    }
    return false;
  }

  // Sostituisce gli a capo (impostati ad esempio in una textarea) con un '<br>'
  static replaceEnterInTitleSubtitleDescription(item: IDataItem) {
    if (item) {
      item.name = (item.name && item.name.replace(/(\r\n|\n|\r)/gm, "<br />")) || '';
      item.subTitle = (item.subTitle && item.subTitle.replace(/(\r\n|\n|\r)/gm, "<br />")) || '';
      // item.description = (item.description && item.description.replace(/(\r\n|\n|\r)/gm, "<br />")) || '';
    }
  }

  static removeTagFromItem(item: Item, selectedTag: any, removeCluster?: boolean, tagType?: string): void {
    if (item && item.itemAttributes && item.itemAttributes.length) {
      if (!selectedTag && removeCluster) {
        // Se non ho il tag vuol dire che devo rimuovere il cluster dall'item
        for (let i = 0, attrsLength = item.itemAttributes.length; i < attrsLength; i++) {
          let currentItemAttr = item.itemAttributes[i];
          if (currentItemAttr.referenceApplicationName === CommonConstants.APPLICATION_CORPORATE_ACADEMY && currentItemAttr.referenceType === 'TAG' && currentItemAttr.attributeType === tagType) {
            item.itemAttributes.splice(i, 1);
            break;
          }
        }
      }
      else if (selectedTag && selectedTag.value && selectedTag.value.tagId) {
        for (let i = 0, attrsLength = item.itemAttributes.length; i < attrsLength; i++) {
          let currentItemAttr = item.itemAttributes[i];
          if (currentItemAttr.referenceApplicationName === CommonConstants.APPLICATION_CORPORATE_ACADEMY && selectedTag.value.tagId == currentItemAttr.referenceId) {
            item.itemAttributes.splice(i, 1);
            break;
          }
        }
      } else {
        let tagToRemove = selectedTag.tagId ? selectedTag : selectedTag.value;
        for (let i = 0, attrsLength = item.itemAttributes.length; i < attrsLength; i++) {
          let currentItemAttr = item.itemAttributes[i];
          if (currentItemAttr.referenceId === tagToRemove.tagId) {
            item.itemAttributes.splice(i, 1);
            break;
          }
        }
      }
    }
  }

  static getAttributeLangByKey(item: Item, key: string, langCode: string) {
    const currentAttribute = ItemUtil.getAttributeByKey(item, key);
    if (currentAttribute && currentAttribute.attributeType === key &&
      currentAttribute.attributeLangs && currentAttribute.attributeLangs.length) {
      for (let k = 0, langsLength = currentAttribute.attributeLangs.length; k < langsLength; k++) {
        const currentAttributeLang = currentAttribute.attributeLangs[k];
        if (currentAttributeLang.langCode === langCode) {
          return currentAttributeLang;
        }
      }
    }
  }


  // Recupera i propedeuticReferenceId di tutti gli itemChilds
  static getPropedeuticReferenceIds(itemChilds: any[], removeConsumed?: boolean) {
    let propedeuticReferenceIds = [];
    if (itemChilds) {
      for (let i = 0; i < itemChilds.length; i++) {
        if (itemChilds[i].propedeuticReferenceId) {
          propedeuticReferenceIds.push(itemChilds[i].propedeuticReferenceId);
        }
      }

      for (let i = 0; i < itemChilds.length; i++) {
        if (itemChilds[i].propedeuticReferenceId) {
          // Qualora gli Item di questi referenceId fossero già stati completati, li rimuovo
          if (propedeuticReferenceIds.length && removeConsumed) {
            for (let i = 0; i < propedeuticReferenceIds.length; i++) {
              for (let k = itemChilds.length - 1; k >= 0; k--) {
                if (itemChilds[k].childObject && itemChilds[k].childObject.engagements) {
                  for (let z = 0; z < itemChilds[k].childObject.engagements.length; z++) {
                    if (itemChilds[k].referenceId === propedeuticReferenceIds[i] && itemChilds[k].childObject.engagements[z].eventName === ReferenceTypes.EVENT_ITEM_CONSUMED) {
                      // Rimuovo il propedeuticReferenceId dell'item che ora è sbloccato
                      for (let g = 0; g < itemChilds.length; g++) {
                        if (itemChilds[g].propedeuticReferenceId === propedeuticReferenceIds[i]) {
                          itemChilds[g].propedeuticReferenceId = null;
                          break;
                        }
                      }
                      break;
                    }
                  }
                } else if (itemChilds[k].childObject && itemChilds[k].childObject.isConsumed && itemChilds[k].referenceId === propedeuticReferenceIds[i]) {
                  // Rimuovo il propedeuticReferenceId dell'item che ora è sbloccato
                  for (let g = 0; g < itemChilds.length; g++) {
                    if (itemChilds[g].propedeuticReferenceId === propedeuticReferenceIds[i]) {
                      itemChilds[g].propedeuticReferenceId = null;
                      break;
                    }
                  }
                }
              }
            }
          }
          return propedeuticReferenceIds;
        }
      }
    }
  }

  // Recupera i dati sullo scorm e dam di un item
  static getItemDamScormData(itemDetails: ItemFrontEndWrapper) {
    let data = {
      damPlayerVisible: false,
      damPlayerScript: null,
      damPlayerSeekTo: null,
      mimeType: null,
      damObjectUrl: null,
      damVideoSubtitle: null,
      scormPlayerVisibile: false
    };
    if (itemDetails) {
      if (itemDetails && itemDetails.isDam) {
        data.damPlayerVisible = true;
        data.damPlayerScript = itemDetails.embeddedPlayerCode;
        data.mimeType = itemDetails.mimeType;
        data.damObjectUrl = itemDetails.damObjectUrl;
        data.damVideoSubtitle = itemDetails.videoSubtitles;
        if (itemDetails.item && itemDetails.item.itemRegistration && itemDetails.item.itemRegistration) {
          data.damPlayerSeekTo = itemDetails.item.itemRegistration.currentSeconds;
        } else {
          if (itemDetails.item && itemDetails.item.engagements) {
            for (let i = 0; i < itemDetails.item.engagements.length; i++) {
              let e = itemDetails.item.engagements[i];
              // Se non l'ho già trovato, cerco l'ultima posizione del player (gli engagement sono in ordine temporale decrescente, quindi il primo che trovo è quello buono)
              if (!data.damPlayerSeekTo && e.moduleName === ReferenceTypes.ITEM && (e.eventName === ReferenceTypes.EVENT_ITEM_STARTED || e.eventName === ReferenceTypes.EVENT_ITEM_CONSUMED)) {
                if (e.engagementDetails) {
                  for (let j = 0; j < e.engagementDetails.length; j++) {
                    if (e.engagementDetails[j].detailKey === EngagementDetailKeys.CURRENT_TIME) {
                      data.damPlayerSeekTo = parseFloat(e.engagementDetails[j].detailValue);
                      break;
                    }
                  }
                }
              }
            }
          }
        }
      }
      else if (itemDetails && itemDetails.isScorm) {
        data.scormPlayerVisibile = true;
      }
    }
    return data;
  }

  // Recupera una lista di sottotipi
  static getItemSubtypesList(translate: TranslateService) {
    let l = [
      { code: null, desc: translate.instant("generic.NONE") },
      { code: ItemAttributeObjectTypes.PODCAST, desc: <string>null },
      { code: ItemAttributeObjectTypes.GRAPH, desc: <string>null },
      { code: ItemAttributeObjectTypes.DOCUMENT, desc: <string>null },
      { code: ItemAttributeObjectTypes.EBOOK, desc: <string>null },
      { code: ItemAttributeObjectTypes.ELEARNING, desc: <string>null },
      { code: ItemAttributeObjectTypes.VIDEO, desc: <string>null },
      { code: ItemAttributeObjectTypes.IMAGE, desc: <string>null },
      { code: ItemAttributeObjectTypes.SURVEY, desc: <string>null },
      { code: ItemAttributeObjectTypes.LINK, desc: <string>null }
    ];
    for (let i = 0; i < l.length; i++) {
      if (l[i].code) {
        l[i].desc = translate.instant("generic.itemTypes." + l[i].code);
      }
    }
    return l;
  }

  // Recupera una survey associata ad un item, nonché lo status
  static checkIfChildsAreNotDisabled(itemChilds: any[]) {
    if (itemChilds && itemChilds.length) {
      for (let k = 0; k < itemChilds.length; k++) {
        let currentObj: any = itemChilds[k];
        if (currentObj.childObject) {
          // Se ha un oggetto propedeutico, lo cerco
          if (currentObj.propedeuticReferenceId) {
            for (let z = 0; z < itemChilds.length; z++) {
              if (itemChilds[z].childObject && itemChilds[z].referenceId === currentObj.propedeuticReferenceId) {
                if (itemChilds[z].childObject.engagements && itemChilds[z].childObject.engagements.length) {
                  for (let j = 0; j < itemChilds[z].childObject.engagements.length; j++) {
                    if (itemChilds[z].childObject.engagements[j].eventName === ReferenceTypes.EVENT_ITEM_CONSUMED) {
                      // Se l'oggetto associato è stato consumato, l'oggetto con il propedeuticReferenceId è sbloccato
                      currentObj.isNotDisabled = true;
                      break;
                    }
                  }
                } else if (itemChilds[z].childObject.isConsumed) {
                  currentObj.isNotDisabled = true;
                }
              }
            }
          }
        }
      }
    }
  }

  // Recupera le survey associate ai figli di un item contenitore (ad esempio gli item aggiunti alla playlist)
  static getSurveyStatusOfItemChildsData(userId: string, itemChilds: ItemChild[], surveyService, toastr, translate, counter: number) {
    let promises = [];
    if (itemChilds && itemChilds.length) {
      for (let l = 0, childsLength = itemChilds.length; l < childsLength; l++) {
        let currentItemChild = itemChilds[l];
        promises.push(new Promise((resolve: Function, reject: Function) => {
          surveyService.getSurveys(userId, currentItemChild.referenceId)
            .subscribe(
              (data: SenecaResponse<Survey>) => {
                if (data.error) {
                  toastr.error(translate.instant('errors.' + data.error));
                  reject();
                } else {
                  if (data.response && data.response.surveyStatus) {
                    if (data.response.surveyStatus === SurveyStatuses.STARTED || data.response.surveyStatus === SurveyStatuses.VALID) {
                      counter++;
                    }
                  }
                  resolve(data.response);
                }
              })
        }));
      }
    }
    return Promise.all(promises);
  }
  //recupera tutte le survey presenti nelle sezioni
  static getSurveyStatusDataOfSection(userId: string, referenceId: string, surveyService, toastr, itemChilds?: any[]) {
    let dataMap: { [itemId: string]: { isSurveyStarted?: boolean, isSurveyCertificable?: boolean, isItemCertifiable?: boolean } };
    dataMap = {};
    return new Promise((resolve: Function, reject: Function) => {
      let promises = [];
      for (let i = 0; i < itemChilds.length; i++) {
        let currentItemChild = itemChilds[i];
        let surveyPromise = ItemUtil.getSurveyStatusData(userId, currentItemChild.referenceId, surveyService, toastr, itemChilds[i]);
        promises.push(
          surveyPromise.then((data: { isSurveyStarted: boolean, isSurveyCertificable: boolean, isItemCertifiable?: boolean }) => {
            if (data) {
              dataMap[currentItemChild.referenceId] = data;
            } else {
              reject()
            }
          })
        );
      }
      Promise.all(promises)
        .then(() => {
          return resolve(dataMap)
        });
    });
  }

  // Recupera una survey associata ad un item, nonché lo status
  static getSurveyStatusData(userId: string, referenceId: string, surveyService, toastr, itemChilds?: any[]) {
    return new Promise((resolve, reject) => {
      surveyService.getSurveys(userId, referenceId)
        .subscribe(data => {
          let isSurveyStarted: boolean = false;
          let isSurveyCertificable: boolean = false;
          let isItemCertifiable: boolean = false;
          // Se ci sono errori, li mostor e torno alla lista dei template
          if (data.error) {
            toastr.error(data.error);
            reject();
          } else {
            if (data.response && data.response.surveyStatus) {
              if (data.response.surveyStatus === SurveyStatuses.STARTED) {
                // Survey iniziata
                isSurveyStarted = true;
              } else if (data.response.surveyStatus === SurveyStatuses.VALID) {
                // Se lo status è valido, significa che ho una survey certificabile
                isSurveyCertificable = true;
              }
            }
            if (itemChilds && itemChilds.length) {
              let mandatoryItems = 0;
              let consumedMandatoryItems = 0;
              // E verifico se posso effettuare la certificazione
              for (let k = 0; k < itemChilds.length; k++) {
                let currentObj = itemChilds[k];
                let isMandatory = !!currentObj.mandatory;
                if (currentObj.childObject && isMandatory) {
                  mandatoryItems++;
                  if (currentObj.childObject.engagements && currentObj.childObject.engagements.length) {
                    for (let j = 0; j < currentObj.childObject.engagements.length; j++) {
                      if (currentObj.childObject.engagements[j].eventName === ReferenceTypes.EVENT_ITEM_CONSUMED) {
                        consumedMandatoryItems++;
                        break;
                      }
                    }
                  } else if (currentObj.childObject.isConsumed) {
                    consumedMandatoryItems++;
                  }
                }
              }
              if (mandatoryItems == consumedMandatoryItems) {
                isItemCertifiable = true;
              }
            } else {
              // In questo caso sono in un oggetto atomico
              isItemCertifiable = true;
            }
            let objData = {
              isSurveyStarted: isSurveyStarted,
              isSurveyCertificable: isSurveyCertificable,
              isItemCertifiable: isItemCertifiable
            }
            resolve(objData);
          }
        });
    })
  }


  // Recupera una survey associata ad un item, nonché lo status
  static getSurveysTemplateByReferenceId(referenceId: string, surveyService, toastr) {
    return new Promise((resolve, reject) => {
      surveyService.getSurveysTemplateByReferenceId(referenceId)
        .subscribe(data => {
          // Se ci sono errori, li mostor e torno alla lista dei template
          if (data.error) {
            toastr.error(data.error);
            reject();
          } else {
            let objData = {
              uploadedSurveyTemplates: data.response,
              isThereSurvey: data.response && data.response.length ? true : false
            }
            resolve(objData);
          }
        });
    })
  }

  static getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  }

  // Recupera il contatore card cover
  static countCardCovers(itemsService, toastr, title?: string, ignoreVisibility?: boolean, totalCardCoverCount?: number, originApplicationName?: string) {
    return new Promise((resolve, reject) => {
      if (totalCardCoverCount) {
        resolve(totalCardCoverCount);
      } else {
        itemsService.countCardCovers(title, ignoreVisibility, originApplicationName)
          .subscribe(data => {
            // Se ci sono errori, li mostor e torno alla lista dei template
            if (data.error) {
              toastr.error(data.error);
              reject();
            } else {
              resolve(data.response);
            }
          });
      }
    })
  }

  // Recupera una lista di card cover
  static getCardCovers(itemsService, toastr, fromRecord: number, numRecords: number, title?: string, sorting?: string, itemTypes?: string | string[], ignoreVisibility?: boolean, originApplicationName?: string, skipImageSize?: boolean) {
    return new Promise((resolve, reject) => {
      itemsService.getCardCovers(fromRecord, numRecords, title, sorting, itemTypes, ignoreVisibility, originApplicationName, skipImageSize)
        .subscribe(data => {
          // Se ci sono errori, li mostor e torno alla lista dei template
          if (data.error) {
            toastr.error(data.error);
            reject();
          } else {
            resolve(data);
          }
        });
    })
  }

  // Recupera le informazioni sui like/dislike totali di un oggetto
  static getLikesCountsByTypesAndReference(referenceId: string, referenceType: string, likeType: string, itemService, toastr) {
    return new Promise((resolve, reject) => {
      itemService.getLikesCountsByTypesAndReference(referenceId, referenceType, likeType, CommonConstants.APPLICATION_CORPORATE_ACADEMY, CommonConstants.APPLICATION_CORPORATE_ACADEMY)
        .subscribe(data => {
          // Se ci sono errori, li mostor e torno alla lista dei template
          if (data.error) {
            toastr.error(data.error);
            reject();
          } else {
            let objData = {
              likes: data.response.likes,
              dislikes: data.response.dislikes
            }
            // Salvo i like
            objData.likes = data.response.likes;

            // Salvo i dislike
            objData.dislikes = data.response.dislikes;

            resolve(objData);
          }
        });
    })
  }

  // Recupera le informazioni sui tenant
  static getTenantsByIds(tenantIds: string[], itemService, toastr) {
    return new Promise((resolve, reject) => {
      itemService.getTenantsByIds(tenantIds)
        .subscribe(data => {
          // Se ci sono errori, li mostor e torno alla lista dei template
          if (data.error) {
            toastr.error(data.error);
            reject();
          } else {
            resolve(data.response);
          }
        });
    })
  }

  // Verifica se la tipologia è di tipo 'altro' (ebook, sitografia, documento)
  static isOtherType(typology: string) {
    if (typology && (
      typology === ItemAttributeObjectTypes.EBOOK ||
      typology === ItemAttributeObjectTypes.GRAPH ||
      typology === ItemAttributeObjectTypes.DOCUMENT
    )) {
      return true;
    }

    return false;
  }

  // Verifica se l'oggetto rientra nella tipologia dell'immagine
  static isImage(item: IDataItem | ConsumableItem) {
    let subType = ItemUtil.getItemObjectSubType(item);
    return subType === ItemAttributeObjectTypes.IMAGE;
  }

  // Verifica se l'oggetto rientra nella tipologia del video
  static isVideo(item: IDataItem | ConsumableItem) {
    let subType = ItemUtil.getItemObjectSubType(item);
    return subType === ItemAttributeObjectTypes.VIDEO;
  }

  // Verifica se l'oggetto rientra nella tipologia dei progetti
  static isProject(item: IDataItem | ConsumableItem) {
    let subType = ItemUtil.getItemObjectSubType(item);
    return subType === ItemAttributeObjectTypes.PROJECT;
  }

  static isCourseClassStage(item: IDataItem | ConsumableItem) {
    return item && item.itemType && item.itemType === ItemTypes.COURSE_CLASS_STAGE;
  }

  // Verifica se l'oggetto rientra nella tipologia dei progetti blended
  static isBlendedProject(item: IDataItem | ConsumableItem) {
    let subType = ItemUtil.getItemObjectSubType(item);
    return subType === ItemAttributeObjectTypes.BLENDED_PROJECT;
  }

  // Verifica se l'oggetto rientra nella tipologia dei learning plan
  static isPlaylistPlan(item: IDataItem | ConsumableItem) {
    let subType = ItemUtil.getItemObjectSubType(item);
    return subType === ItemAttributeObjectTypes.LEARNING_PLAN;
  }

  static isLearningPlan(item: IDataItem | ConsumableItem) {
    let subType = ItemUtil.getItemObjectSubType(item);
    return subType === ItemAttributeObjectTypes.PROJECT || subType === ItemAttributeObjectTypes.BLENDED_PROJECT;
  }


  // Verifica se l'oggetto rientra nella tipologia di oggetti fisici, quindi se è un libro, un dvd o una rivista
  static isPhysicalItem(item: IDataItem | ConsumableItem) {
    let subType = ItemUtil.getItemObjectSubType(item);
    return subType && (
      subType === ItemAttributeObjectTypes.BOOK ||
      subType == ItemAttributeObjectTypes.DVD ||
      subType == ItemAttributeObjectTypes.MAGAZINE);
  }

  // Verifica se l'oggetto rientra nella tipologia dei multimedia (video, immagine, podcast)
  static isMultimediaItem(itemType: string) {
    if (itemType && (
      itemType === ItemAttributeObjectTypes.VIDEO ||
      itemType == ItemAttributeObjectTypes.IMAGE ||
      itemType == ItemAttributeObjectTypes.PODCAST)) {
      return true;
    }
    return false;
  }

  // Recupera il titolo dell'oggetto propedeutico a quello selezionato
  static getPropedeuticReferenceTitle(allParentChilds: any[], selectedItem: ItemChild, propedeuticId?: string) {
    let propedeuticReferenceId;
    if (!!propedeuticId) {
      propedeuticReferenceId = propedeuticId;
    } else {
      propedeuticReferenceId = selectedItem && selectedItem.propedeuticReferenceId;
    }
    if (propedeuticReferenceId) {
      for (let i = 0; i < allParentChilds.length; i++) {
        if (allParentChilds[i].referenceId === propedeuticReferenceId) {
          return (allParentChilds[i].childObject && allParentChilds[i].childObject.title) || '';
        }
      }
    }
  }

  // Funzione che cerca finchè non trova l'item propedeutico in cima alla lista
  static searchPropedeuticalObject(fullItem: any, propedeuticalObject: any) {
    for (let i = 0; i < fullItem.itemChilds.length; i++) {
      if (fullItem.itemChilds[i].referenceId === propedeuticalObject.propedeuticReferenceId) {
        // Verifico se questo oggetto è già stato consumato
        let check = fullItem.itemChilds[i];
        let consumed = false;
        if (check && check.childObject && check.childObject) {
          if (check.childObject.engagements && check.childObject.engagements.length) {
            for (let k = 0; k < check.childObject.engagements.length; k++) {
              if (check.childObject.engagements[k].eventName === ReferenceTypes.EVENT_ITEM_CONSUMED) {
                consumed = true;
                break;
              }
            }
          } else if (check.childObject.isConsumed) {
            consumed = true;
          }
        }
        if (!consumed) {
          propedeuticalObject = fullItem.itemChilds[i];
          // se per questo elemento è necessario sbloccarne un altro, lo cerco
          if (propedeuticalObject.propedeuticReferenceId) {
            return this.searchPropedeuticalObject(fullItem, propedeuticalObject);
          }
          break;
        }
      }
    }
  }

  // Torna un parametro indicate il fatto che l'utente loggato è owner del tenant a cui appartiene l'item
  static isUserOwnerTenantOfItem(loggedUser: JwtPayload, item: IDataItem): boolean {
    // Recupero il tenant dell'item
    let adminTenantOfItem: any = null;
    let isTenantOwner = false;
    if (loggedUser && loggedUser.user && loggedUser.user.userTenant && loggedUser.user.userTenant.adminTenants && loggedUser.user.userTenant.adminTenants.length
      && item && item.itemTenants && item.itemTenants.length) {
      for (let i = 0, tenantsLength = item.itemTenants.length; i < tenantsLength; i++) {
        let currentTenant = item.itemTenants[i];
        if (currentTenant.owner) {
          adminTenantOfItem = currentTenant.tenant;
          break;
        }
      }
    }

    if (adminTenantOfItem) {
      for (let i = 0, tenantsLength = loggedUser.user.userTenant.adminTenants.length; i < tenantsLength; i++) {
        let currentAdminUserTenant = loggedUser.user.userTenant.adminTenants[i];
        if (currentAdminUserTenant === adminTenantOfItem) {
          isTenantOwner = true;
          break;
        }
      }
    }
    return isTenantOwner;
  }

  // Rimuove gli itemChild selezionati dall'oggetto contenitore
  static removeSelectedChildsFromContainer(itemContainer: Item, selectedItemChilds: ItemChild[]) {
    if (itemContainer && itemContainer.itemChilds && selectedItemChilds && selectedItemChilds.length)
      for (let i = 0; i < selectedItemChilds.length; i++) {
        for (let j = itemContainer.itemChilds.length - 1; j >= 0; j--) {
          if (itemContainer.itemChilds[j].referenceId == selectedItemChilds[i].referenceId) {
            itemContainer.itemChilds.splice(j, 1);
            break;
          }
        }
      }

    // Riordino i child item
    for (let k = 0; k < itemContainer.itemChilds.length; k++) {
      itemContainer.itemChilds[k].childOrder = k + 1;
    }
    // Svuoto i selezionati
    selectedItemChilds.length = 0;
  }

  // Cerco il primo oggetto propedeutico
  static getFirstPropedeuticalItem(fullItem: any, selectedItem: ItemChild): ItemChild {
    let propedeuticalObject: ItemChild = null;
    if (fullItem && fullItem.itemChilds && fullItem.itemChilds.length && selectedItem && selectedItem.propedeuticReferenceId) {
      for (let i = 0; i < fullItem.itemChilds.length; i++) {
        if (fullItem.itemChilds[i].referenceId === selectedItem.propedeuticReferenceId) {
          // Verifico se questo oggetto è già stato consumato
          let check = fullItem.itemChilds[i];
          let consumed = false;
          if (check && check.childObject && check.childObject) {
            if (check.childObject.engagements && check.childObject.engagements.length) {
              for (let k = 0; k < check.childObject.engagements.length; k++) {
                if (check.childObject.engagements[k].eventName === ReferenceTypes.EVENT_ITEM_CONSUMED) {
                  consumed = true;
                  break;
                }
              }
            } else if (check.childObject.isConsumed) {
              consumed = true;
            }
          }
          if (!consumed) {
            propedeuticalObject = fullItem.itemChilds[i];
            break;
          }
        }
      }
    }

    return propedeuticalObject;
  }

  // Recupera un allegato
  static getAttachmentUrl(attachmentId: string, adminMode: boolean, item, forcedItemIdEngagement: string, forceCreateConsumedEngagement: boolean, isItemOtherType: boolean, itemService, toastr) {
    return new Promise((resolve, reject) => {
      let derivedItem = mapIDataItemToItem(<IDataItem>item);
      itemService.getAttachmentUrl(attachmentId, adminMode, derivedItem, forcedItemIdEngagement, forceCreateConsumedEngagement, isItemOtherType)
        .subscribe(data => {
          // Se ci sono errori, li mostor e torno alla lista dei template
          if (data.error) {
            toastr.error(data.error);
            reject();
          } else {
            resolve(data.response);
          }
        });
    })
  }

  static getExternalResourceName(requestUrl: string, requestName: string, applicationData: GlobalApplicationData) {
    return applicationData.applicationContext + 'rest-api/corporateacademy-mediator/proxy-url/' + encodeURIComponent(requestName) + '?requestUrl=' + encodeURIComponent(requestUrl);
  }

  static getDownloadTempFileUrl(filename: string, applicationData: GlobalApplicationData, customName?: string) {
    let url = applicationData.applicationContext + 'rest-api/corporateacademy-mediator/download-temp-file?filename=' + filename;
    if (customName && customName.length) {
      url = url + '&customName=' + customName;
    }
    return url
  }

  static getPublicCertificate(userId: string, itemId: string, applicationData: GlobalApplicationData) {
    return applicationData.applicationContext + 'rest-api/corporateacademy-mediator/certificate/' + userId + '/' + itemId;
  }

  // Recupera le informazioni sui like/dislike totali dell'utente loggato e che sta visualizzando un oggetto
  static getMyLikeByTypeAndReference(referenceId: string, referenceType: string, likeType: string, itemService, toastr) {
    return new Promise((resolve, reject) => {
      itemService.getMyLikeByTypeAndReference(referenceId, referenceType, likeType, CommonConstants.APPLICATION_CORPORATE_ACADEMY, CommonConstants.APPLICATION_CORPORATE_ACADEMY)
        .subscribe(data => {
          // Se ci sono errori, li mostor e torno alla lista dei template
          if (data.error) {
            toastr.error(data.error);
            reject();
          } else {
            let objData = {
              currentLike: null,
              iLikeThis: false,
              iDislikeThis: false
            };

            // Salvo il like attuale
            objData.currentLike = data.response;

            // Verifico se è un like o un dislike
            if (objData.currentLike && objData.currentLike.likeSign == "+") {
              objData.iLikeThis = true;
            } else if (objData.currentLike && objData.currentLike.likeSign == "-") {
              objData.iDislikeThis = true;
            }

            resolve(objData);
          }
        });
    })
  }

  // Torna i dati sulla percentuale di avanzamento e i dati sulla certificazione di oggetti dentro ad una sezione
  static getPercentageAndCertificationDataOfItemChildOfSection(currentItemChild) {
    // Verifico gli engagement, cioè le azioni dell'utente sull'Item corrente per ricavare la percentuale
    if (currentItemChild.childObject) {
      const isCourseClassStage = ItemUtil.isCourseClassStage(currentItemChild.childObject);
      const isExternalOnlineStage = ItemUtil.isExternalOnlineStage(currentItemChild.childObject);
      const isExternalCourseStage = ItemUtil.isExternalCourseStage(currentItemChild.childObject);
      const isEventOnlineStage = ItemUtil.isEventOnlineStage(currentItemChild.childObject);
      const isEventClassStage = ItemUtil.isEventClassStage(currentItemChild.childObject);
      const isAssessmentStage = ItemUtil.isAssessmentStage(currentItemChild.childObject);
      const isWebinarStage = ItemUtil.isWebinarStage(currentItemChild.childObject);

      let completionPercentage = '';
      if (!isCourseClassStage && !isExternalOnlineStage && !isWebinarStage && !isEventOnlineStage && !isAssessmentStage && !isEventClassStage && !isExternalCourseStage) {
        completionPercentage = (currentItemChild.childObject.completionPercentage || '0') + '%';
      } else {
        // Devo forzare manualmente il currentItemChild.childObject.completionPercentage passato dal backend affinché non si veda nelle card
        currentItemChild.childObject.completionPercentage = '';
      }

      currentItemChild.percentageItem = completionPercentage;
      currentItemChild.isConsumed = currentItemChild.childObject.isConsumed;
      currentItemChild.isItemAlreadyCertified = currentItemChild.childObject.isCertified;
    }
  }

  // Torna i dati sulla percentuale di avanzamento e i dati sulla certificazione
  static getPercentageAndCertificationData(itemDetails) {
    // Data dell'evento EVENT_ITEM_STARTED
    let dateEventItemStarted: any = null;
    // Data dell'evento EVENT_ITEM_CONSUMED
    let dateEventItemConsumed: any = null;
    // Percentuale di avanzamento, cioè quella fornita dall'EVENT_ITEM_STARTED
    let percentageEventItemStarted: any = null;

    let isCertified = false;
    let isConsumed = false;
    let isItemCertifiable = false;
    let isItemOpen = false;
    let isItemAlreadyCertified = false;
    let certifiedDate = null;

    let itemType = null;
    let itemRef = null;
    if (itemDetails) {
      if (itemDetails.item) {
        itemRef = itemDetails.item;
      } else if (itemDetails.childObject) {
        itemRef = itemDetails.childObject;
      } else {
        itemRef = itemDetails;
      }
    }

    if (itemRef) {
      itemType = itemRef.itemType;
    }

    let percentageItem = itemRef.completionPercentage;

    // Verifico gli engagement, cioè le azioni dell'utente sull'Item corrente
    if (itemRef.engagements && itemRef.engagements.length) {
      for (let d = 0; d < itemRef.engagements.length; d++) {
        let currentEngagement = itemRef.engagements[d];
        if (currentEngagement.moduleName === ReferenceTypes.ITEM) {
          // Attualmente, la percentuale di avanzamento (o percentuale degli oggetti) è data dalla percentuale più recente fra quella dell'item consumed e quella dell'item started. Quindi la più recente, va mostrata. Di conseguenza, devo recuperarle entrambe e, poi, confrontarle
          if (currentEngagement.eventName === ReferenceTypes.EVENT_ITEM_CONSUMED) {
            // Item concluso. Salvo la data in cui è stato creato tale engagement
            dateEventItemConsumed = currentEngagement.creationDate;
            // L'item è stato concluso
            isConsumed = true;

            if (itemType === ItemTypes.SCORM_FREE || itemType === ItemTypes.SCORM_INVITE || itemType === ItemTypes.DAM_ITEM) {
              // Se è un oggetto atomico e ha un engagement 'consumed' significa che posso abilitare la certificazione
              isItemCertifiable = true;
            }
          }

          if (currentEngagement.eventName === ReferenceTypes.EVENT_ITEM_OPEN) {
            // L'item è stato aperto
            isItemOpen = true;
          }

          // Verifico se l'oggetto è già stato verificato
          if (currentEngagement.eventName === ReferenceTypes.EVENT_ITEM_CERTIFIED) {
            isItemAlreadyCertified = true;
            // dunque salvo la data
            certifiedDate = itemRef.engagements[d].creationDate;
          }
        }
      }
    } else {
      if (itemRef.isConsumed) {
        isConsumed = true;
      }
      if (itemRef.isCertified) {
        isItemAlreadyCertified = true;
        isCertified = true;
      }
      if (itemRef.certifiedDate) {
        certifiedDate = itemRef.certificationDate;
      }
    }

    /* if (itemType == "SURVEY_ITEM"){
        percentageItem = "0";
    }
      if( itemRef.engagements && itemRef.engagements.map((x: any) => {x.eventName == "EVENT_ITEM_CERTIFIED"}).length && itemType == "SURVEY_ITEM"){
        percentageItem = "100";
        isConsumed = true;
        isItemOpen = true;
        isItemAlreadyCertified = true;
  
    } */

    return {
      percentageItem: percentageItem,
      isConsumed: isConsumed,
      isItemCertifiable: isItemCertifiable,
      isItemOpen: isItemOpen,
      isItemAlreadyCertified: isItemAlreadyCertified,
      certifiedDate: certifiedDate,
      isCertified: isCertified
    }
  }

  // Imposta l'etichetta in traduzione ad una lista di item
  static setSubtypeLabelToItem(item, translate: TranslateService) {
    if (item && item.subtype) {
      if (item.subtype === ItemAttributeObjectTypes.MAGAZINE) {
        item.subtypeLabel = translate.instant('card.types.MAGAZINE');
      } else if (item.subtype === ItemAttributeObjectTypes.DVD) {
        item.subtypeLabel = translate.instant('card.types.DVD');
      } else if (item.subtype === ItemAttributeObjectTypes.BOOK) {
        item.subtypeLabel = translate.instant('card.types.BOOK');
      } else if (item.subtype === ItemAttributeObjectTypes.LEARNING_PLAN) {
        item.subtypeLabel = translate.instant('card.types.LEARNING_PLAN');
      } else if (item.subtype === ItemAttributeObjectTypes.PROJECT) {
        item.subtypeLabel = translate.instant('card.types.PROJECT');
      } else if (item.subtype === ItemAttributeObjectTypes.BLENDED_PROJECT) {
        item.subtypeLabel = translate.instant('card.types.BLENDED_PROJECT');
      } else if (item.subtype === ItemAttributeObjectTypes.PODCAST) {
        item.subtypeLabel = translate.instant('card.types.PODCAST');
      } else if (item.subtype === ItemAttributeObjectTypes.GRAPH) {
        item.subtypeLabel = translate.instant('card.types.GRAPH');
      } else if (item.subtype === ItemAttributeObjectTypes.DOCUMENT) {
        item.subtypeLabel = translate.instant('card.types.DOCUMENT');
      } else if (item.subtype === ItemAttributeObjectTypes.EBOOK) {
        item.subtypeLabel = translate.instant('card.types.EBOOK');
      } else if (item.subtype === ItemAttributeObjectTypes.ELEARNING) {
        item.subtypeLabel = translate.instant('card.types.ELEARNING');
      } else if (item.subtype === ItemAttributeObjectTypes.VIDEO) {
        item.subtypeLabel = translate.instant('card.types.VIDEO');
      } else if (item.subtype === ItemAttributeObjectTypes.IMAGE) {
        item.subtypeLabel = translate.instant('card.types.IMAGE');
      }
    }
  }

  // Torna l'item propedeutico a quello selezionato (fra sezioni)
  static getPropedeuticalItemToThis(selectedItem, sections) {
    // Recupero le informazioni complete dell'item selezionato
    let selectedItemFullData = null;
    for (let i = 0; i < sections.length; i++) {
      if (sections[i].referenceId == selectedItem.itemId) {
        selectedItemFullData = sections[i];
      }
    }
    // Item propedeutico
    let propedeuticalObject = null;

    // Cerco il primo oggetto propedeutico
    for (let i = 0; i < sections.length; i++) {
      if (sections[i].referenceId == selectedItemFullData.propedeuticReferenceId) {
        // Verifico se questo oggetto è già stato consumato
        let check = sections[i];
        let consumed = check && check.childObject && check && check.childObject.isConsumed;
        if (!consumed) {
          propedeuticalObject = sections[i];
          break;
        }
      }
    }

    // Funzione che cerca finchè non trova l'item propedeutico in cima alla lista
    let searchPropedeuticalObject = () => {
      for (let i = 0; i < sections.length; i++) {
        if (sections[i].referenceId == propedeuticalObject.propedeuticReferenceId) {
          // Verifico se questo oggetto è già stato consumato
          let check = sections[i];
          let consumed = false;
          if (check && check.childObject) {
            if (check.childObject.engagements && check.childObject.engagements.length) {
              for (let k = 0; k < check.childObject.engagements.length; k++) {
                if (check.childObject.engagements[k].eventName === ReferenceTypes.EVENT_ITEM_CONSUMED) {
                  consumed = true;
                  break;
                }
              }
            } else if (check.childObject.isConsumed) {
              consumed = true;
            }
          }
          if (!consumed) {
            propedeuticalObject = sections[i];
            // se per questo elemento è necessario sbloccarne un altro, lo cerco
            if (propedeuticalObject.propedeuticReferenceId) {
              return searchPropedeuticalObject();
            }
            break;
          }
        }
      }
    }

    // Se il mio item propedeutico possiede un altro item propedeutico, lo cerco
    if (propedeuticalObject && propedeuticalObject.propedeuticReferenceId) {
      searchPropedeuticalObject();
    }
    return propedeuticalObject;
  }

  // Torna l'item propedeutico a quello selezionato (fra item delle sezioni)
  static getPropedeuticalItemToThisChild(selectedItem, item) {
    // Recupero le informazioni complete dell'item selezionato
    let selectedItemFullData = null;
    // Item propedeutico
    let propedeuticalObject = null;
    for (let i = 0; i < item.itemChilds.length; i++) {
      for (let i = 0; i < item.itemChilds.length; i++) {
        if (item.itemChilds[i].referenceId === selectedItem.itemId) {
          selectedItemFullData = item.itemChilds[i];
        }
      }
    }

    // Cerco il primo oggetto propedeutico
    for (let i = 0; i < item.itemChilds.length; i++) {
      if ((selectedItemFullData && item.itemChilds[i].referenceId == selectedItemFullData.propedeuticReferenceId)
        || (!selectedItemFullData && selectedItem && selectedItem.propedeuticReferenceId && item.itemChilds[i].referenceId == selectedItem.propedeuticReferenceId)) {
        // Verifico se questo oggetto è già stato consumato
        let check = item.itemChilds[i];
        let consumed = false;
        if (check && check.childObject) {
          if (check.childObject.engagements && check.childObject.engagements.length) {
            for (let k = 0; k < check.childObject.engagements.length; k++) {
              if (check.childObject.engagements[k].eventName === ReferenceTypes.EVENT_ITEM_CONSUMED) {
                consumed = true;
                break;
              }
            }
          } else if (check.childObject.isConsumed) {
            consumed = true;
          }
        }
        if (!consumed) {
          propedeuticalObject = item.itemChilds[i];
          break;
        }
      }
    }

    // Funzione che cerca finchè non trova l'item propedeutico in cima alla lista
    let searchPropedeuticalObject = () => {
      for (let i = 0; i < item.itemChilds.length; i++) {
        if (item.itemChilds[i].referenceId == propedeuticalObject.propedeuticReferenceId) {
          // Verifico se questo oggetto è già stato consumato
          let check = item.itemChilds[i];
          let consumed = false;
          if (check && check.childObject) {
            if (check.childObject.engagements && check.childObject.engagements.length) {
              for (let k = 0; k < check.childObject.engagements.length; k++) {
                if (check.childObject.engagements[k].eventName === ReferenceTypes.EVENT_ITEM_CONSUMED) {
                  consumed = true;
                  break;
                }
              }
            } else if (check.childObject.isConsumed) {
              consumed = true;
            }
          }
          if (!consumed) {
            propedeuticalObject = item.itemChilds[i];
            // se per questo elemento è necessario sbloccarne un altro, lo cerco
            if (propedeuticalObject.propedeuticReferenceId) {
              return searchPropedeuticalObject();
            }
            break;
          }
        }
      }
    }

    // Se il mio item propedeutico possiede un altro item propedeutico, lo cerco
    if (propedeuticalObject && propedeuticalObject.propedeuticReferenceId) {
      searchPropedeuticalObject();
    }

    return propedeuticalObject;
  }

}
